import { useState, useEffect } from "react";
import System from "@/models/system";
import { useTranslation } from "react-i18next";

export default function DeepSeekOptions({ settings }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(settings?.DeepSeekApiKey);
  const [deepSeekApiKey, setDeepSeekApiKey] = useState(
    settings?.DeepSeekApiKey
  );

  return (
    <div className="flex gap-[36px] mt-1.5">
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-3">
          {t("deepseek.api-key")}
        </label>
        <input
          type="password"
          name="DeepSeekApiKey"
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
          placeholder={t("deepseek.api-key-placeholder")}
          defaultValue={settings?.DeepSeekApiKey ? "*".repeat(20) : ""}
          required={true}
          autoComplete="off"
          spellCheck={false}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => setDeepSeekApiKey(inputValue)}
        />
      </div>
      {!settings?.credentialsOnly && (
        <DeepSeekModelSelection settings={settings} apiKey={deepSeekApiKey} />
      )}
    </div>
  );
}

function DeepSeekModelSelection({ apiKey, settings }) {
  const { t } = useTranslation();
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      if (!apiKey) {
        setModels([]);
        setLoading(true);
        return;
      }

      setLoading(true);
      const { models } = await System.customModels(
        "deepseek",
        typeof apiKey === "boolean" ? null : apiKey
      );
      setModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, [apiKey]);

  if (loading) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-3">
          {t("deepseek.model-selection")}
        </label>
        <select
          name="DeepSeekModelPref"
          disabled={true}
          value=""
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} value="">
            {t("deepseek.loading-models")}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-3">
        {t("deepseek.model-selection")}
      </label>
      <select
        name="DeepSeekModelPref"
        required={true}
        value={settings?.DeepSeekModelPref || ""}
        className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
      >
        {models.map((model) => (
          <option key={model.id} value={model.id} className="text-foreground">
            {model.name}
          </option>
        ))}
      </select>
    </div>
  );
}
