export default function AnthropicAiOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Anthropic API Key
          </label>
          <input
            type="password"
            name={`AnthropicApiKey${moduleSuffix}`}
            placeholder="Anthropic Claude API Key"
            defaultValue={
              settings?.[`AnthropicApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
            className="dark-input-mdl normal-text  text-sm rounded-md block w-full  p-2.5"
          />
        </div>

        {!settings?.[`credentialsOnly${moduleSuffix}`] && (
          <div className="flex flex-col w-60">
            <label className="normal-text text-sm font-semibold block mb-4">
              Chat Model Selection
            </label>
            <select
              name={`AnthropicModelPref${moduleSuffix}`}
              defaultValue={
                settings?.[`AnthropicModelPref${moduleSuffix}`] || "claude-2"
              }
              required={true}
              className="dark-input-mdl normal-text text-sm rounded-lg block w-full p-1.5"
            >
              {[
                "claude-instant-1.2",
                "claude-2.0",
                "claude-2.1",
                "claude-3-haiku-20240307",
                "claude-3-opus-20240229",
                "claude-3-sonnet-20240229",
                "claude-3-5-sonnet-20240620",
                "claude-3-5-sonnet-20241022",
              ].map((model) => {
                return (
                  <option key={model} value={model} className="normal-text">
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}
