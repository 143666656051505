import useUser from "@/hooks/useUser";
import paths from "@/utils/paths";
import { TbHomeUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useMatch } from "react-router-dom";
import { ToolTipWrapper } from "../Footer";

export default function SettingsButton() {
  const isInSettings = !!useMatch("/settings/*");
  const { user } = useUser();

  if (user && user?.role === "default") return null;

  if (isInSettings)
    return (
      <ToolTipWrapper id="go-home">
        <Link
          to={paths.home()}
          aria-label="Home"
          data-tooltip-id="go-home"
          data-tooltip-content="Back to workspaces"
          className="focus:outline-none transition-all duration-300 p-1 rounded-md text-white primary-bg"
        >
          <TbHomeUp className="h-5 w-5" />
        </Link>
      </ToolTipWrapper>
    );
}
