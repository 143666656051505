import React, { useEffect, useState } from "react";
import System from "../../../models/system";
import { AUTH_TOKEN, AUTH_USER } from "../../../utils/constants";
import paths from "../../../utils/paths";
import { useTranslation } from "react-i18next";
import useLogo from "../../../hooks/useLogo";
import { ImSpinner2 } from "react-icons/im";

export default function MultiUserAuth() {
  const { t } = useTranslation();
  const { logo } = useLogo();
  const [loading, setLoading] = useState(false);
  const [loadingPublicMode, setLoadingPublicMode] = useState(false);
  const [error, setError] = useState(null);
  const [supportEmail, setSupportEmail] = useState(false);
  const [isPublicUserMode, setIsPublicUserMode] = useState(false);

  const getErrorTranslationKey = (errorMessage) => {
    if (!errorMessage) return null;

    if (
      errorMessage.includes("[002]") ||
      errorMessage === "Invalid login credentials."
    ) {
      return "errors.auth.invalid-credentials";
    } else if (errorMessage.includes("[003]")) {
      return "errors.auth.invalid-password";
    } else if (errorMessage.includes("[004]")) {
      return "errors.auth.account-suspended";
    }

    return null;
  };

  const handleLogin = async (e) => {
    setError(null);
    setLoading(true);
    e.preventDefault();
    const data = {};
    const form = new FormData(e.target);
    for (var [key, value] of form.entries()) data[key] = value;
    const { valid, user, token, message } = await System.requestToken(data);
    if (valid && !!token && !!user) {
      window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
      window.localStorage.setItem(AUTH_TOKEN, token);
      window.location = paths.home();
    } else {
      setError(message);
      setLoading(false);
    }
  };

  const handlePublicModeLogin = async () => {
    const publicUsername = "publicuser";
    const publicPassword = "publicuser";

    setLoadingPublicMode(true);
    setError(null);

    const { valid, user, token, message } = await System.requestToken({
      username: publicUsername,
      password: publicPassword,
    });

    if (valid && !!token && !!user) {
      window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
      window.localStorage.setItem(AUTH_TOKEN, token);
      window.location = paths.home();
    } else {
      setError(message);
      setLoadingPublicMode(false);
    }
  };

  const clearError = () => {
    if (error) setError(null);
  };

  useEffect(() => {
    const fetchSupportEmail = async () => {
      const supportEmail = await System.fetchSupportEmail();
      if (supportEmail) {
        setSupportEmail(supportEmail.email);
      }
    };
    fetchSupportEmail();
  }, []);

  useEffect(() => {
    const fetchIsPublicUserMode = async () => {
      try {
        const publicUserModeEnabled = await System.isPublicUserMode();
        setIsPublicUserMode(publicUserModeEnabled);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchIsPublicUserMode();
  }, []);

  return (
    <div
      className={`relative flex flex-col md:gap-2 items-center max-w-[480px] mx-auto p-12 pb-14 md:p-16 md:pb-20 bg-white rounded-xl shadow-lg transition-opacity duration-300 delay-500 ${
        logo ? "opacity-100" : "opacity-0"
      }`}
    >
      <img
        src={logo}
        alt="Company Logo"
        className="w-[230px] mb-4 tenderFlow-logo"
      />

      <p className="text-lg">{t("login.sign-in.start")}</p>

      <form
        onSubmit={handleLogin}
        className="flex flex-col gap-6 w-full mt-8 md:mt-10"
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="username" className="text-gray-700">
            {t("login.multi-user.placeholder-username")}
          </label>
          <input
            className="w-full h-[42px] px-4 py-2 text-lg border border-gray-300 rounded-md focus-visible:outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-[var(--primary-color)]"
            name="username"
            type="text"
            autoComplete="off"
            onChange={clearError}
            required
          />
        </div>

        <div className="relative flex flex-col gap-2">
          <label htmlFor="password" className="text-gray-700">
            {t("login.multi-user.placeholder-password")}
          </label>
          <input
            className="w-full h-[42px] px-4 py-2 text-lg border border-gray-300 rounded-md focus-visible:outline-none focus-visible:border-transparent focus-visible:ring-2 focus-visible:ring-[var(--primary-color)] "
            name="password"
            type="password"
            autoComplete="off"
            onChange={clearError}
            required
          />
          {supportEmail && (
            <a
              href={`mailto:${supportEmail}`}
              className="absolute right-0 text-[var(--primary-color)] hover:text-[var(--text-foreground)] transition-colors"
            >
              {t("login.password.forgot")}
            </a>
          )}
        </div>

        <div className="pt-4">
          <button
            type="submit"
            className="flex items-center justify-center w-full h-[42px] text-white text-lg bg-[var(--primary-color)] rounded-md disabled:opacity-60"
            disabled={loading}
          >
            {loading ? (
              <ImSpinner2 className="animate-spin-fast" />
            ) : (
              t("login.multi-user.login")
            )}
          </button>
        </div>

        {isPublicUserMode && (
          <>
            <hr className="my-2 border-gray-200" />
            <button
              className="flex items-center justify-center w-full h-[42px] text-[var(--text-foreground)] text-lg bg-[var(--light-bg)] rounded-md disabled:opacity-60"
              disabled={loadingPublicMode}
              onClick={handlePublicModeLogin}
            >
              {loadingPublicMode ? (
                <ImSpinner2 className="animate-spin-fast" />
              ) : (
                t("login.publicMode")
              )}
            </button>
          </>
        )}

        {error && (
          <div className="absolute bottom-4 md:bottom-8 left-1/2 -translate-x-1/2 text-red-600 font-medium text-center">
            {getErrorTranslationKey(error)
              ? t(getErrorTranslationKey(error))
              : error}
          </div>
        )}
      </form>
    </div>
  );
}
