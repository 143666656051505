export const TEXT_SIZE_KEY = "anythingllm_text_size";

export const initializeTextSize = () => {
  const currentSize = window.localStorage.getItem(TEXT_SIZE_KEY);
  if (!currentSize) {
    window.localStorage.setItem(TEXT_SIZE_KEY, "normal");
    window.dispatchEvent(
      new CustomEvent("textSizeChange", { detail: "normal" })
    );
  }
};

export const getTextSizeClass = (size) => {
  switch (size) {
    case "small":
      return "text-[12px]";
    case "large":
      return "text-[18px]";
    default:
      return "text-[14px]";
  }
};
