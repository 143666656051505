import { create } from "zustand";
import { persist } from "zustand/middleware";
import { API_BASE } from "@/utils/constants";
import { baseHeaders } from "@/utils/request";

const ENDPOINTS = {
  "tab-names": {
    endpoint: "/get-tab-names",
    updateEndpoint: "/system/custom-tab-names",
    defaultValue: { tabName1: "", tabName2: "", tabName3: "" },
  },
  "force-invoice-logging": {
    endpoint: "/system/force-invoice-logging",
    updateEndpoint: "/system/force-invoice-logging",
    defaultValue: false,
  },
  "invoice-logging": {
    endpoint: "/system/invoice-logging",
    updateEndpoint: "/system/invoice-logging",
    defaultValue: false,
  },
  "rexor-linkage": {
    endpoint: "/system/rexor-linkage",
    updateEndpoint: "/system/rexor-linkage",
    defaultValue: false,
  },
};

const DEFAULT_REFRESH_INTERVAL = 12 * 60 * 60 * 1000;

const useSystemSettingsStore = create(
  persist(
    (set, get) => ({
      values: Object.fromEntries(
        Object.entries(ENDPOINTS).map(([key, config]) => [
          key,
          config.defaultValue,
        ])
      ),

      lastFetched: {},
      loading: {},
      errors: {},
      refreshInterval: DEFAULT_REFRESH_INTERVAL,

      fetchAllSettings: async (force = false) => {
        const keys = Object.keys(ENDPOINTS);
        const { lastFetched, refreshInterval } = get();
        const now = Date.now();

        const keysToFetch = force
          ? keys
          : keys.filter((key) => {
              const timestamp = lastFetched[key] || 0;
              return !timestamp || now - timestamp > refreshInterval;
            });

        if (keysToFetch.length === 0) return get().values;

        set((state) => {
          const newLoading = { ...state.loading };
          keysToFetch.forEach((key) => {
            newLoading[key] = true;
          });
          return { loading: newLoading };
        });

        const results = await Promise.all(
          keysToFetch.map(async (key) => {
            try {
              const { endpoint } = ENDPOINTS[key];
              const res = await fetch(`${API_BASE}${endpoint}`, {
                method: "GET",
                headers: baseHeaders(),
              });

              if (!res.ok) {
                throw new Error(`Failed to fetch ${key}: ${res.statusText}`);
              }

              const data = await res.json();
              return { key, data, success: true };
            } catch (error) {
              console.error(`Error fetching setting ${key}:`, error);
              return { key, error: error.message, success: false };
            }
          })
        );

        set((state) => {
          const newValues = { ...state.values };
          const newLoading = { ...state.loading };
          const newErrors = { ...state.errors };
          const newLastFetched = { ...state.lastFetched };

          results.forEach(({ key, data, error, success }) => {
            newLoading[key] = false;

            if (success) {
              newValues[key] = data;
              newLastFetched[key] = now;
              newErrors[key] = null;
            } else {
              newErrors[key] = error;
            }
          });

          return {
            values: newValues,
            loading: newLoading,
            errors: newErrors,
            lastFetched: newLastFetched,
          };
        });

        return get().values;
      },

      getSetting: (key) => {
        if (!ENDPOINTS[key]) {
          console.error(`Unknown setting key: ${key}`);
          return null;
        }
        return get().values[key] ?? ENDPOINTS[key].defaultValue;
      },

      updateSetting: async (key, data) => {
        if (!ENDPOINTS[key]) {
          console.error(`Unknown setting key: ${key}`);
          return false;
        }

        set((state) => ({
          loading: { ...state.loading, [key]: true },
          errors: { ...state.errors, [key]: null },
        }));

        try {
          const endpoint =
            ENDPOINTS[key].updateEndpoint || ENDPOINTS[key].endpoint;
          const res = await fetch(`${API_BASE}${endpoint}`, {
            method: "POST",
            headers: baseHeaders(),
            body: JSON.stringify(data),
          });

          if (!res.ok) {
            throw new Error(`Failed to update ${key}: ${res.statusText}`);
          }

          set((state) => ({
            values: { ...state.values, [key]: data },
            lastFetched: { ...state.lastFetched, [key]: Date.now() },
            loading: { ...state.loading, [key]: false },
            errors: { ...state.errors, [key]: null },
          }));

          return true;
        } catch (error) {
          console.error(`Error updating setting ${key}:`, error);

          set((state) => ({
            loading: { ...state.loading, [key]: false },
            errors: { ...state.errors, [key]: error.message },
          }));

          return false;
        }
      },

      isLoading: (key) => get().loading[key] || false,

      getError: (key) => get().errors[key] || null,

      clearAllSettings: () => {
        set({
          values: Object.fromEntries(
            Object.entries(ENDPOINTS).map(([key, config]) => [
              key,
              config.defaultValue,
            ])
          ),
          lastFetched: {},
          loading: {},
          errors: {},
        });
      },
    }),
    {
      name: "system-settings",
      partialize: (state) => ({
        values: state.values,
        lastFetched: state.lastFetched,
        refreshInterval: state.refreshInterval,
      }),
    }
  )
);

export const useTabNames = () =>
  useSystemSettingsStore((state) => state.getSetting("tab-names"));

export const useForceInvoiceLogging = () =>
  useSystemSettingsStore((state) => state.getSetting("force-invoice-logging"));

export const useInvoiceLogging = () =>
  useSystemSettingsStore((state) => state.getSetting("invoice-logging"));

export const useRexorLinkage = () =>
  useSystemSettingsStore((state) => state.getSetting("rexor-linkage"));

export default useSystemSettingsStore;
