import System from "@/models/system";
import paths from "@/utils/paths";
import React, { useEffect, useState } from "react";
import SettingsButton from "../SettingsButton";
import { isMobile } from "react-device-detect";
import { Tooltip } from "react-tooltip";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { VscGithubInverted } from "react-icons/vsc";
import { MdOutlineAddLink } from "react-icons/md";
import { FaGlobe, FaSquareXTwitter, FaEnvelope } from "react-icons/fa6";

export const MAX_ICONS = 3;
export const ICON_COMPONENTS = {
  FaSquareXTwitter: FaSquareXTwitter,
  IoLogoLinkedin: IoLogoLinkedin,
  FaFacebookSquare: FaFacebookSquare,
  BsInstagram: BsInstagram,
  FaEnvelope: FaEnvelope,
  VscGithubInverted: VscGithubInverted,
  FaGlobe: FaGlobe,
  MdOutlineAddLink: MdOutlineAddLink,
};

export default function Footer({ user = null }) {
  const { t } = useTranslation();
  const [footerData, setFooterData] = useState(false);
  const isInSettings = !!useMatch("/settings/*");

  useEffect(() => {
    async function fetchFooterData() {
      const { footerData } = await System.fetchCustomFooterIcons();
      setFooterData(footerData);
    }
    fetchFooterData();
  }, []);

  // wait for some kind of non-false response from footer data first
  // to prevent pop-in.
  if (footerData === false) return null;

  if (!Array.isArray(footerData) || footerData.length === 0) {
    return (
      <div className="flex justify-center">
        <div className="flex space-x-1 w-full justify-end mr-1 mb-2">
          {!isMobile && <SettingsButton />}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-end mr-1 mb-2">
      <div className="flex space-x-4">{!isMobile && <SettingsButton />}</div>
    </div>
  );
}

export function ToolTipWrapper({ id = v4(), children }) {
  return (
    <div className="flex w-fit">
      {children}
      <Tooltip
        id={id}
        place="top"
        delayShow={300}
        className="tooltip !text-xs z-99"
      />
    </div>
  );
}
