import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  defaultNS,
  resources,
  loadLanguageAsync,
  initializeLanguageAsync,
} from "./locales/resources";

const languageDetector = new LanguageDetector(null, {
  order: ["navigator"],
  caches: [],
});

let i18nInstance;

const applyLanguageResources = async (language) => {
  if (language === "en") return true;

  try {
    const resourceBundle = await loadLanguageAsync(language);

    if (resourceBundle) {
      i18next.addResourceBundle(language, "common", resourceBundle);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const initialize = async () => {
  await i18next
    .use(initReactI18next)
    .use(languageDetector)
    .init({
      fallbackLng: "en",
      debug: false,
      defaultNS,
      resources,
      lowerCaseLng: true,
      interpolation: {
        escapeValue: false,
      },
    });

  const targetLanguage = await initializeLanguageAsync();

  i18next.on("languageChanged", async (lng) => {
    if (lng !== "en") {
      await applyLanguageResources(lng);
    }

    localStorage.setItem("language", lng);
  });

  if (targetLanguage !== "en") {
    const success = await applyLanguageResources(targetLanguage);

    if (success) {
      await i18next.changeLanguage(targetLanguage);
    } else {
      localStorage.setItem("language", "en");
      await i18next.changeLanguage("en");
    }
  }

  i18nInstance = i18next;

  return i18nInstance;
};

let initializationPromise = null;

export const ensureI18nInitialized = () => {
  if (!initializationPromise) {
    initializationPromise = initialize();
  }
  return initializationPromise;
};

export const changeLanguage = async (language) => {
  await ensureI18nInitialized();

  if (language !== "en") {
    const success = await applyLanguageResources(language);
    if (!success) {
      return false;
    }
  }

  await i18next.changeLanguage(language);
  localStorage.setItem("language", language);
  return true;
};

export default i18next;
