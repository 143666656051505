import paths from "@/utils/paths";
import LGroupImg from "@/media/images/l_group.png";
import RGroupImg from "@/media/images/r_group.png";
import IstLegalLogo from "@/media/logo/ISTLogo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OnboardingHome() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <div className="relative w-screen h-screen flex overflow-hiddent">
      <div
        className="hidden md:block fixed bottom-10 left-10 w-[320px] h-[320px] bg-no-repeat bg-contain"
        style={{ backgroundImage: `url(${LGroupImg})` }}
      ></div>

      <div
        className="hidden md:block fixed top-10 right-10 w-[320px] h-[320px] bg-no-repeat bg-contain"
        style={{ backgroundImage: `url(${RGroupImg})` }}
      ></div>

      <div className="relative flex justify-center items-center m-auto">
        <div className="flex flex-col justify-center items-center">
          <p className="text-foreground text-[24px]">
            {t("onboarding.welcome")}
          </p>
          <img
            src={IstLegalLogo}
            alt="IstLegalLogo"
            className="md:h-[80px] flex-shrink-0 w-full"
          />
          <button
            onClick={() => navigate(paths.onboarding.llmPreference())}
            className="animate-pulse w-full md:max-w-[350px] md:min-w-[300px] text-foreground font-semibold rounded-md get-started-btn"
          >
            {t("onboarding.get-started")}
          </button>
        </div>
      </div>
    </div>
  );
}
