import React, { useState, useEffect } from "react";
import System from "../../../models/system";
import SingleUserAuth from "./SingleUserAuth";
import MultiUserAuth from "./MultiUserAuth";
import tenderflowLogo from "../../../media/logo/tenderFlow.jpg";
import {
  AUTH_TOKEN,
  AUTH_USER,
  AUTH_TIMESTAMP,
} from "../../../utils/constants";
import useLogo from "../../../hooks/useLogo";
import { ICON_COMPONENTS } from "@/components/Footer";
import { safeJsonParse } from "@/utils/request";

export default function PasswordModal({ mode = "single" }) {
  const [customWebsiteLink, setCustomWebsiteLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [footerIcons, setFooterIcons] = useState([]);
  const { logo } = useLogo();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [websiteLinkData, footerData] = await Promise.all([
          System.fetchCustomWebsiteLink(),
          System.fetchCustomFooterIcons(),
        ]);
        const parsedIcons = footerData.footerData || [];
        setFooterIcons(parsedIcons);
        setCustomWebsiteLink(websiteLinkData.websiteLink || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-full flex flex-col md:flex-row items-center justify-center">
      <div className="bg-white hidden md:flex md:w-1/2 md:h-full md:items-center md:justify-center">
        <div className="flex flex-col items-center justify-center">
          <img
            className="tenderFlow-svg w-[60%]"
            src={tenderflowLogo}
            alt="Company Logo"
          />
          <div className="flex flex-row align-center justify-center gap-2">
            <img
              className="w-80 tenderFlow-logo"
              src={logo}
              alt="Company Logo"
            />
            {footerIcons && footerIcons.length > 0 && (
              <div className="tenderFlow-icons tenderFlow-svg">
                {footerIcons.map((iconData, index) => {
                  return (
                    iconData &&
                    iconData.icon &&
                    iconData.url && (
                      <a
                        key={index}
                        href={iconData.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {React.createElement(
                          ICON_COMPONENTS[iconData.icon] ||
                            (() => <span></span>),
                          {
                            size: 20,
                            className: "tender-icons",
                          }
                        )}
                      </a>
                    )
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="block footer absolute bottom-2">
          {loading ? (
            <p className="text-[10px]">Loading ...</p>
          ) : (
            customWebsiteLink && (
              <a
                href={customWebsiteLink}
                className="text-[12px] outline-none normal-text underline"
              >
                Visit the website
              </a>
            )
          )}
        </div>
      </div>
      <div className="left-block flex flex-col items-center justify-center h-full w-full md:w-1/2 z-50 relative">
        {mode === "single" ? (
          <SingleUserAuth />
        ) : (
          <MultiUserAuth
            customWebsiteLink={customWebsiteLink}
            Linkloading={loading}
          />
        )}
      </div>
    </div>
  );
}

export function usePasswordModal(notry = false) {
  const [auth, setAuth] = useState({
    loading: true,
    requiresAuth: false,
    mode: "single",
  });

  useEffect(() => {
    async function checkAuthReq() {
      if (!window) return;

      // If the last validity check is still valid
      // we can skip the loading.
      if (!System.needsAuthCheck() && notry === false) {
        setAuth({
          loading: false,
          requiresAuth: false,
          mode: "multi",
        });
        return;
      }

      const settings = await System.keys();
      if (settings?.MultiUserMode) {
        const currentToken = window.localStorage.getItem(AUTH_TOKEN);
        if (!!currentToken) {
          const valid = notry ? false : await System.checkAuth(currentToken);
          if (!valid) {
            setAuth({
              loading: false,
              requiresAuth: true,
              mode: "multi",
            });
            window.localStorage.removeItem(AUTH_USER);
            window.localStorage.removeItem(AUTH_TOKEN);
            window.localStorage.removeItem(AUTH_TIMESTAMP);
            return;
          } else {
            setAuth({
              loading: false,
              requiresAuth: false,
              mode: "multi",
            });
            return;
          }
        } else {
          setAuth({
            loading: false,
            requiresAuth: true,
            mode: "multi",
          });
          return;
        }
      } else {
        const requiresAuth = settings?.RequiresAuth || false;
        if (!requiresAuth) {
          setAuth({
            loading: false,
            requiresAuth: false,
            mode: "single",
          });
          return;
        }

        const currentToken = window.localStorage.getItem(AUTH_TOKEN);
        if (!!currentToken) {
          const valid = notry ? false : await System.checkAuth(currentToken);
          if (!valid) {
            setAuth({
              loading: false,
              requiresAuth: true,
              mode: "single",
            });
            window.localStorage.removeItem(AUTH_TOKEN);
            window.localStorage.removeItem(AUTH_USER);
            window.localStorage.removeItem(AUTH_TIMESTAMP);
            return;
          } else {
            setAuth({
              loading: false,
              requiresAuth: false,
              mode: "single",
            });
            return;
          }
        } else {
          setAuth({
            loading: false,
            requiresAuth: true,
            mode: "single",
          });
          return;
        }
      }
    }
    checkAuthReq();
  }, []);

  return auth;
}
