import React, { useEffect, useState, useRef, useCallback } from "react";
import BaseLLMPreference from "@/components/LLMSelection/BaseLLMPreference";
import System from "@/models/system";
import paths from "@/utils/paths";
import showToast from "@/utils/toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LLMPreference({
  setHeader,
  setForwardBtn,
  setBackBtn,
}) {
  const [selectedLLM, setSelectedLLM] = useState(null);
  const [settings, setSettings] = useState(null);
  const formRef = useRef(null);
  const hiddenSubmitButtonRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchKeys() {
      const _settings = await System.keys();
      setSettings(_settings);
      setSelectedLLM(_settings?.LLMProvider || "openai");
    }
    fetchKeys();
  }, []);

  const handleForward = useCallback(() => {
    if (hiddenSubmitButtonRef.current) {
      hiddenSubmitButtonRef.current.click();
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate(paths.onboarding.home());
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {};
    const formData = new FormData(form);

    // Only include LLM settings if an API key was provided
    const apiKeyField = `${selectedLLM === "openai" ? "OpenAi" : selectedLLM}Key`;
    const apiKey = formData.get(apiKeyField);

    if (apiKey && !apiKey.includes("*")) {
      data.LLMProvider = selectedLLM;
      data.EmbeddingEngine = "native";
      data.VectorDB = "lancedb";

      // Copy all form data
      for (const [key, value] of formData.entries()) {
        if (value && !value.includes("*")) {
          data[key] = value;
        }
      }

      // Validate API key by attempting to fetch models
      const { models, error } = await System.customModels(selectedLLM, apiKey);
      if (error || !models || models.length === 0) {
        showToast(
          `Invalid API key: ${error || "Could not fetch available models"}`,
          "error"
        );
        return;
      }

      // Only update system if we have valid settings
      const { error: updateError } = await System.updateSystem(data);
      if (updateError) {
        showToast(
          t("toast.settings.llm-failed", { error: updateError }),
          "error"
        );
        return;
      }
    }

    // Proceed to next step regardless of whether LLM was configured
    navigate(paths.onboarding.userSetup());
  };

  useEffect(() => {
    setHeader({
      title: t("onboarding.llm-preference.title"),
      description: t("onboarding.llm-preference.description"),
    });
    setForwardBtn({ showing: true, disabled: false, onClick: handleForward });
    setBackBtn({ showing: true, disabled: false, onClick: handleBack });
  }, [handleForward, handleBack, setHeader, setForwardBtn, setBackBtn, t]);

  const handleLLMChange = (llm) => {
    setSelectedLLM(llm);
  };

  return (
    <div>
      <form ref={formRef} onSubmit={handleSubmit} className="w-full">
        <BaseLLMPreference
          settings={settings}
          selectedLLM={selectedLLM}
          onLLMChange={handleLLMChange}
          customClassName="onboarding-llm-selection"
        />

        <button
          type="submit"
          ref={hiddenSubmitButtonRef}
          hidden
          aria-hidden="true"
        ></button>
      </form>
    </div>
  );
}
