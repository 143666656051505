import System from "@/models/system.js";

export const API_BASE = import.meta.env.VITE_API_BASE || "/api";

export const AUTH_USER = "anythingllm_user";
export const AUTH_TOKEN = "anythingllm_authToken";
export const AUTH_TIMESTAMP = "anythingllm_authTimestamp";
export const COMPLETE_QUESTIONNAIRE = "anythingllm_completed_questionnaire";
export const SEEN_DOC_PIN_ALERT = "anythingllm_pinned_document_alert";
export const SEEN_WATCH_ALERT = "anythingllm_watched_document_alert";

export const USER_BACKGROUND_COLOR = "bg-historical-msg-user";
export const AI_BACKGROUND_COLOR = "bg-historical-msg-system";
export const APPEARANCE_SETTINGS = "anythingllm_appearance_settings";

export const MODULE_LEGAL_QA = "legal-qa";
export const MODULE_DOCUMENT_DRAFTING = "document-drafting";

export const OLLAMA_COMMON_URLS = [
  "http://127.0.0.1:11434",
  "http://host.docker.internal:11434",
  "http://172.17.0.1:11434",
];

export const LMSTUDIO_COMMON_URLS = [
  "http://localhost:1234/v1",
  "http://127.0.0.1:1234/v1",
  "http://host.docker.internal:1234/v1",
  "http://172.17.0.1:1234/v1",
];

export const KOBOLDCPP_COMMON_URLS = [
  "http://127.0.0.1:5000/v1",
  "http://localhost:5000/v1",
  "http://host.docker.internal:5000/v1",
  "http://172.17.0.1:5000/v1",
];

export const LOCALAI_COMMON_URLS = [
  "http://127.0.0.1:8080/v1",
  "http://localhost:8080/v1",
  "http://host.docker.internal:8080/v1",
  "http://172.17.0.1:8080/v1",
];

export function fullApiUrl() {
  if (API_BASE !== "/api") return API_BASE;
  return `${window.location.origin}/api`;
}

export const POPUP_BROWSER_EXTENSION_EVENT = "NEW_BROWSER_EXTENSION_CONNECTION";

export function getCurrentModule() {
  return window.localStorage.getItem("module") || "legal-qa";
}

export function isModuleLegalQA() {
  return getCurrentModule() === "legal-qa";
}

export function isModuleDocumentDrafting() {
  return getCurrentModule() === "document-drafting";
}

export async function isDocumentDraftingSelected() {
  return isDocumentDrafting() && isModuleDocumentDrafting;
}

export function currentModuleSlug() {
  return getCurrentModule() === "document-drafting"
    ? `document-drafting`
    : "legal-qa";
}

export function isDocumentDrafting() {
  return window.localStorage.getItem("document-drafting") === "true";
}

export function isDocumentDraftingLinking() {
  return window.localStorage.getItem("document-drafting-linking") === "true";
}

export function isQura() {
  return window.localStorage.getItem("qura") === "true";
}

export function isCitationEnabled() {
  return window.localStorage.getItem("citationEnabled") === "true";
}
