import { useTranslation } from "react-i18next";

export default function LLMItem({
  name,
  value,
  image,
  logo,
  icon,
  description,
  checked,
  onClick,
}) {
  const { t } = useTranslation();
  return (
    <button
      onClick={() => onClick(value)}
      className={`flex gap-x-4 w-full p-2 rounded-md hover:cursor-pointer
        ${checked ? "modal-list-Active" : "modal-list-Inactive"}`}
    >
      {logo || image ? (
        <img
          src={logo || image}
          alt={t("llm.logo-alt", { name })}
          className="w-10 h-10 rounded-md"
        />
      ) : (
        icon && (
          <div className="w-10 h-10 rounded-md flex items-center justify-center">
            {icon}
          </div>
        )
      )}
      <div className="text-justify">
        <div className="text-sm font-semibold text-foreground">{name}</div>
        <div className="mt-1 text-xs text-foreground">{description}</div>
      </div>
    </button>
  );
}
