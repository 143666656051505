import Workspace from "@/models/workspace";
import paths from "@/utils/paths";
import showToast from "@/utils/toast";
import { CircleNotch, Trash } from "@phosphor-icons/react";
import { LuPlus } from "react-icons/lu";
import { useEffect, useState, useCallback, useRef } from "react";
import ThreadItem from "./ThreadItem";
import { useParams, useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Button";
export const THREAD_RENAME_EVENT = "renameThread";

export default function ThreadContainer({ workspace }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { threadSlug = null } = useParams();
  const isOnThreadsPage = useMatch(`/workspace/:${workspace.slug}`);
  const isOnSpecificThreadPage = useMatch(
    `/workspace/:${workspace.slug}/t/:${threadSlug}`
  );

  const isThreadRoute = isOnThreadsPage || isOnSpecificThreadPage;

  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteTimeoutRef = useRef(null);

  const chatHandler = useCallback((event) => {
    const { threadSlug, newName } = event.detail;
    setThreads((prevThreads) =>
      prevThreads.map((thread) => {
        if (thread.slug === threadSlug) {
          return { ...thread, name: newName };
        }
        return thread;
      })
    );
  }, []);

  useEffect(() => {
    window.addEventListener(THREAD_RENAME_EVENT, chatHandler);
    return () => {
      window.removeEventListener(THREAD_RENAME_EVENT, chatHandler);
    };
  }, [chatHandler]);

  useEffect(() => {
    let mounted = true;
    async function fetchThreads() {
      if (!workspace.slug) return;

      try {
        const { threads } = await Workspace.threads.all(workspace.slug);
        if (!mounted) return;

        setLoading(false);
        setThreads(threads);

        // If there are no threads, automatically create a new one
        if (threads.length === 0) {
          setLoading(true); // Show loading state while creating thread
          const { thread, error } = await Workspace.threads.new(workspace.slug);
          if (!mounted) return;

          if (error) {
            showToast(
              t("toast.workspace.thread-create-failed", { error }),
              "error",
              {
                clear: true,
              }
            );
            setLoading(false);
            return;
          }

          if (thread) {
            try {
              // Recheck that no threads were created while we were creating this one
              const { threads: latestThreads } = await Workspace.threads.all(
                workspace.slug
              );
              if (!mounted) return;

              if (latestThreads.length === 0) {
                setThreads([thread]); // Update local state immediately
                navigate(paths.workspace.thread(workspace.slug, thread.slug), {
                  replace: true,
                });
              } else {
                setThreads(latestThreads);
                // Navigate to the last thread in latestThreads
                const lastThread = latestThreads[latestThreads.length - 1];
                navigate(
                  paths.workspace.thread(workspace.slug, lastThread.slug),
                  {
                    replace: true,
                  }
                );
              }
            } catch (error) {
              if (!mounted) return;
              showToast(t("errors.thread-fetch-failed"), "error", {
                clear: true,
              });
            }
          }
          setLoading(false);
        } else if (
          !threadSlug ||
          !threads.find((thread) => thread.slug === threadSlug)
        ) {
          if (!isThreadRoute) return;

          // If there are threads but no specific thread is selected
          // OR if the current threadSlug doesn't exist in the threads list
          // navigate to the last created thread
          const lastThread = threads[threads.length - 1];
          navigate(paths.workspace.thread(workspace.slug, lastThread.slug), {
            replace: true,
          });
        }
      } catch (error) {
        if (!mounted) return;
        setLoading(false);
        showToast(t("errors.thread-fetch-failed"), "error", { clear: true });
      }
    }

    fetchThreads();
    return () => {
      mounted = false;
    };
  }, [workspace.slug, t, navigate, threadSlug]);

  // Enable toggling of bulk-deletion by holding meta-key (ctrl on win and cmd/fn on others)
  useEffect(() => {
    const resetState = () => {
      setCtrlPressed(false);
      setThreads((prev) =>
        prev.map((t) => {
          return { ...t, deleted: false };
        })
      );
    };

    const handleKeyDown = (event) => {
      if (["Control", "Meta"].includes(event.key)) {
        setCtrlPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (["Control", "Meta"].includes(event.key)) {
        resetState();
      }
    };

    const handleBlur = () => {
      resetState();
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        resetState();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleBlur);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleBlur);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const toggleForDeletion = useCallback((id) => {
    setThreads((prev) =>
      prev.map((t) => {
        if (t.id !== id) return t;
        return { ...t, deleted: !t.deleted };
      })
    );
  }, []);

  const handleDeleteAll = useCallback(async () => {
    try {
      setIsDeleting(true);
      const slugs = threads
        .filter((t) => t.deleted === true)
        .map((t) => t.slug);
      const success = await Workspace.threads.deleteBulk(workspace.slug, slugs);

      if (success) {
        const remainingThreads = threads.filter((t) => !t.deleted);
        setThreads(remainingThreads);
        showToast(t("show-toast.threads-deleted"), "success", { clear: true });

        // If all threads were deleted, create a new one
        if (remainingThreads.length === 0) {
          setLoading(true);
          const { thread, error } = await Workspace.threads.new(workspace.slug);
          if (error) {
            showToast(
              t("toast.workspace.thread-create-failed", { error }),
              "error",
              {
                clear: true,
              }
            );
            setLoading(false);
            return;
          }
          setThreads([thread]);
          navigate(paths.workspace.thread(workspace.slug, thread.slug), {
            replace: true,
          });
        }
      } else {
        throw new Error("Failed to delete threads");
      }
    } catch (error) {
      showToast(t("errors.delete-threads-failed"), "error", { clear: true });
      // Reset deleted state on error
      setThreads((prev) => prev.map((t) => ({ ...t, deleted: false })));
    } finally {
      setIsDeleting(false);
    }
  }, [workspace.slug, threads, t, navigate]);

  const removeThread = useCallback(
    (threadId) => {
      // For single thread deletion, we'll remove it immediately from the UI
      setThreads((prev) => prev.filter((t) => t.id !== threadId));

      // Clear any existing timeout
      if (deleteTimeoutRef.current) {
        clearTimeout(deleteTimeoutRef.current);
      }

      // Set new timeout and store the reference
      deleteTimeoutRef.current = setTimeout(async () => {
        // Use state updater to get latest threads state
        setThreads((currentThreads) => {
          // If this was the last thread, create a new one
          if (currentThreads.length === 0) {
            setLoading(true);
            Workspace.threads.new(workspace.slug).then(({ thread, error }) => {
              if (error) {
                showToast(
                  t("toast.workspace.thread-create-failed", { error }),
                  "error",
                  {
                    clear: true,
                  }
                );
                setLoading(false);
                return;
              }
              setThreads([thread]);
              navigate(paths.workspace.thread(workspace.slug, thread.slug), {
                replace: true,
              });
              setLoading(false);
            });
          }

          return currentThreads;
        });

        deleteTimeoutRef.current = null;
      }, 500);
    },
    [workspace.slug, t, navigate]
  );

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (deleteTimeoutRef.current) {
        clearTimeout(deleteTimeoutRef.current);
      }
    };
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col bg-pulse w-full h-10 items-center justify-center">
        <p className="text-xs animate-pulse text-foreground">
          {t("sidebar.thread.load-thread")}
        </p>
      </div>
    );
  }

  // Update activeThreadIdx calculation to handle edge cases
  const activeThreadIdx =
    threadSlug && threads.length > 0
      ? threads.findIndex((thread) => thread?.slug === threadSlug)
      : threads.length > 0
        ? threads.length - 1 // Default to last thread if no threadSlug
        : -1; // No threads available

  return (
    <div
      className="flex flex-col gap-y-2 mt-4 ml-4 pl-4 border-l"
      role="list"
      aria-label="Threads"
    >
      {threads.map((thread, i) => (
        <ThreadItem
          key={thread.slug}
          idx={i}
          ctrlPressed={ctrlPressed}
          toggleMarkForDeletion={toggleForDeletion}
          activeIdx={activeThreadIdx}
          isActive={activeThreadIdx === i}
          workspace={workspace}
          onRemove={removeThread}
          thread={thread}
          hasNext={i !== threads.length - 1}
        />
      ))}
      <DeleteAllThreadButton
        ctrlPressed={ctrlPressed}
        threads={threads}
        onDelete={handleDeleteAll}
        isDeleting={isDeleting}
      />
      <NewThreadButton workspace={workspace} />
    </div>
  );
}

function NewThreadButton({ workspace }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    const { thread, error } = await Workspace.threads.new(workspace.slug);
    if (error) {
      showToast(t("toast.errors.failed-create-thread", { error }), "error", {
        clear: true,
      });
      setLoading(false);
      return;
    }
    window.location.replace(
      paths.workspace.thread(workspace.slug, thread.slug)
    );
  };

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={onClick}
      className="w-fit"
      disabled={loading}
    >
      {loading ? (
        <>
          <CircleNotch className=" animate-spin" />
          {t("sidebar.thread.starting-thread")}
        </>
      ) : (
        <>
          <LuPlus />
          {t("sidebar.thread.thread")}
        </>
      )}
    </Button>
  );
}

function DeleteAllThreadButton({ ctrlPressed, threads, onDelete, isDeleting }) {
  const { t } = useTranslation();
  const hasThreadsMarkedForDeletion =
    threads.filter((t) => t.deleted).length > 0;

  if (!hasThreadsMarkedForDeletion) return null;

  return (
    <Button
      onClick={onDelete}
      disabled={isDeleting}
      variant="outline"
      size="sm"
      className="w-fit"
    >
      <Trash weight="bold" />
      {t("sidebar.thread.delete")}
    </Button>
  );
}
