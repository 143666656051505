import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "@/App.jsx";
import { ThemeProvider } from "./context";
import I18nLoader from "./components/I18nLoader";
import "./index.css";

const renderApp = () => {
  const AppContent = (
    <I18nLoader>
      <ThemeProvider>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </I18nLoader>
  );

  ReactDOM.createRoot(document.getElementById("root")).render(
    process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_DEBUG === "true" ? (
      <React.StrictMode>{AppContent}</React.StrictMode>
    ) : (
      AppContent
    )
  );
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", renderApp);
} else {
  renderApp();
}
