import { useEffect, useState } from "react";
import { Flask } from "@phosphor-icons/react";
import System from "@/models/system";
import { useTranslation } from "react-i18next";

export default function NativeLLMOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="flex flex-col md:flex-row md:items-center gap-x-2 text-foreground mb-4 dark-input-mdl w-fit rounded-lg px-4 py-2">
        <div className="gap-x-2 flex items-center">
          <Flask size={18} className="text-foreground" />
          <p className="text-sm md:text-base">
            {t("native.experimental-warning")}
          </p>
        </div>
      </div>
      <div className="w-full flex items-start gap-4">
        <NativeModelSelection settings={settings} moduleSuffix={moduleSuffix} />
      </div>
    </div>
  );
}

function NativeModelSelection({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("native-llm", null, null);
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-3">
          {t("model.selection")}
        </label>
        <select
          name={`NativeLLMModelPref${moduleSuffix}`}
          disabled={true}
          value=""
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} value="" className="text-foreground">
            {t("loading.models")}
          </option>
        </select>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-3">
          {t("model.selection")}
        </label>
        <select
          name={`NativeLLMModelPref${moduleSuffix}`}
          required={true}
          value={settings?.[`NativeLLMModelPref${moduleSuffix}`] || ""}
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
        >
          {customModels.length > 0 && (
            <optgroup label={t("model.your-models")}>
              {customModels.map((model) => (
                <option
                  key={model.id}
                  value={model.id}
                  className="text-foreground"
                >
                  {model.id}
                </option>
              ))}
            </optgroup>
          )}
        </select>
        <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
          {t("native.model-desc")}
        </p>
      </div>
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-3">
          {t("generic.token-window")}
        </label>
        <input
          type="number"
          name={`NativeLLMTokenLimit${moduleSuffix}`}
          placeholder="4096"
          min={1}
          onScroll={(e) => e.target.blur()}
          defaultValue={settings?.[`NativeLLMTokenLimit${moduleSuffix}`]}
          required={true}
          autoComplete="off"
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
        />
        <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
          {t("native.token-desc")}
        </p>
      </div>
    </>
  );
}
