import React from "react";
import { Gear } from "@phosphor-icons/react";
import OpenAiLogo from "@/media/llmprovider/openai.png";
import GenericOpenAiLogo from "@/media/llmprovider/generic-openai.png";
import AzureOpenAiLogo from "@/media/llmprovider/azure.png";
import AnthropicLogo from "@/media/llmprovider/anthropic.png";
import GeminiLogo from "@/media/llmprovider/gemini.png";
import OllamaLogo from "@/media/llmprovider/ollama.png";
import LMStudioLogo from "@/media/llmprovider/lmstudio.png";
import LocalAiLogo from "@/media/llmprovider/localai.png";
import TogetherAILogo from "@/media/llmprovider/togetherai.png";
import FireworksAILogo from "@/media/llmprovider/fireworksai.jpeg";
import MistralLogo from "@/media/llmprovider/mistral.jpeg";
import HuggingFaceLogo from "@/media/llmprovider/huggingface.png";
import PerplexityLogo from "@/media/llmprovider/perplexity.png";
import OpenRouterLogo from "@/media/llmprovider/openrouter.jpeg";
import GroqLogo from "@/media/llmprovider/groq.png";
import KoboldCPPLogo from "@/media/llmprovider/koboldcpp.png";
import TextGenWebUILogo from "@/media/llmprovider/text-generation-webui.png";
import CohereLogo from "@/media/llmprovider/cohere.png";
import LiteLLMLogo from "@/media/llmprovider/litellm.png";
import AWSBedrockLogo from "@/media/llmprovider/bedrock.png";
import DeepSeekLogo from "@/media/llmprovider/deepseek.png";

import OpenAiOptions from "@/components/LLMSelection/OpenAiOptions";
import GenericOpenAiOptions from "@/components/LLMSelection/GenericOpenAiOptions";
import AzureAiOptions from "@/components/LLMSelection/AzureAiOptions";
import AnthropicAiOptions from "@/components/LLMSelection/AnthropicAiOptions";
import LMStudioOptions from "@/components/LLMSelection/LMStudioOptions";
import LocalAiOptions from "@/components/LLMSelection/LocalAiOptions";
import NativeLLMOptions from "@/components/LLMSelection/NativeLLMOptions";
import GeminiLLMOptions from "@/components/LLMSelection/GeminiLLMOptions";
import OllamaLLMOptions from "@/components/LLMSelection/OllamaLLMOptions";
import TogetherAiOptions from "@/components/LLMSelection/TogetherAiOptions";
import FireworksAiOptions from "@/components/LLMSelection/FireworksAiOptions";
import MistralOptions from "@/components/LLMSelection/MistralOptions";
import HuggingFaceOptions from "@/components/LLMSelection/HuggingFaceOptions";
import PerplexityOptions from "@/components/LLMSelection/PerplexityOptions";
import OpenRouterOptions from "@/components/LLMSelection/OpenRouterOptions";
import GroqAiOptions from "@/components/LLMSelection/GroqAiOptions";
import CohereAiOptions from "@/components/LLMSelection/CohereAiOptions";
import KoboldCPPOptions from "@/components/LLMSelection/KoboldCPPOptions";
import TextGenWebUIOptions from "@/components/LLMSelection/TextGenWebUIOptions";
import LiteLLMOptions from "@/components/LLMSelection/LiteLLMOptions";
import AWSBedrockLLMOptions from "@/components/LLMSelection/AwsBedrockLLMOptions";
import DeepSeekOptions from "@/components/LLMSelection/DeepSeekOptions";

export const getLLMProviders = (t, moduleSuffix = "") => [
  {
    name: "OpenAI",
    value: "openai",
    logo: OpenAiLogo,
    options: (settings, suffix, onError, onChange) => (
      <OpenAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.openai"),
    requiredConfig: ["OpenAiKey"],
  },
  {
    name: "Azure OpenAI",
    value: "azure",
    logo: AzureOpenAiLogo,
    options: (settings, suffix, onError, onChange) => (
      <AzureAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.azure"),
    requiredConfig: ["AzureOpenAiEndpoint"],
  },
  {
    name: "Anthropic",
    value: "anthropic",
    logo: AnthropicLogo,
    options: (settings, suffix, onError, onChange) => (
      <AnthropicAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.anthropic"),
    requiredConfig: ["AnthropicApiKey"],
  },
  {
    name: "Gemini",
    value: "gemini",
    logo: GeminiLogo,
    options: (settings, suffix, onError, onChange) => (
      <GeminiLLMOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.gemini"),
    requiredConfig: ["GeminiLLMApiKey"],
  },
  {
    name: "Hugging Face",
    value: "huggingface",
    logo: HuggingFaceLogo,
    options: (settings, suffix, onError, onChange) => (
      <HuggingFaceOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.huggingface"),
    requiredConfig: ["HuggingFaceApiKey"],
  },
  {
    name: "Ollama",
    value: "ollama",
    logo: OllamaLogo,
    options: (settings, suffix, onError, onChange) => (
      <OllamaLLMOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.ollama"),
    requiredConfig: ["OllamaApiHost"],
  },
  {
    name: "LM Studio",
    value: "lmstudio",
    logo: LMStudioLogo,
    options: (settings, suffix, onError, onChange) => (
      <LMStudioOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.lmstudio"),
    requiredConfig: ["LMStudioURL"],
  },
  {
    name: "Local AI",
    value: "localai",
    logo: LocalAiLogo,
    options: (settings, suffix, onError, onChange) => (
      <LocalAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.localai"),
    requiredConfig: ["LocalAiURL"],
  },
  {
    name: "Together AI",
    value: "togetherai",
    logo: TogetherAILogo,
    options: (settings, suffix, onError, onChange) => (
      <TogetherAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.together-ai"),
    requiredConfig: ["TogetherAiApiKey"],
  },
  {
    name: "Fireworks AI",
    value: "fireworksai",
    logo: FireworksAILogo,
    options: (settings, suffix, onError, onChange) => (
      <FireworksAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.fireworks-ai"),
    requiredConfig: ["FireworksAiApiKey"],
  },
  {
    name: "Mistral",
    value: "mistral",
    logo: MistralLogo,
    options: (settings, suffix, onError, onChange) => (
      <MistralOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.mistral"),
    requiredConfig: ["MistralApiKey"],
  },
  {
    name: "Perplexity",
    value: "perplexity",
    logo: PerplexityLogo,
    options: (settings, suffix, onError, onChange) => (
      <PerplexityOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.perplexity"),
    requiredConfig: ["PerplexityApiKey"],
  },
  {
    name: "Open Router",
    value: "openrouter",
    logo: OpenRouterLogo,
    options: (settings, suffix, onError, onChange) => (
      <OpenRouterOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.openrouter"),
    requiredConfig: ["OpenRouterApiKey"],
  },
  {
    name: "Groq",
    value: "groq",
    logo: GroqLogo,
    options: (settings, suffix, onError, onChange) => (
      <GroqAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.groq"),
    requiredConfig: ["GroqApiKey"],
  },
  {
    name: "Cohere",
    value: "cohere",
    logo: CohereLogo,
    options: (settings, suffix, onError, onChange) => (
      <CohereAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.cohere"),
    requiredConfig: ["CohereApiKey"],
  },
  {
    name: "KoboldCPP",
    value: "koboldcpp",
    logo: KoboldCPPLogo,
    options: (settings, suffix, onError, onChange) => (
      <KoboldCPPOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.koboldcpp"),
    requiredConfig: ["KoboldCPPUrl"],
  },
  {
    name: "text-generation-webui",
    value: "textgenwebui",
    logo: TextGenWebUILogo,
    options: (settings, suffix, onError, onChange) => (
      <TextGenWebUIOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.textgenwebui"),
    requiredConfig: ["TextgenUrl", "TextgenApiKey"],
  },
  {
    name: "LiteLLM",
    value: "litellm",
    logo: LiteLLMLogo,
    options: (settings, suffix, onError, onChange) => (
      <LiteLLMOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.litellm"),
    requiredConfig: ["LiteLLMApiKey", "LiteLLMBaseURL"],
  },
  {
    name: "AWS Bedrock",
    value: "bedrock",
    logo: AWSBedrockLogo,
    options: (settings, suffix, onError, onChange) => (
      <AWSBedrockLLMOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.bedrock"),
    requiredConfig: [
      "BedrockAwsRegion",
      "BedrockAwsAccessKey",
      "BedrockAwsSecretKey",
    ],
  },
  {
    name: "DeepSeek",
    value: "deepseek",
    logo: DeepSeekLogo,
    options: (settings, suffix, onError, onChange) => (
      <DeepSeekOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.deepseek"),
    requiredConfig: ["DeepSeekApiKey"],
  },
  {
    name: "Generic OpenAI API",
    value: "generic-openai",
    logo: GenericOpenAiLogo,
    options: (settings, suffix, onError, onChange) => (
      <GenericOpenAiOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    description: t("llm-provider.openai-generic"),
    requiredConfig: ["GenericOpenAiKey", "GenericOpenAiBaseUrl"],
  },
  {
    name: "Native",
    value: "native",
    options: (settings, suffix, onError, onChange) => (
      <NativeLLMOptions
        settings={settings}
        moduleSuffix={suffix || moduleSuffix}
        onError={onError}
        onChange={onChange}
      />
    ),
    icon: <Gear size={32} weight="bold" className="text-foreground" />,
    description: t("llm-provider.system-default"),
    requiredConfig: [],
  },
];

export const getModelPrefKey = (provider, suffix = "") => {
  const providerLower = provider?.toLowerCase();
  switch (providerLower) {
    case "gemini":
      return `GeminiLLMModelPref${suffix}`;
    case "ollama":
      return `OllamaLLMModelPref${suffix}`;
    case "native":
      return `NativeLLMModelPref${suffix}`;
    case "litellm":
      return `LiteLLMModelPref${suffix}`;
    case "openai":
      return `OpenAiModelPref${suffix}`;
    case "azureopenai":
      return `AzureOpenAiModelPref${suffix}`;
    case "genericopenai":
      return `GenericOpenAiModelPref${suffix}`;
    case "lmstudio":
      return `LmStudioModelPref${suffix}`;
    case "togetherai":
      return `TogetherAiModelPref${suffix}`;
    case "openrouter":
      return `OpenRouterModelPref${suffix}`;
    case "anthropic":
      return `AnthropicModelPref${suffix}`;
    case "mistral":
      return `MistralModelPref${suffix}`;
    case "groq":
      return `GroqModelPref${suffix}`;
    case "perplexity":
      return `PerplexityModelPref${suffix}`;
    case "deepseek":
      return `DeepseekModelPref${suffix}`;
    case "xai":
      return `XAiModelPref${suffix}`;
    default: {
      const providerPrefix =
        provider?.charAt(0).toUpperCase() + provider?.slice(1);
      return `${providerPrefix}ModelPref${suffix}`;
    }
  }
};
