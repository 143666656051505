import { useState, useEffect } from "react";
import System from "@/models/system";
import { useTranslation } from "react-i18next";

export default function MistralOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(
    settings?.[`MistralApiKey${moduleSuffix}`]
  );
  const [mistralKey, setMistralKey] = useState(
    settings?.[`MistralApiKey${moduleSuffix}`]
  );

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("mistral.api-key")}
        </label>
        <input
          type="password"
          name={`MistralApiKey${moduleSuffix}`}
          className="dark-input-mdl text-foreground  text-foreground text-sm rounded-lg block w-full p-2.5"
          placeholder={t("mistral.api-key-placeholder")}
          defaultValue={
            settings?.[`MistralApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => setMistralKey(inputValue)}
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <MistralModelSelection
          settings={settings}
          apiKey={mistralKey}
          moduleSuffix={moduleSuffix}
        />
      )}
    </div>
  );
}

function MistralModelSelection({ apiKey, settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      if (!apiKey) {
        setCustomModels([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const { models } = await System.customModels(
        "mistral",
        typeof apiKey === "boolean" ? null : apiKey
      );
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, [apiKey]);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("mistral.model-selection")}
        </label>
        <select
          name={`MistralModelPref${moduleSuffix}`}
          disabled={true}
          value="loading"
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
        >
          <option value="loading" disabled={true} className="text-foreground">
            {apiKey ? t("mistral.loading-models") : t("mistral.waiting-key")}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-4">
        {t("mistral.model-selection")}
      </label>
      <select
        name={`MistralModelPref${moduleSuffix}`}
        required={true}
        value={settings?.[`MistralModelPref${moduleSuffix}`] || ""}
        className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {customModels.length > 0 && (
          <optgroup label={t("mistral.available-models")}>
            {customModels.map((model) => (
              <option
                className="text-foreground"
                key={model.id}
                value={model.id}
              >
                {model.id}
              </option>
            ))}
          </optgroup>
        )}
      </select>
    </div>
  );
}
