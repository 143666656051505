import { useState, useEffect } from "react";
import { ensureI18nInitialized } from "@/i18n";
import { FullScreenLoader } from "@/components/Preloader";

const I18nLoader = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const initI18n = async () => {
      try {
        await ensureI18nInitialized();
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };

    initI18n();
  }, []);

  if (!isLoaded) {
    return <FullScreenLoader />;
  }

  return children;
};

export default I18nLoader;
