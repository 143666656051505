import System from "@/models/system";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function FireworksAiOptions({ settings }) {
  const { t } = useTranslation();
  return (
    <div className="flex gap-[36px] mt-1.5">
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("fireworksai.api-key")}
        </label>
        <input
          type="password"
          name="FireworksAiLLMApiKey"
          className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2.5"
          placeholder={t("fireworksai.api-key-placeholder")}
          defaultValue={settings?.FireworksAiLLMApiKey ? "*".repeat(20) : ""}
          required={true}
          autoComplete="off"
          spellCheck={false}
        />
      </div>
      {!settings?.credentialsOnly && (
        <FireworksAiModelSelection settings={settings} />
      )}
    </div>
  );
}
function FireworksAiModelSelection({ settings }) {
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("fireworksai");
      if (models?.length > 0) {
        const modelsByOrganization = models.reduce((acc, model) => {
          acc[model.organization] = acc[model.organization] || [];
          acc[model.organization].push(model);
          return acc;
        }, {});
        setGroupedModels(modelsByOrganization);
      }
      setLoading(false);
    }
    findCustomModels();
  }, []);
  if (loading || Object.keys(groupedModels).length === 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("fireworksai.model-selection")}
        </label>
        <select
          name="FireworksAiLLMModelPref"
          disabled={true}
          value="loading"
          className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2.5"
        >
          <option value="loading" disabled={true}>
            {t("fireworksai.loading-models")}
          </option>
        </select>
      </div>
    );
  }
  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-4">
        {t("fireworksai.model-selection")}
      </label>
      <select
        name="FireworksAiLLMModelPref"
        required={true}
        value={settings?.FireworksAiLLMModelPref || ""}
        className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2.5"
      >
        {Object.keys(groupedModels)
          .sort()
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
}
