import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@/utils/classes";

const variantClasses = {
  default: "bg-primary text-white",
  outline: "border bg-elevated hover:bg-secondary",
  secondary: " border bg-secondary hover:bg-secondary-hover text-foreground",
  ghost: "bg-transparent hover:bg-secondary-hover text-foreground",
  destructive:
    "bg-transparent text-red-600 hover:text-red-700 hover:bg-red-100 ",
};

const sizeClasses = {
  default: "h-12 px-4 py-2 text-base",
  sm: "h-9 rounded-md px-3 text-sm",
  icon: "h-10 w-10 text-sm",
};

const baseClasses =
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-5 [&_svg]:shrink-0";

export const Button = ({
  variant = "default",
  size = "default",
  className,
  children,
  asChild = false,
  ...props
}) => {
  const Component = asChild ? Slot : "button";

  return (
    <Component
      className={cn(
        baseClasses,
        variantClasses[variant],
        sizeClasses[size],
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
