import React, { useState, useEffect, useRef, useCallback } from "react";
import System from "@/models/system";
import showToast from "@/utils/toast";
import paths from "@/utils/paths";
import { useNavigate } from "react-router-dom";
import { AUTH_TIMESTAMP, AUTH_TOKEN, AUTH_USER } from "@/utils/constants";
import { useTranslation } from "react-i18next";

export default function UserSetup({ setHeader, setForwardBtn, setBackBtn }) {
  const [multiUserLoginValid, setMultiUserLoginValid] = useState(false);
  const myTeamSubmitRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleForward = useCallback(() => {
    if (myTeamSubmitRef.current) {
      myTeamSubmitRef.current.click();
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate(paths.onboarding.llmPreference());
  }, [navigate]);

  useEffect(() => {
    setForwardBtn({
      showing: true,
      disabled: !multiUserLoginValid,
      onClick: handleForward,
    });
  }, [multiUserLoginValid, setForwardBtn, handleForward]);

  useEffect(() => {
    setHeader({
      title: t("onboarding.user-setup.title"),
      description: t("onboarding.user-setup.description"),
    });
    setBackBtn({ showing: true, disabled: false, onClick: handleBack });
  }, [handleBack, setBackBtn, setHeader, t]);

  return (
    <div className="w-full flex items-center justify-center flex-col gap-y-6">
      <div className="w-full flex items-center justify-center border max-w-[600px] rounded-lg border-white/20">
        <MyTeam
          setMultiUserLoginValid={setMultiUserLoginValid}
          myTeamSubmitRef={myTeamSubmitRef}
          navigate={navigate}
        />
      </div>
    </div>
  );
}

const MyTeam = ({ setMultiUserLoginValid, myTeamSubmitRef, navigate }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {
      username: formData.get("username"),
      password: formData.get("password"),
    };

    // First try to create the admin user directly
    const { user, token, error } = await System.requestToken(data);

    if (error) {
      // If user doesn't exist, try to set up multi-user mode which will create the admin
      const { success, error: setupError } = await System.setupMultiUser(data);
      if (!success) {
        showToast(t("show-toast.setup-error", { error: setupError }), "error");
        return;
      }
      // Get the token for the newly created admin
      const { user: newUser, token: newToken } =
        await System.requestToken(data);
      window.localStorage.setItem(AUTH_USER, JSON.stringify(newUser));
      window.localStorage.setItem(AUTH_TOKEN, newToken);
      window.localStorage.removeItem(AUTH_TIMESTAMP);
    } else {
      // User already exists, just set the auth token
      window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
      window.localStorage.setItem(AUTH_TOKEN, token);
      window.localStorage.removeItem(AUTH_TIMESTAMP);
    }

    navigate(paths.onboarding.dataHandling());
  };

  const setNewUsername = (e) => setUsername(e.target.value);
  const setNewPassword = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (username.length >= 6 && password.length >= 8) {
      setMultiUserLoginValid(true);
    } else {
      setMultiUserLoginValid(false);
    }
  }, [username, password, setMultiUserLoginValid]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col w-full md:px-8 px-2 py-4 text-foreground">
        <div className="space-y-6 flex h-full w-full">
          <div className="w-full flex flex-col gap-y-4">
            <div>
              <label htmlFor="name" className="block mb-3 text-sm font-medium ">
                {t("onboarding.user-setup.username")}
              </label>
              <input
                className=" text-sm rounded-lg block w-full p-2.5 dark-input-mdl"
                name="username"
                type="text"
                placeholder={t("onboarding.placeholder.admin-username")}
                minLength={6}
                required={true}
                autoComplete="off"
                onChange={setNewUsername}
              />
            </div>
            <div className="mt-4">
              <label htmlFor="name" className="block mb-3 text-sm font-medium ">
                {t("onboarding.user-setup.password")}
              </label>
              <input
                className=" text-sm rounded-lg block w-full p-2.5 dark-input-mdl"
                name="password"
                type="password"
                placeholder={t("onboarding.placeholder.admin-password")}
                minLength={8}
                required={true}
                autoComplete="off"
                onChange={setNewPassword}
              />
            </div>
            <p className="w-96  text-opacity-80 text-xs">
              {t("onboarding.user-setup.account-requirment")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between items-center px-6 py-4 space-x-6 border-t rounded-b border-gray-500/50">
        <div className=" text-opacity-80 text-xs">
          {t("onboarding.user-setup.password-note")}
        </div>
      </div>
      <button
        type="submit"
        ref={myTeamSubmitRef}
        hidden
        aria-hidden="true"
      ></button>
    </form>
  );
};
