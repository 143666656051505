import System from "@/models/system";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function TogetherAiOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("togetherai.api-key")}
        </label>
        <input
          type="password"
          name={`TogetherAiApiKey${moduleSuffix}`}
          placeholder={t("togetherai.api-key-placeholder")}
          defaultValue={
            settings?.[`TogetherAiApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          className="modal-search-block text-foreground  text-sm rounded-lg block w-full p-2.5"
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <TogetherAiModelSelection
          settings={settings}
          moduleSuffix={moduleSuffix}
        />
      )}
    </div>
  );
}
function TogetherAiModelSelection({ settings, moduleSuffix = "" }) {
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("togetherai");

      if (models?.length > 0) {
        const modelsByOrganization = models.reduce((acc, model) => {
          acc[model.organization] = acc[model.organization] || [];
          acc[model.organization].push(model);
          return acc;
        }, {});

        setGroupedModels(modelsByOrganization);
      }

      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || Object.keys(groupedModels).length === 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("togetherai.model-selection")}
        </label>
        <select
          name={`TogetherAiModelPref${moduleSuffix}`}
          disabled={true}
          value="loading"
          className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
        >
          <option value="loading" disabled={true} className="text-foreground">
            {t("togetherai.loading-models")}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-4">
        {t("togetherai.model-selection")}
      </label>
      <select
        name={`TogetherAiModelPref${moduleSuffix}`}
        required={true}
        value={settings?.[`TogetherAiModelPref${moduleSuffix}`] || ""}
        className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {Object.keys(groupedModels)
          .sort()
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option
                  className="text-foreground"
                  key={model.id}
                  value={model.id}
                >
                  {model.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
}
