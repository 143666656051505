/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import { API_BASE, currentModuleSlug } from "@/utils/constants";
import { baseHeaders } from "@/utils/request";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import WorkspaceThread from "@/models/workspaceThread";
import { v4 } from "uuid";
import { ABORT_STREAM_EVENT } from "@/utils/chat";

const Workspace = {
  // Keep track of pending workspace creation requests
  _pendingCreations: new Map(),

  new: async function (data = {}) {
    const slugModule = await currentModuleSlug();

    // Generate a unique key for this workspace creation request
    const requestKey = `${slugModule}_${data.name || "unnamed"}_${Date.now()}`;

    // Check if there's already a pending request for this workspace
    if (this._pendingCreations.has(requestKey)) {
      console.log("Duplicate workspace creation prevented");
      return this._pendingCreations.get(requestKey);
    }

    // Create a promise for this request and store it
    const creationPromise = (async () => {
      try {
        const { workspace, message } = await fetch(
          `${API_BASE}/workspace/new/${slugModule}`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: baseHeaders(),
          }
        )
          .then((res) => res.json())
          .catch((_) => {
            return { workspace: null, message: "Error fetching workspace" };
          });

        return { workspace, message };
      } finally {
        // Remove the pending request after a small delay
        setTimeout(() => {
          this._pendingCreations.delete(requestKey);
        }, 1000);
      }
    })();

    // Store the promise
    this._pendingCreations.set(requestKey, creationPromise);

    // Return the promise result
    return creationPromise;
  },
  update: async function (slug, data = {}) {
    const { workspace, message } = await fetch(
      `${API_BASE}/workspace/${slug}/update`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .catch((_) => {
        return { workspace: null, message: "Error fetching workspace" };
      });

    return { workspace, message };
  },
  updateOrder: async function (workspaces) {
    const { success, message } = await fetch(
      `${API_BASE}/workspace/update-order`,
      {
        method: "POST",
        body: JSON.stringify(workspaces),
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .catch((_) => {
        return { success: false, message: "Error updating workspace order" };
      });

    return { success, message };
  },
  all: async function () {
    // Get the current module directly from localStorage for consistency
    const moduleFromStorage = window.localStorage.getItem("module");
    const moduleSlug =
      moduleFromStorage === "document-drafting"
        ? "document-drafting"
        : "legal-qa";

    // Use a cache-busting query parameter
    const timestamp = Date.now();

    const workspaces = await fetch(
      `${API_BASE}/workspaces/${moduleSlug}?_=${timestamp}`,
      {
        method: "GET",
        headers: {
          ...baseHeaders(),
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => res.workspaces || [])
      .catch((_) => {
        console.error("Error fetching workspaces:");
        return [];
      });

    return workspaces;
  },
  fromAllModules: async function () {
    const moduleTypes = ["legal-qa", "document-drafting"];
    try {
      const workspaces = await Promise.all(
        moduleTypes.map((type) =>
          fetch(`${API_BASE}/workspaces/${type}`, {
            method: "GET",
            headers: baseHeaders(),
          })
            .then((res) => res.json())
            .then((res) => res.workspaces || [])
            .catch(() => [])
        )
      );
      return workspaces.flat();
    } catch (error) {
      console.error("Error fetching all workspaces:", error);
      return [];
    }
  },
  getPopulatedWorkspaces: async function () {
    try {
      const response = await fetch(`${API_BASE}/populated-workspaces`, {
        method: "GET",
        headers: baseHeaders(),
      });

      if (!response.ok) {
        throw new Error(
          `Error fetching populated workspaces: ${response.status}`
        );
      }

      const data = await response.json();
      return data.workspaces || [];
    } catch (error) {
      console.error("Error fetching populated workspaces:", error);
      return [];
    }
  },
  modifyEmbeddings: async function (slug, changes = {}) {
    const { workspace, message } = await fetch(
      `${API_BASE}/workspace/${slug}/update-embeddings`,
      {
        method: "POST",
        body: JSON.stringify(changes), // contains 'adds' and 'removes' keys that are arrays of filepaths
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .catch((_) => {
        return { workspace: null, message: "Error modifying embeddings" };
      });

    return { workspace, message };
  },
  chatHistory: async function (slug) {
    const history = await fetch(`${API_BASE}/workspace/${slug}/chats`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => res.history || [])
      .catch(() => []);
    return history;
  },
  chatLog: async function (chatId) {
    try {
      const response = await fetch(`${API_BASE}/workspace/chat-log/${chatId}`, {
        method: "GET",
        headers: baseHeaders(),
      });
      const data = await response.json();
      return data.chatLog || null;
    } catch (error) {
      console.error("Error fetching chat log:", error);
      return null;
    }
  },
  legalTask: async function (slug, legalTask) {
    try {
      const response = await fetch(`${API_BASE}/workspace/${slug}/legal-task`, {
        method: "POST",
        body: JSON.stringify({ slug, legalTask }),
        headers: baseHeaders(),
      });

      if (!response.ok) {
        console.error(
          `Server error: ${response.status} ${response.statusText}`
        );
        return null;
      }

      const data = await response.json();
      return data.legalTask || null;
    } catch (error) {
      console.error("Error retrieving legal task:", error);
      return null;
    }
  },

  updateChatFeedback: async function (chatId, slug, feedback) {
    const result = await fetch(
      `${API_BASE}/workspace/${slug}/chat-feedback/${chatId}`,
      {
        method: "POST",
        headers: baseHeaders(),
        body: JSON.stringify({ feedback }),
      }
    )
      .then((res) => res.ok)
      .catch(() => false);
    return result;
  },
  deleteChat: async function (chatId, slug = null) {
    if (slug) {
      // Original workspace-specific delete chat
      return await fetch(
        `${API_BASE}/workspace/${slug}/delete-chat/${chatId}`,
        {
          method: "DELETE",
          headers: baseHeaders(),
        }
      )
        .then((res) => res.ok)
        .catch(() => false);
    }
    // General workspace chat deletion
    return await fetch(`${API_BASE}/workspace/workspace-chats/${chatId}`, {
      method: "PUT",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .catch((_) => {
        console.error("Error deleting chat:");
        return { success: false, error: "Error deleting chat" };
      });
  },
  deleteChats: async function (slug = "", chatIds = []) {
    return await fetch(`${API_BASE}/workspace/${slug}/delete-chats`, {
      method: "DELETE",
      headers: baseHeaders(),
      body: JSON.stringify({ chatIds }),
    })
      .then((res) => {
        if (res.ok) return true;
        throw new Error("Failed to delete chats.");
      })
      .catch((_) => {
        console.error("Error deleting chats:");
        return false;
      });
  },
  deleteEditedChats: async function (slug = "", threadSlug = "", startingId) {
    if (threadSlug)
      return this.threads._deleteEditedChats(slug, threadSlug, startingId);
    return this._deleteEditedChats(slug, startingId);
  },
  updateChatResponse: async function (
    slug = "",
    threadSlug = "",
    chatId,
    newText
  ) {
    if (threadSlug)
      return this.threads._updateChatResponse(
        slug,
        threadSlug,
        chatId,
        newText
      );
    return this._updateChatResponse(slug, chatId, newText);
  },
  multiplexStream: async function ({
    workspaceSlug,
    threadSlug = null,
    prompt,
    chatHandler,
    attachments = [],
    chatId = null,
    isCanvasChat = false,
    preventChatCreation = false,
    cdb = false,
    invoice_ref = null,
    abortController = null,
  }) {
    var llmSelected = localStorage.getItem("selectedLLMOption");
    //This is for the binary llm selection, to make sure that the llm is set to 0
    //(first option) when the module is not document drafting or when the llm is not set.
    if (
      llmSelected === null ||
      localStorage.getItem("module") !== "document-drafting"
    ) {
      llmSelected = 0;
    }

    if (threadSlug)
      return this.threads.streamChat(
        { workspaceSlug, threadSlug },
        prompt,
        chatHandler,
        attachments,
        chatId,
        isCanvasChat,
        preventChatCreation,
        cdb,
        llmSelected,
        invoice_ref,
        abortController
      );
    return this.streamChat(
      { slug: workspaceSlug },
      prompt,
      chatHandler,
      attachments,
      chatId,
      isCanvasChat,
      preventChatCreation,
      cdb,
      llmSelected,
      invoice_ref,
      abortController
    );
  },
  streamChat: async function (
    { slug },
    message,
    handleChat,
    attachments = [],
    chatId = null,
    isCanvasChat = false,
    preventChatCreation = false,
    cdb = false,
    llmSelected = 0,
    invoice_ref,
    abortController
  ) {
    if (!abortController) {
      abortController = new AbortController();
    }

    window.addEventListener(ABORT_STREAM_EVENT, () => {
      abortController.abort();
      handleChat({ id: v4(), type: "stopGeneration" });
    });

    const slugModule = await currentModuleSlug();

    try {
      await fetchEventSource(
        `${API_BASE}/workspace/${slug}/stream-chat/${slugModule}?cdb=${cdb}`,
        {
          method: "POST",
          headers: baseHeaders(),
          body: JSON.stringify({
            message,
            attachments,
            module: slugModule,
            chatId,
            isCanvasChat,
            preventChatCreation,
            cdb,
            llmSelected,
            invoice_ref,
          }),
          signal: abortController.signal,
          onmessage(ev) {
            try {
              const chatResult = JSON.parse(ev.data);
              if (chatResult.type === "abort") {
                abortController.abort();
                return;
              }
              handleChat(chatResult);
            } catch (_) {
              handleChat({
                id: v4(),
                type: "abort",
                textResponse: null,
                sources: [],
              });
            }
          },
          onerror(err) {
            handleChat({
              id: v4(),
              type: "abort",
              textResponse: err.message,
              sources: [],
            });
            abortController.abort();
          },
        }
      );
    } catch (error) {
      handleChat({
        id: v4(),
        type: "abort",
        textResponse: error.message,
        sources: [],
      });
    }

    return abortController;
  },
  bySlug: async function (slug = "", includeDocuments = false) {
    const workspace = await fetch(
      `${API_BASE}/workspace/${slug}?includeDocuments=${includeDocuments}`,
      {
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .then((res) => res.workspace)
      .catch(() => null);
    return workspace;
  },
  delete: async function (slug) {
    const result = await fetch(`${API_BASE}/workspace/${slug}`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => res.ok)
      .catch(() => false);

    if (result) {
      window.dispatchEvent(
        new CustomEvent("WORKSPACE_DELETED", {
          detail: { workspaceId: slug },
        })
      );
    }
    return result;
  },
  wipeVectorDb: async function (slug) {
    return await fetch(`${API_BASE}/workspace/${slug}/reset-vector-db`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => res.ok)
      .catch(() => false);
  },
  uploadFile: async function (slug, formData) {
    const slugModule = await currentModuleSlug();
    try {
      const response = await fetch(
        `${API_BASE}/workspace/${slug}/upload/${slugModule}`,
        {
          method: "POST",
          body: formData,
          headers: baseHeaders(),
        }
      );

      const data = await response.json();
      return { response, data };
    } catch (error) {
      // Handle network-level errors
      return {
        response: { ok: false },
        data: {
          error:
            error.name === "TypeError" &&
            error.message.includes("Failed to fetch")
              ? "ERR_INSUFFICIENT_RESOURCES"
              : error.message,
          originalname: formData.get("file").name,
        },
      };
    }
  },
  getTokenCount: async function (slug) {
    try {
      const response = await fetch(
        `${API_BASE}/workspace/${slug}/token-count`,
        {
          method: "GET",
          headers: baseHeaders(),
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to fetch token count:", error);
      return null;
    }
  },
  uploadLink: async function (slug, link) {
    const response = await fetch(`${API_BASE}/workspace/${slug}/upload-link`, {
      method: "POST",
      body: JSON.stringify({ link }),
      headers: baseHeaders(),
    });

    const data = await response.json();
    return { response, data };
  },

  getSuggestedMessages: async function (slug) {
    return await fetch(`${API_BASE}/workspace/${slug}/suggested-messages`, {
      method: "GET",
      cache: "no-cache",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch suggested messages.");
        return res.json();
      })
      .then((res) => res.suggestedMessages)
      .catch((_) => {
        console.error("Error fetching suggested messages:");
        return null;
      });
  },
  setSuggestedMessages: async function (slug, messages) {
    return fetch(`${API_BASE}/workspace/${slug}/suggested-messages`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ messages }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            res.statusText || "Error setting suggested messages."
          );
        }
        return { success: true, ...res.json() };
      })
      .catch((_) => {
        console.error("Error setting suggested messages:");
        return { success: false, error: "Error setting suggested messages" };
      });
  },
  setPinForDocument: async function (slug, docPath, pinStatus) {
    return fetch(`${API_BASE}/workspace/${slug}/update-pin`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ docPath, pinStatus }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            res.statusText || "Error setting pin status for document."
          );
        }
        return true;
      })
      .catch((_) => {
        console.error("Error setting pin status for document:");
        return false;
      });
  },
  setPDRForDocument: async function (slug, docPath, pdrStatus) {
    return fetch(`${API_BASE}/workspace/${slug}/update-pdr`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ docPath, pdrStatus: pdrStatus }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            res.statusText || "Error setting PDR status for document."
          );
        }
        return true;
      })
      .catch((_) => {
        console.error("Error setting PDR status for document:");
        return false;
      });
  },
  ttsMessage: async function (slug, chatId) {
    return await fetch(`${API_BASE}/workspace/${slug}/tts/${chatId}`, {
      method: "GET",
      cache: "no-cache",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok && res.status !== 204) return res.blob();
        throw new Error("Failed to fetch TTS.");
      })
      .then((blob) => (blob ? URL.createObjectURL(blob) : null))
      .catch((_) => {
        return null;
      });
  },
  uploadPfp: async function (formData, slug) {
    return await fetch(`${API_BASE}/workspace/${slug}/upload-pfp`, {
      method: "POST",
      body: formData,
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Error uploading pfp.");
        return { success: true, error: null };
      })
      .catch((_) => {
        console.error("Error uploading pfp:");
        return { success: false, error: "Error uploading pfp" };
      });
  },

  fetchPfp: async function (slug) {
    return await fetch(`${API_BASE}/workspace/${slug}/pfp`, {
      method: "GET",
      cache: "no-cache",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok && res.status !== 204) return res.blob();
        throw new Error("Failed to fetch pfp.");
      })
      .then((blob) => (blob ? URL.createObjectURL(blob) : null))
      .catch((_) => {
        return null;
      });
  },

  removePfp: async function (slug) {
    return await fetch(`${API_BASE}/workspace/${slug}/remove-pfp`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok) return { success: true, error: null };
        throw new Error("Failed to remove pfp.");
      })
      .catch((_) => {
        console.error("Error removing pfp:");
        return { success: false, error: "Error removing pfp" };
      });
  },
  _updateChatResponse: async function (slug = "", chatId, newText) {
    return await fetch(`${API_BASE}/workspace/${slug}/update-chat`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ chatId, newText }),
    })
      .then((res) => {
        if (res.ok) return true;
        throw new Error("Failed to update chat.");
      })
      .catch((_) => {
        console.error("Error updating chat:");
        return false;
      });
  },
  _deleteEditedChats: async function (slug = "", startingId) {
    return await fetch(`${API_BASE}/workspace/${slug}/delete-edited-chats`, {
      method: "DELETE",
      headers: baseHeaders(),
      body: JSON.stringify({ startingId }),
    })
      .then((res) => {
        if (res.ok) return true;
        throw new Error("Failed to delete chats.");
      })
      .catch((_) => {
        console.error("Error deleting edited chats:");
        return false;
      });
  },
  forkThread: async function (slug = "", threadSlug = null, chatId = null) {
    return await fetch(`${API_BASE}/workspace/${slug}/thread/fork`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ threadSlug, chatId }),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Failed to fork thread.");
        return res.json();
      })
      .then((data) => data.newThreadSlug)
      .catch((_) => {
        console.error("Error forking thread:");
        return null;
      });
  },
  /**
   * Uploads and embeds a single file in a single call into a workspace
   * @param {string} slug - workspace slug
   * @param {FormData} formData
   * @returns {Promise<{response: {ok: boolean}, data: {success: boolean, error: string|null, document: {id: string, location:string}|null}}>}
   */
  uploadAndEmbedFile: async function (slug, formData) {
    const slugModule = await currentModuleSlug();
    const response = await fetch(
      `${API_BASE}/workspace/${slug}/upload-and-embed/${slugModule}`,
      {
        method: "POST",
        body: formData,
        headers: baseHeaders(),
      }
    );

    const data = await response.json();
    return { response, data };
  },

  upgradeUserPrompt: async (prompt) => {
    const response = await fetch(`${API_BASE}/upgrade-prompt`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ prompt }),
    });

    const data = await response.json();
    return { response, data };
  },

  /**
   * Deletes and un-embeds a single file in a single call from a workspace
   * @param {string} slug - workspace slug
   * @param {string} documentLocation - location of file eg: custom-documents/my-file-uuid.json
   * @returns {Promise<boolean>}
   */
  deleteAndUnembedFile: async function (slug, documentLocation) {
    const response = await fetch(
      `${API_BASE}/workspace/${slug}/remove-and-unembed`,
      {
        method: "DELETE",
        body: JSON.stringify({ documentLocation }),
        headers: baseHeaders(),
      }
    );
    return response.ok;
  },
  threads: WorkspaceThread,
  getDocumentDraftingSuggestedMessages: async function (slug) {
    try {
      const response = await fetch(
        `${API_BASE}/workspace/${slug}/document-drafting-suggested-messages`,
        {
          method: "GET",
          headers: baseHeaders(),
        }
      );
      const result = await response.json();
      return result.messages || [];
    } catch (error) {
      console.error(
        "Error fetching document drafting suggested messages:",
        error
      );
      return [];
    }
  },
  setDocumentDraftingSuggestedMessages: async function (slug, messages) {
    try {
      const response = await fetch(
        `${API_BASE}/workspace/${slug}/document-drafting-suggested-messages`,
        {
          method: "POST",
          headers: baseHeaders(),
          body: JSON.stringify({ messages }),
        }
      );
      const result = await response.json();
      return { success: response.ok, error: result.message };
    } catch (error) {
      console.error(
        "Error setting document drafting suggested messages:",
        error
      );
      return { success: false, error: error.message };
    }
  },
  async deleteWorkspace(workspaceId) {
    try {
      const response = await fetch(`${API_BASE}/workspace/${workspaceId}`, {
        method: "DELETE",
      });
      const success = response.ok;
      if (success) {
        window.dispatchEvent(
          new CustomEvent("WORKSPACE_DELETED", {
            detail: { workspaceId },
          })
        );
      }
      return success;
    } catch (error) {
      console.error("Error deleting workspace:", error);
      return false;
    }
  },

  async deleteThread(workspaceId, threadId) {
    try {
      const response = await fetch(
        `${API_BASE}/workspace/${workspaceId}/thread/${threadId}`,
        {
          method: "DELETE",
        }
      );
      const success = response.ok;
      if (success) {
        window.dispatchEvent(
          new CustomEvent("THREAD_DELETED", {
            detail: { workspaceId, threadId },
          })
        );
      }
      return success;
    } catch (error) {
      console.error("Error deleting thread:", error);
      return false;
    }
  },
};

export default Workspace;
