import React, { useEffect, useState, useRef } from "react";
import { MODEL_MAP } from "@/utils/AiProviders/modelMap";
import { useTranslation } from "react-i18next";
import { getModelPrefKey } from "../LLMProviderConfig";
import { baseHeaders } from "@/utils/request";

/**
 * Displays the context window size for a selected LLM model
 *
 * @param {Object} props - Component props
 * @param {string} props.provider - The selected LLM provider
 * @param {string} props.moduleSuffix - The module suffix for the context
 * @param {Object} props.settings - The system settings
 * @returns {React.ReactNode}
 */
export default function ContextWindowDisplay({
  provider,
  moduleSuffix,
  settings,
}) {
  const { t } = useTranslation();
  const [contextWindow, setContextWindow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentModelValueRef = useRef(null);
  const previousProviderRef = useRef(provider);
  const initializedRef = useRef(false);
  const fetchInProgressRef = useRef(false);
  const initAttemptRef = useRef(0);
  const initialRequestSentRef = useRef(false);

  const modelPrefKey = getModelPrefKey(provider, moduleSuffix);

  // Helper function to find model value in DOM
  const findModelValueInDom = () => {
    if (!provider) return null;

    const selectElement = document.querySelector(
      `select[name="${modelPrefKey}"]`
    );

    if (
      selectElement &&
      selectElement.value &&
      selectElement.value !== "loading"
    ) {
      return selectElement.value;
    }
    return null;
  };

  // Helper function to find model value from all possible sources
  const findModelValue = () => {
    if (!provider) return null;

    // Try multiple sources to get the model value
    // 1. Check DOM first (most up-to-date)
    const domValue = findModelValueInDom();
    if (domValue) {
      console.debug("Found model value in DOM:", { provider, model: domValue });
      return domValue;
    }

    // 2. Check settings object
    if (settings && settings[modelPrefKey]) {
      console.debug("Found model value in settings:", {
        provider,
        model: settings[modelPrefKey],
      });
      return settings[modelPrefKey];
    }

    // 3. Last resort - try to find any select with relevant name patterns
    const selectElements = document.querySelectorAll("select");
    for (const select of selectElements) {
      if (
        select.name.includes(provider.toLowerCase()) &&
        select.name.includes("Model") &&
        select.value &&
        select.value !== "loading"
      ) {
        console.debug("Found model value in related select:", {
          provider,
          model: select.value,
          element: select.name,
        });
        return select.value;
      }
    }

    return null;
  };

  // Function to fetch context window from API
  const fetchContextWindow = async (selectedProvider, selectedModel) => {
    if (!selectedProvider || !selectedModel) {
      return null;
    }

    // Set loading state even if there's already a request in progress
    setIsLoading(true);
    initialRequestSentRef.current = true;

    if (fetchInProgressRef.current) {
      console.debug("API request already in progress, waiting...");
      return null;
    }

    try {
      fetchInProgressRef.current = true;
      setError(null);

      // Get API endpoint
      const API_BASE =
        window.location.hostname === "localhost"
          ? "http://localhost:3001/api"
          : "/api";

      // Always try API first to get the most accurate context window
      try {
        const params = new URLSearchParams({
          provider: selectedProvider,
          model: selectedModel,
          settings_suffix: moduleSuffix || "",
        });

        console.debug("Fetching context window from API:", {
          provider: selectedProvider,
          model: selectedModel,
          url: `${API_BASE}/system/context-window-display?${params.toString()}`,
        });

        const response = await fetch(
          `${API_BASE}/system/context-window-display?${params.toString()}`,
          {
            headers: {
              "Content-Type": "application/json",
              ...baseHeaders(),
            },
            // Add a reasonable timeout to prevent long hangs
            signal: AbortSignal.timeout(5000),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch context window info");
        }

        const data = await response.json();
        const contextWindowLimit = data?.contextWindowLimit;

        console.debug("API returned context window info:", {
          provider: selectedProvider,
          model: selectedModel,
          contextWindowLimit,
          data,
        });

        if (contextWindowLimit && contextWindowLimit > 0) {
          setContextWindow(contextWindowLimit);
          setIsLoading(false);
          return contextWindowLimit;
        }

        throw new Error("No valid context window size returned from API");
      } catch (apiError) {
        console.warn(
          "API fetch failed, checking MODEL_MAP as fallback:",
          apiError.message
        );

        // If API fails, check if we have a model-specific context window in the MODEL_MAP
        const normalizedProvider = selectedProvider.toLowerCase();
        const normalizedModel = selectedModel.toLowerCase().trim();

        if (MODEL_MAP[normalizedProvider]?.models?.[normalizedModel]) {
          const contextWindowSize =
            MODEL_MAP[normalizedProvider].models[normalizedModel];
          console.debug("Found context window in MODEL_MAP:", {
            provider: normalizedProvider,
            model: normalizedModel,
            contextWindow: contextWindowSize,
          });
          setContextWindow(contextWindowSize);
          setIsLoading(false);
          return contextWindowSize;
        }

        // If specific model not found, use the default context window from the provider
        if (MODEL_MAP[normalizedProvider]?.defaults?.contextWindow) {
          const defaultContextWindow =
            MODEL_MAP[normalizedProvider].defaults.contextWindow;
          console.debug("Using default context window for provider:", {
            provider: normalizedProvider,
            model: normalizedModel,
            defaultContextWindow,
          });
          setContextWindow(defaultContextWindow);
          setError("Using default context window size");
          setIsLoading(false);
          return defaultContextWindow;
        }

        // If there's no default context window, try to use any model from this provider to get approximate size
        if (MODEL_MAP[normalizedProvider]?.models) {
          const providerModels = MODEL_MAP[normalizedProvider].models;
          // Find the first model in this provider to get a representative size
          const representativeModel = Object.keys(providerModels)[0];
          if (representativeModel) {
            const approximateSize = providerModels[representativeModel];
            console.debug("Using fallback size from provider's first model:", {
              provider: normalizedProvider,
              representativeModel,
              approximateSize,
            });
            setContextWindow(approximateSize);
            setError("Using approximate context window size");
            setIsLoading(false);
            return approximateSize;
          }
        }

        // If all else fails, use a safe default based on common sizes
        const safeDefault = 4096; // Most conservative value
        console.debug("All lookups failed, using safe default:", safeDefault);
        setContextWindow(safeDefault);
        setError("Using default context window size");
        setIsLoading(false);
        return safeDefault;
      }
    } catch (error) {
      console.error("Error fetching context window:", error);
      setError(error.message);
      setIsLoading(false);
      return null;
    } finally {
      fetchInProgressRef.current = false;
    }
  };

  // Reset context window when provider changes
  useEffect(() => {
    // Check if provider has changed
    if (provider !== previousProviderRef.current) {
      console.debug("Provider changed:", {
        previous: previousProviderRef.current,
        current: provider,
      });

      // Reset state for new provider
      setContextWindow(null);
      setError(null);
      setIsLoading(true); // Set to loading when provider changes
      initializedRef.current = false;
      currentModelValueRef.current = null;
      initAttemptRef.current = 0;
      initialRequestSentRef.current = false;

      // Update ref for next comparison
      previousProviderRef.current = provider;

      // Try to find model immediately to update context window
      const modelValue = findModelValue();
      if (provider && modelValue) {
        console.debug(
          "Found model for new provider, fetching context window:",
          {
            provider,
            model: modelValue,
          }
        );
        fetchContextWindow(provider, modelValue);
      }
    }
  }, [provider]);

  // Enhanced initialization with retry mechanism
  useEffect(() => {
    const initializeContextWindow = () => {
      if (initializedRef.current) return;

      // Only proceed if we have provider and settings
      if (!provider || provider === "none" || provider === "default") {
        setIsLoading(false); // Don't show loading if no provider
        return;
      }

      // Increment attempt counter
      initAttemptRef.current += 1;
      console.debug(
        `Initialization attempt #${initAttemptRef.current} for ${provider}`
      );

      // Find model value from all possible sources
      const modelValue = findModelValue();

      // Skip if no model value is found and we haven't tried too many times
      if (!modelValue) {
        if (initAttemptRef.current < 5) {
          // Schedule another attempt with increasing delay
          const delay = initAttemptRef.current * 300;
          console.debug(`No model found yet. Retrying in ${delay}ms...`);
          setTimeout(initializeContextWindow, delay);
        } else {
          console.debug("Failed to find model after multiple attempts");
          setIsLoading(false); // Stop loading if we can't find a model
        }
        return;
      }

      // Make sure we're in loading state until we get a result
      setIsLoading(true);
      console.debug("Initializing context window with:", {
        provider,
        model: modelValue,
      });

      // Fetch context window from API
      fetchContextWindow(provider, modelValue).then((result) => {
        if (result) {
          initializedRef.current = true;
          currentModelValueRef.current = modelValue;
          console.debug("Successfully initialized context window:", {
            provider,
            model: modelValue,
            contextWindow: result,
          });
        } else if (initAttemptRef.current < 5) {
          // If fetch fails but we haven't tried too many times, retry
          const delay = initAttemptRef.current * 300;
          console.debug(
            `Context window fetch failed. Retrying in ${delay}ms...`
          );
          setTimeout(initializeContextWindow, delay);
        } else {
          setIsLoading(false); // Stop loading after max attempts
        }
      });
    };

    // Start initialization process
    initializeContextWindow();
  }, [provider, settings, moduleSuffix]);

  // Immediate initialization attempt on settings change
  useEffect(() => {
    if (
      initializedRef.current ||
      !settings ||
      !provider ||
      fetchInProgressRef.current ||
      initialRequestSentRef.current
    ) {
      return;
    }

    const modelValue = findModelValue();
    if (modelValue) {
      console.debug("Settings changed, re-attempting initialization with:", {
        provider,
        model: modelValue,
      });
      // Make sure we're in loading state
      setIsLoading(true);
      fetchContextWindow(provider, modelValue);
    }
  }, [settings]);

  // Listen for direct model select changes in DOM
  useEffect(() => {
    if (!provider || provider === "none" || provider === "default") {
      return () => {};
    }

    const modelPrefKey = getModelPrefKey(provider, moduleSuffix);

    // Create a handler for select change events
    const handleSelectChange = (event) => {
      if (
        event.target.tagName === "SELECT" &&
        event.target.name === modelPrefKey
      ) {
        const newModelValue = event.target.value;
        currentModelValueRef.current = newModelValue;

        // Fetch updated context window for the new model
        if (provider && newModelValue) {
          console.debug("Model selection changed, fetching context window:", {
            provider,
            model: newModelValue,
          });
          setIsLoading(true); // Set loading state for model change
          fetchContextWindow(provider, newModelValue);
        }
      }
    };

    // Add event listener
    document.addEventListener("change", handleSelectChange);

    // Clean up
    return () => {
      document.removeEventListener("change", handleSelectChange);
    };
  }, [provider, moduleSuffix]);

  // Listen for provider select changes in DOM
  useEffect(() => {
    // Create a handler for provider select changes
    const handleProviderChange = (event) => {
      if (
        event.target.tagName === "SELECT" &&
        (event.target.name === "LLMProvider" ||
          event.target.name?.includes("LLMProvider") ||
          event.target.dataset?.role === "provider-select")
      ) {
        const newProvider = event.target.value;

        // Only process if this is a real change and not our current provider
        if (
          newProvider !== provider &&
          newProvider !== "none" &&
          newProvider !== "default"
        ) {
          console.debug("Provider select element changed:", {
            previous: provider,
            new: newProvider,
            element: event.target.name,
          });

          // Reset state since provider changed
          setContextWindow(null);
          setError(null);
          setIsLoading(true);

          // We don't update the context window here - we'll wait for the component
          // to rerender with the new provider prop, which will trigger our provider effect

          // But we can start looking for a model immediately
          setTimeout(() => {
            const modelValue = findModelValue();
            if (modelValue) {
              console.debug("Found model after provider change:", {
                provider: newProvider,
                model: modelValue,
              });
              fetchContextWindow(newProvider, modelValue);
            }
          }, 100); // Short delay to allow DOM to update
        }
      }
    };

    // Add event listener
    document.addEventListener("change", handleProviderChange);

    // Clean up
    return () => {
      document.removeEventListener("change", handleProviderChange);
    };
  }, [provider]);

  // Force an initialization attempt after the component is fully mounted
  useEffect(() => {
    if (initializedRef.current || !provider || initialRequestSentRef.current) {
      return;
    }

    // Delay initialization slightly to ensure DOM is fully rendered
    const timeoutId = setTimeout(() => {
      if (!initializedRef.current && !initialRequestSentRef.current) {
        console.debug("Attempting delayed initialization:", { provider });
        const modelValue = findModelValue();
        if (modelValue) {
          console.debug("Found model during delayed initialization:", {
            provider,
            model: modelValue,
          });
          setIsLoading(true);
          fetchContextWindow(provider, modelValue);
        } else {
          // If no model found after delayed init, try one more time with a longer delay
          setTimeout(() => {
            if (!initializedRef.current && !initialRequestSentRef.current) {
              const finalModelValue = findModelValue();
              if (finalModelValue) {
                console.debug("Final attempt found model:", {
                  provider,
                  model: finalModelValue,
                });
                setIsLoading(true);
                fetchContextWindow(provider, finalModelValue);
              } else {
                setIsLoading(false);
              }
            }
          }, 800);
        }
      }
    }, 200);

    return () => clearTimeout(timeoutId);
  }, []);

  // Check if the provider is valid (not null, undefined, "none", or "default")
  const isValidProvider =
    provider && provider !== "none" && provider !== "default";

  // Don't render anything if no provider is selected
  if (!isValidProvider) {
    return null;
  }

  return (
    <div className="mt-2 text-sm text-foreground">
      <span className="font-semibold">{t("llm.context-window")}:</span>{" "}
      {isLoading ? (
        <span className="text-gray-400">{t("common.loading")}</span>
      ) : error ? (
        <span className="text-yellow-500">
          {t("llm.context-window-warning")}
        </span>
      ) : contextWindow ? (
        <span>
          {contextWindow.toLocaleString()} {t("llm.tokens")}
        </span>
      ) : (
        <span className="text-gray-400">{t("llm.context-window-waiting")}</span>
      )}
    </div>
  );
}
