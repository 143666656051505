import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  CaretUpDown,
  MagnifyingGlass,
  X,
  Gear,
  ArrowCounterClockwise,
} from "@phosphor-icons/react";
import LLMItem from "../LLMItem";
import { getLLMProviders, getModelPrefKey } from "../LLMProviderConfig";
import { useTranslation } from "react-i18next";
import System from "@/models/system";
import ContextWindowDisplay from "../ContextWindowDisplay";
import { Button } from "@/components/Button";

export default function BaseLLMPreference({
  settings,
  selectedLLM,
  onLLMChange,
  moduleSuffix = "",
  supportedProviders = null,
  onChange = null,
  setHasChanges = null,
  loadSettings = null,
  updateContextWindow = null,
}) {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLLMs, setFilteredLLMs] = useState([]);
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const searchInputRef = useRef(null);
  const [internalSelectedLLM, setInternalSelectedLLM] = useState(selectedLLM);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && e.target.tagName === "INPUT") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const effectiveSelectedLLM = selectedLLM === "default" ? null : selectedLLM;

    if (effectiveSelectedLLM !== internalSelectedLLM) {
      setInternalSelectedLLM(effectiveSelectedLLM);

      if (effectiveSelectedLLM && settings) {
        const modelPrefKey = getModelPrefKey(
          effectiveSelectedLLM,
          moduleSuffix
        );
        const modelValue = settings[modelPrefKey];

        if (modelValue) {
          console.debug("Initial model value detected:", {
            provider: effectiveSelectedLLM,
            model: modelValue,
            key: modelPrefKey,
          });
        }
      }
    }
  }, [selectedLLM, internalSelectedLLM, settings, moduleSuffix]);

  const filteredProviders = useMemo(() => {
    let providers = getLLMProviders(t, moduleSuffix);

    if (supportedProviders) {
      providers = providers.filter((provider) =>
        supportedProviders.includes(provider.value)
      );
    }

    return providers;
  }, [t, moduleSuffix, supportedProviders]);

  const handleXButton = () => {
    if (searchQuery.length > 0) {
      setSearchQuery("");
      if (searchInputRef.current) searchInputRef.current.value = "";
    } else {
      if (searchMenuOpen) {
        handleCloseSearchMenu();
      } else {
        setSearchMenuOpen(true);
      }
    }
  };

  const handleChange = useCallback(
    (args) => {
      if (args && args.isReset && loadSettings) {
        loadSettings().catch((error) => {
          console.error("Error reloading settings after reset:", error);
        });
      } else if (args && args.modelChanged && updateContextWindow) {
        if (args.provider && args.modelValue) {
          updateContextWindow(args.provider, args.modelValue);
        }
      } else if (args && args.autoSaved) {
        // Auto-saved changes don't need the save button
      } else if (args && args.llmChanged) {
        // LLM provider changes are auto-saved, don't show the save button
      } else if (setHasChanges) {
        setHasChanges(true);
      }

      if (onChange) {
        onChange(args);
      }
    },
    [onChange, loadSettings, updateContextWindow, setHasChanges]
  );

  const updateLLMChoice = useCallback(
    (llmValue) => {
      if (typeof onLLMChange === "function") {
        try {
          onLLMChange(llmValue);
        } catch (error) {
          console.error("Error in onLLMChange callback:", error);
        }
      }

      setInternalSelectedLLM(llmValue);
      setSearchMenuOpen(false);
      setSearchQuery("");

      // Only update system settings if we have a real provider value
      if (llmValue) {
        // Notify UI about provider change
        if (typeof handleChange === "function") {
          handleChange({
            llmChanged: true,
            provider: llmValue,
          });
        }

        // Add a delay to allow components to load before trying to select default models
        const providerKey = `LLMProvider${moduleSuffix}`;
        const updates = { [providerKey]: llmValue };

        // Initialize with a default model for certain providers to prevent the no-selection issue
        // This ensures we always have a model selected when switching providers
        if (llmValue === "openai") {
          const openaiModelKey = `OpenAiModelPref${moduleSuffix}`;
          // Set a default model to avoid empty selection state
          updates[openaiModelKey] = "chatgpt-4o-latest";
        } else if (llmValue === "anthropic") {
          const anthropicModelKey = `AnthropicModelPref${moduleSuffix}`;
          updates[anthropicModelKey] = "claude-3-5-sonnet-20241022";
        } else if (llmValue === "gemini") {
          const geminiModelKey = `GeminiLLMModelPref${moduleSuffix}`;
          // Set a fallback default model (matching the first in defaultGeminiModels)
          updates[geminiModelKey] = "chat-bison-001";
        }

        System.updateSystem(updates)
          .then((result) => {
            // Add a short delay to allow components to initialize before trying to auto-select models
            setTimeout(() => {
              if (typeof loadSettings === "function") {
                loadSettings(true);
              }
            }, 500);

            return result;
          })
          .catch((error) => {
            console.error(`Error updating LLM provider: ${error}`);
          });
      }
    },
    [onLLMChange, handleChange, moduleSuffix, loadSettings]
  );

  const shouldShowResetButton = () => {
    try {
      if (!settings) {
        return false;
      }

      const providerKey = `LLMProvider${moduleSuffix}`;
      const settingsKeys = Object.keys(settings);
      const hasLLMProvider = settingsKeys.some(
        (key) =>
          key.toLowerCase() === providerKey.toLowerCase() ||
          key.toLowerCase() === `llm_provider${moduleSuffix}`.toLowerCase()
      );

      let hasModelPreference = false;

      if (moduleSuffix && typeof moduleSuffix === "string") {
        const keys = Object.keys(settings);

        const modelPrefKeys = keys.filter(
          (key) =>
            key &&
            typeof key === "string" &&
            (key.toLowerCase().indexOf("modelpref") !== -1 ||
              key.toLowerCase().indexOf("model_pref") !== -1) &&
            (key.endsWith(moduleSuffix) ||
              key.toLowerCase().endsWith(moduleSuffix.toLowerCase()))
        );

        hasModelPreference = modelPrefKeys.length > 0;
      }

      return hasLLMProvider || hasModelPreference;
    } catch (error) {
      console.error("Error checking if reset button should be shown:", error);
      return false;
    }
  };

  const handleReset = async () => {
    try {
      const suffix =
        moduleSuffix && typeof moduleSuffix === "string" ? moduleSuffix : "";

      try {
        const result = await System.resetLLMSettings(suffix);

        if (!result.success) {
          console.error("Error from resetLLMSettings:", result.error);
          return;
        }
      } catch (resetError) {
        console.error("Exception during resetLLMSettings:", resetError);
        return;
      }

      setInternalSelectedLLM(null);

      if (typeof onLLMChange === "function") {
        try {
          onLLMChange(null);
        } catch (callbackError) {
          console.error("Error in onLLMChange callback:", callbackError);
        }
      }

      if (typeof handleChange === "function") {
        handleChange({ isReset: true });
      }

      try {
        await System.keys(true);
      } catch (refreshError) {
        console.error("Error refreshing settings after reset:", refreshError);
      }
    } catch (error) {
      console.error("Error resetting LLM preference:", error);
    }
  };

  useEffect(() => {
    const filtered = filteredProviders.filter((llm) =>
      llm.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredLLMs(filtered);
  }, [searchQuery, internalSelectedLLM, filteredProviders]);

  const selectedLLMObject = filteredProviders.find(
    (llm) => llm.value === internalSelectedLLM
  );

  const handleCloseSearchMenu = () => {
    setSearchMenuOpen(false);

    setTimeout(() => {
      const modelSelects = document.querySelectorAll(
        `select[name$="${moduleSuffix}"]`
      );
      if (modelSelects.length > 0) {
        modelSelects.forEach((select) => {
          const modelKey = select.name;
          const modelValue = select.value;

          if (modelValue && (!settings || settings[modelKey] !== modelValue)) {
            console.log(
              `Auto-saving model selection for ${modelKey}: ${modelValue}`
            );
            System.updateSystem({
              [modelKey]: modelValue,
            }).catch((error) => {
              console.error(
                `Error saving model selection for ${modelKey}:`,
                error
              );
            });

            if (typeof handleChange === "function") {
              handleChange({
                autoSaved: true,
                modelChanged: true,
                provider: internalSelectedLLM,
                modelKey: modelKey,
                modelValue: modelValue,
              });
            }
          }
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (!internalSelectedLLM) return;

    const parentForm = document.querySelector("form");
    if (!parentForm) return;

    const handleFormSubmit = (event) => {
      const modelSelects = document.querySelectorAll(
        `select[name$="${moduleSuffix}"]`
      );
      if (modelSelects.length > 0) {
        const updates = {};

        modelSelects.forEach((select) => {
          updates[select.name] = select.value;
        });

        if (Object.keys(updates).length > 0) {
          console.log(`Auto-saving model selections on form submit:`, updates);

          System.updateSystem(updates).catch((error) => {
            console.error(`Error saving model selections:`, error);
          });
        }
      }
    };

    parentForm.addEventListener("submit", handleFormSubmit);

    return () => {
      parentForm.removeEventListener("submit", handleFormSubmit);
    };
  }, [internalSelectedLLM, moduleSuffix]);

  useEffect(() => {
    if (!internalSelectedLLM) return;

    const handleModelSelectChange = (event) => {
      if (
        event.target.tagName === "SELECT" &&
        event.target.name.endsWith(moduleSuffix)
      ) {
        const modelKey = event.target.name;
        const modelValue = event.target.value;

        console.log(`Model select changed: ${modelKey} = ${modelValue}`);

        System.updateSystem({
          [modelKey]: modelValue,
        }).catch((error) => {
          console.error(`Error saving model selection for ${modelKey}:`, error);
        });

        if (settings) {
          settings[modelKey] = modelValue;
        }

        if (typeof handleChange === "function") {
          handleChange({
            autoSaved: true,
            modelChanged: true,
            provider: internalSelectedLLM,
            modelKey: modelKey,
            modelValue: modelValue,
          });
        }
      }
    };

    document.addEventListener("change", handleModelSelectChange);

    return () => {
      document.removeEventListener("change", handleModelSelectChange);
    };
  }, [internalSelectedLLM, moduleSuffix, handleChange, settings]);

  return (
    <>
      <div className="relative">
        {searchMenuOpen ? (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-sm z-10"
            onClick={handleCloseSearchMenu}
          />
        ) : null}
        {searchMenuOpen ? (
          <div
            className="top-0 w-full max-w-[640px] max-h-[310px] overflow-auto white-scrollbar min-h-[64px] sidebar-block rounded-md flex flex-col justify-between cursor-pointer z-20"
            style={{
              position: moduleSuffix.includes("_2") ? "fixed" : "absolute",
              maxHeight: "50vh",
              top: moduleSuffix.includes("_2") ? "30%" : "0",
              left: moduleSuffix.includes("_2") ? "50%" : "0",
              transform: moduleSuffix.includes("_2")
                ? "translateX(-50%)"
                : "none",
              width: moduleSuffix.includes("_2") ? "90%" : "100%",
            }}
          >
            <div className="w-full flex flex-col gap-y-1">
              <div className="flex items-center sticky top-0 border-b border-[#9CA3AF] mx-4 modal-search-block">
                <MagnifyingGlass
                  size={20}
                  weight="bold"
                  className="absolute left-4 z-30 text-foreground -ml-4 my-2"
                />
                <input
                  type="text"
                  name="llm-search"
                  autoComplete="off"
                  placeholder={t("chat.llm.search")}
                  className="modal-search-block my-2 z-20 pl-12 h-[38px] w-full px-4 py-1 text-sm outline-none text-foreground placeholder:font-medium"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  ref={searchInputRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
                <X
                  size={20}
                  weight="bold"
                  className="cursor-pointer text-foreground mr-2 hover:text-[#9CA3AF]"
                  onClick={handleXButton}
                />
              </div>
              <div className="flex-1 pl-4 pr-2 flex flex-col gap-y-1 overflow-y-auto white-scrollbar pb-4">
                {filteredLLMs.map((llm) => (
                  <LLMItem
                    key={llm.name}
                    name={llm.name}
                    value={llm.value}
                    image={llm.logo}
                    icon={llm.icon}
                    description={llm.description}
                    checked={internalSelectedLLM === llm.value}
                    onClick={() => updateLLMChoice(llm.value)}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full max-w-[640px] items-center">
            <button
              className="flex-1 h-[64px] rounded-lg flex items-center p-[14px] justify-between cursor-pointer border-2 border-transparent primary-o-btn transition-all duration-300"
              type="button"
              onClick={() => setSearchMenuOpen(true)}
            >
              <div className="flex gap-x-4 items-center">
                {selectedLLMObject?.logo ? (
                  <img
                    src={selectedLLMObject.logo}
                    alt={t("llm.logo-alt", {
                      name:
                        selectedLLMObject?.name || t("chat.llm.no-selection"),
                    })}
                    className="w-8 h-8 object-contain rounded"
                  />
                ) : (
                  <Gear size={32} weight="bold" className="text-foreground" />
                )}
                <div className="flex flex-col text-left">
                  <div className="text-sm font-semibold text-foreground">
                    {selectedLLMObject?.name || t("chat.llm.no-selection")}
                  </div>
                  <div className="mt-1 text-xs text-foreground">
                    {selectedLLMObject?.description ||
                      t("chat.llm.select-provider")}
                  </div>
                </div>
              </div>
              <CaretUpDown
                size={24}
                weight="bold"
                className="text-foreground"
              />
            </button>
            {shouldShowResetButton() && (
              <Button
                variant="icon"
                onClick={handleReset}
                aria-label="reset llm provider"
              >
                <ArrowCounterClockwise
                  size={22}
                  weight="bold"
                  className="text-foreground"
                />
              </Button>
            )}
          </div>
        )}
      </div>
      {internalSelectedLLM && (
        <div className="mt-4 flex flex-col gap-y-1">
          {filteredProviders
            .find((llm) => llm.value === internalSelectedLLM)
            ?.options?.(settings, moduleSuffix, null, (args) => {
              if (typeof handleChange === "function") {
                handleChange(args || { autoSaved: true });
              }
            })}
        </div>
      )}
      <ContextWindowDisplay
        provider={internalSelectedLLM}
        settings={settings}
        moduleSuffix={moduleSuffix}
      />
    </>
  );
}
