import React, { useState, useEffect } from "react";
import System from "../../../models/system";
import SingleUserAuth from "./SingleUserAuth";
import MultiUserAuth from "./MultiUserAuth";
import {
  AUTH_TOKEN,
  AUTH_USER,
  AUTH_TIMESTAMP,
} from "../../../utils/constants";
import { ICON_COMPONENTS } from "@/components/Footer";

export default function PasswordModal({ mode = "single" }) {
  const [customWebsiteLink, setCustomWebsiteLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [footerIcons, setFooterIcons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [websiteLinkData, footerData] = await Promise.all([
          System.fetchCustomWebsiteLink(),
          System.fetchCustomFooterIcons(),
        ]);
        const parsedIcons = footerData.footerData || [];
        setFooterIcons(parsedIcons);
        setCustomWebsiteLink(websiteLinkData.websiteLink || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="h-screen bg-[var(--light-bg)]">
      <div className="pt-[6vw] px-8">
        {mode === "single" ? (
          <SingleUserAuth />
        ) : (
          <MultiUserAuth
            customWebsiteLink={customWebsiteLink}
            Linkloading={loading}
          />
        )}
      </div>

      <div className="md:absolute md:bottom-16 md:mt-0 mt-16 flex flex-col items-center gap-3 w-full">
        {footerIcons && footerIcons.length > 0 && (
          <div className="hidden tenderFlow-icons">
            {footerIcons.map((iconData, index) => {
              return (
                iconData &&
                iconData.icon &&
                iconData.url && (
                  <a
                    key={index}
                    href={iconData.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {React.createElement(
                      ICON_COMPONENTS[iconData.icon] || (() => <span></span>),
                      {
                        size: 24,
                        className: "tender-icon",
                      }
                    )}
                  </a>
                )
              );
            })}
          </div>
        )}

        <div className="text-center">
          {loading ? (
            <p>Loading ...</p>
          ) : (
            customWebsiteLink && (
              <a
                href={customWebsiteLink}
                className="outline-none text-foreground underline"
              >
                Visit the website
              </a>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export function usePasswordModal(notry = false) {
  const [auth, setAuth] = useState({
    loading: true,
    requiresAuth: false,
    mode: "single",
  });

  useEffect(() => {
    async function checkAuthReq() {
      if (!window) return;

      // If the last validity check is still valid
      // we can skip the loading.
      if (!System.needsAuthCheck() && notry === false) {
        setAuth({
          loading: false,
          requiresAuth: false,
          mode: "multi",
        });
        return;
      }

      const settings = await System.keys();
      if (settings?.MultiUserMode) {
        const currentToken = window.localStorage.getItem(AUTH_TOKEN);
        if (currentToken) {
          const valid = notry ? false : await System.checkAuth(currentToken);
          if (!valid) {
            setAuth({
              loading: false,
              requiresAuth: true,
              mode: "multi",
            });
            window.localStorage.removeItem(AUTH_USER);
            window.localStorage.removeItem(AUTH_TOKEN);
            window.localStorage.removeItem(AUTH_TIMESTAMP);
            return;
          } else {
            setAuth({
              loading: false,
              requiresAuth: false,
              mode: "multi",
            });
            return;
          }
        } else {
          setAuth({
            loading: false,
            requiresAuth: true,
            mode: "multi",
          });
          return;
        }
      } else {
        const requiresAuth = settings?.RequiresAuth || false;
        if (!requiresAuth) {
          setAuth({
            loading: false,
            requiresAuth: false,
            mode: "single",
          });
          return;
        }

        const currentToken = window.localStorage.getItem(AUTH_TOKEN);
        if (currentToken) {
          const valid = notry ? false : await System.checkAuth(currentToken);
          if (!valid) {
            setAuth({
              loading: false,
              requiresAuth: true,
              mode: "single",
            });
            window.localStorage.removeItem(AUTH_TOKEN);
            window.localStorage.removeItem(AUTH_USER);
            window.localStorage.removeItem(AUTH_TIMESTAMP);
            return;
          } else {
            setAuth({
              loading: false,
              requiresAuth: false,
              mode: "single",
            });
            return;
          }
        } else {
          setAuth({
            loading: false,
            requiresAuth: true,
            mode: "single",
          });
          return;
        }
      }
    }
    checkAuthReq();
  }, []);

  return auth;
}
