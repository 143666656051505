/**
 * The model name and context window for all know model windows
 * that are available through providers which has discrete model options.
 * This file should be aligned with the server/utils/AiProviders/modelMap.js file.
 */
const MODEL_MAP = {
  anthropic: {
    models: {
      "claude-2.0": 100_000,
      "claude-2.1": 200_000,
      "claude-3-opus-20240229": 200_000,
      "claude-3-sonnet-20240229": 200_000,
      "claude-3-haiku-20240307": 200_000,
      "claude-3-5-sonnet-20240620": 200_000,
      "claude-3-5-sonnet-20241022": 200_000,
      "claude-3-5-haiku": 200_000,
      "claude-3-7-sonnet": 200_000,
      "claude-3-opus": 200_000,
    },
    defaults: {
      contextWindow: 200_000,
      temperature: 0.7,
    },
  },
  cohere: {
    models: {
      "command-r": 128_000,
      "command-r-plus": 128_000,
      command: 4_096,
      "command-light": 4_096,
      "command-nightly": 8_192,
      "command-light-nightly": 8_192,
    },
    defaults: {
      contextWindow: 128_000,
      temperature: 0.7,
    },
  },
  gemini: {
    models: {
      "gemini-pro": 30_720,
      "gemini-1.0-pro": 30_720,
      "gemini-1.5-flash-latest": 1_048_576,
      "gemini-1.5-flash-002": 1_048_576,
      "gemini-1.5-flash-8b": 1_048_576,
      "gemini-1.5-flash-8b-latest": 1_048_576,
      "gemini-1.5-flash-8b-001": 1_048_576,
      "gemini-1.5-pro-latest": 1_048_576,
      "gemini-1.5-pro-002": 1_048_576,
      "gemini-1.5-flash-8b-exp-0827": 1_048_576,
      "gemini-2.0-flash": 1_048_576,
      "gemini-2.0-flash-exp": 1_048_576,
      "gemini-2.0-flash-lite": 1_048_576,
      "gemini-2.0-flash-thinking-exp": 1_048_576,
      "gemini-2.0-pro-exp": 1_048_576,
      "gemini-exp-1206": 1_048_576,
      "gemini-2.5-pro-exp-03-25": 1_048_576,
    },
    defaults: {
      contextWindow: 30_720,
      temperature: 0.7,
    },
  },
  groq: {
    models: {
      "gemma2-9b-it": 8192,
      "gemma-7b-it": 8192,
      "llama3-70b-8192": 8192,
      "llama3-8b-8192": 8192,
      "llama-3.1-70b-versatile": 8000,
      "llama-3.1-8b-instant": 8000,
      "mixtral-8x7b-32768": 32768,
    },
    defaults: {
      contextWindow: 8192,
      temperature: 0.7,
    },
  },
  openai: {
    models: {
      "chatgpt-4o-latest": 128_000,
      "gpt-3.5-turbo": 16_385,
      "gpt-3.5-turbo-1106": 16_385,
      "gpt-4o": 128_000,
      "gpt-4o-2024-08-06": 128_000,
      "gpt-4o-2024-05-13": 128_000,
      "gpt-4o-2024-11-20": 128_000,
      "gpt-4o-mini": 128_000,
      "gpt-4o-mini-2024-07-18": 128_000,
      "gpt-4-turbo": 128_000,
      "gpt-4-1106-preview": 128_000,
      "gpt-4-turbo-preview": 128_000,
      "gpt-4": 8_192,
      "gpt-4-32k": 32_000,
      "o1-preview": 128_000,
      "o1-preview-2024-09-12": 128_000,
      "o1-mini": 128_000,
      "o1-mini-2024-09-12": 128_000,
      "o3-mini": 200_000,
      "o3-mini-2025-01-31": 200_000,
      o1: 200_000,
      "o1-2024-12-17": 200_000,
      "gpt-4-turbo-2024-04-09": 128_000,
    },
    defaults: {
      contextWindow: 128_000,
      temperature: 0.7,
    },
  },
  deepseek: {
    models: {
      "deepseek-chat": 128_000,
      "deepseek-coder": 128_000,
    },
    defaults: {
      contextWindow: 128_000,
      temperature: 0.7,
    },
  },
  localAi: {
    defaults: {
      contextWindow: 8_192,
      temperature: 0.7,
    },
  },
  lmStudio: {
    defaults: {
      contextWindow: 8_192,
      temperature: 0.7,
    },
  },
  perplexity: {
    defaults: {
      contextWindow: 32_000,
      temperature: 0.7,
    },
  },
  liteLLM: {
    defaults: {
      contextWindow: 8_192,
      temperature: 0.7,
    },
  },
  textGenWebUI: {
    defaults: {
      contextWindow: 8_192,
      temperature: 0.7,
    },
  },
};
export { MODEL_MAP };
