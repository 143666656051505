import {
  API_BASE,
  AUTH_TIMESTAMP,
  currentModuleSlug,
  fullApiUrl,
} from "@/utils/constants";
import { baseHeaders, safeJsonParse } from "@/utils/request";
import DataConnector from "./dataConnector";
import LiveDocumentSync from "./experimental/liveSync";
import AgentPlugins from "./experimental/agentPlugins";

const System = {
  cacheKeys: {
    footerIcons: "footer_links",
    supportEmail: "support_email",
    customAppName: "custom_app_name",
    customParagraph: "custom_paragraph",
    customWebsiteLink: "customWebsiteLink",
    customWebsiteVisible: "customWebsiteVisible",
  },
  ping: async function () {
    return await fetch(`${API_BASE}/ping`)
      .then((res) => res.json())
      .then((res) => res?.online || false)
      .catch(() => false);
  },
  totalIndexes: async function (slug = null) {
    const url = new URL(`${fullApiUrl()}/system/system-vectors`);
    if (slug) url.searchParams.append("slug", encodeURIComponent(slug));
    return await fetch(url.toString(), {
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not find indexes.");
        return res.json();
      })
      .then((res) => res.vectorCount)
      .catch(() => 0);
  },
  keys: async function (forceRefresh = false) {
    // Add cache-busting parameter when forceRefresh is true
    const baseUrl = API_BASE.startsWith("http")
      ? API_BASE
      : `${window.location.origin}${API_BASE}`;
    const url = new URL(`${baseUrl}/setup-complete`);
    if (forceRefresh) {
      url.searchParams.append("_t", Date.now());
    }

    return await fetch(url.toString())
      .then((res) => {
        if (!res.ok) throw new Error("Could not find setup information.");
        return res.json();
      })
      .then(async function (res) {
        const results = res.results;

        if (!localStorage.getItem("language") && results.language) {
          localStorage.setItem("language", results.language);
        }

        localStorage.setItem("customColor", results.palette || "");
        return results;
      })
      .catch(() => null);
  },
  localFiles: async function (workspaceId) {
    const slugModule = await currentModuleSlug();
    return await fetch(
      `${API_BASE}/system/local-files/${slugModule}/${workspaceId}`,
      {
        headers: baseHeaders(),
      }
    )
      .then((res) => {
        if (!res.ok) throw new Error("Could not find setup information.");
        return res.json();
      })
      .then((res) => res.localFiles)
      .catch(() => null);
  },
  needsAuthCheck: function () {
    const lastAuthCheck = window.localStorage.getItem(AUTH_TIMESTAMP);
    if (!lastAuthCheck) return true;
    const expiresAtMs = Number(lastAuthCheck) + 60 * 15 * 1000; // expires in 15 minutes instead of 5
    return Number(new Date()) > expiresAtMs;
  },

  checkAuth: async function (currentToken = null) {
    try {
      // Prevent multiple simultaneous auth checks
      if (this._checkingAuth) return true;
      this._checkingAuth = true;

      const valid = await fetch(`${API_BASE}/system/check-token`, {
        headers: baseHeaders(currentToken),
      })
        .then((res) => res.ok)
        .catch((error) => {
          // Only treat as invalid if it's not a network error
          console.error("Auth check error:", error);
          return error.name !== "TypeError";
        });

      if (valid) {
        window.localStorage.setItem(AUTH_TIMESTAMP, Number(new Date()));
      }

      this._checkingAuth = false;
      return valid;
    } catch (error) {
      this._checkingAuth = false;
      // Default to keeping session valid if there's an unexpected error
      console.error("Unexpected auth check error:", error);
      return true;
    }
  },
  requestToken: async function (body) {
    return await fetch(`${API_BASE}/request-token`, {
      method: "POST",
      body: JSON.stringify({ ...body }),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not validate login.");
        return res.json();
      })
      .then((res) => res)
      .catch((e) => {
        return { valid: false, message: e.message };
      });
  },
  recoverAccount: async function (username, recoveryCodes) {
    return await fetch(`${API_BASE}/system/recover-account`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ username, recoveryCodes }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.message || "Error recovering account.");
        }
        return data;
      })
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  resetPassword: async function (token, newPassword, confirmPassword) {
    return await fetch(`${API_BASE}/system/reset-password`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ token, newPassword, confirmPassword }),
    })
      .then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.message || "Error resetting password.");
        }
        return data;
      })
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },

  checkDocumentProcessorOnline: async () => {
    return await fetch(`${API_BASE}/system/document-processing-status`, {
      headers: baseHeaders(),
    })
      .then((res) => res.ok)
      .catch(() => false);
  },
  acceptedDocumentTypes: async () => {
    return await fetch(`${API_BASE}/system/accepted-document-types`, {
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => res?.types)
      .catch(() => null);
  },
  updateSystem: async (data) => {
    return await fetch(`${API_BASE}/system/update-env`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error updating system settings:", e);
        return { newValues: null, error: e.message };
      });
  },
  resetLLMSettings: async (moduleSuffix = "") => {
    return await fetch(`${API_BASE}/system/reset-llm-settings`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ moduleSuffix }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error resetting LLM settings:", e);
        return { success: false, error: e.message };
      });
  },
  updateSystemPassword: async (data) => {
    return await fetch(`${API_BASE}/system/update-password`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setupMultiUser: async (data) => {
    return await fetch(`${API_BASE}/system/enable-multi-user`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setupPublicUser: async (data) => {
    return await fetch(`${API_BASE}/system/enable-public-user`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setupDocumentDrafting: async (data) => {
    return await fetch(`${API_BASE}/system/set-document-drafting`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setDocumentDraftingPrompt: async (data) => {
    return await fetch(`${API_BASE}/system/set-document-drafting-prompt`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setDefaultSettings: async ({
    defaultPrompt,
    vectorSearch,
    validationPrompt,
  }) => {
    return await fetch(`${API_BASE}/system/set-default-settings`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({
        defaultPrompt,
        vectorSearch: vectorSearch ? Number(vectorSearch) : null,
        validationPrompt,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  applyDefaultPromptToAllWorkspaces: async (defaultPrompt) => {
    return await fetch(`${API_BASE}/system/apply-default-prompt`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({
        defaultPrompt,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  applyVectorSearchToAllWorkspaces: async (vectorSearch) => {
    return await fetch(`${API_BASE}/system/apply-vector-search`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({
        vectorSearch: vectorSearch ? Number(vectorSearch) : null,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setDocumentBuilder: async ({
    actionPlanPrompt,
    legalTaskPrompt,
    actionStepsSystemPrompt,
    actionStepsUserPrompt,
    finalStepsSystemPrompt,
    finalStepsUserPrompt,
    actionPlanCombinePrompt,
    actionStepsCombinePrompt,
    finalStepsCombinePrompt,
  }) => {
    return await fetch(`${API_BASE}/system/set-document-builder`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({
        actionPlanPrompt,
        legalTaskPrompt,
        actionStepsSystemPrompt,
        actionStepsUserPrompt,
        finalStepsSystemPrompt,
        finalStepsUserPrompt,
        actionPlanCombinePrompt,
        actionStepsCombinePrompt,
        finalStepsCombinePrompt,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },

  submitLegalTask: async ({ name, subCategory, description, legalPrompt }) => {
    return await fetch(`${API_BASE}/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        sub_category: subCategory,
        description,
        legal_task_prompt: legalPrompt,
      }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error during API call:", e);
        return { success: false, error: e.message };
      });
  },

  fetchLegalTasks: async () => {
    return await fetch(`${API_BASE}/categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error during API call:", e);
        return { success: false, error: e.message };
      });
  },

  deleteCategory: async (id) => {
    return await fetch(`${API_BASE}/categories/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error during API call:", e);
        return { success: false, error: e.message };
      });
  },

  fetchGroupedLegalTasks: async () => {
    return await fetch(`${API_BASE}/categories/grouped`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error during API call:", e);
        return { success: false, error: e.message };
      });
  },

  fetchSubCategories: async (name) => {
    return await fetch(`${API_BASE}/categories/subcategories/${name}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error("Error during API call:", e);
        return { success: false, error: e.message };
      });
  },

  generateLegalTaskPrompt: async (taskDescription, promptTemplate = null) => {
    try {
      const response = await fetch(`${API_BASE}/generate-legal-task-prompt`, {
        method: "POST",
        headers: baseHeaders(),
        body: JSON.stringify({
          taskDescription,
          promptTemplate,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to generate legal task prompt"
        );
      }

      const data = await response.json();
      return { success: true, prompt: data.prompt };
    } catch (error) {
      console.error("Error generating legal task prompt:", error);
      return { success: false, error: error.message };
    }
  },

  setQuraButton: async (data) => {
    return await fetch(`${API_BASE}/system/enable-qura`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  isQura: async () => {
    try {
      const response = await fetch(`${API_BASE}/system/qura`, {
        method: "GET",
        headers: baseHeaders(),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const isQura = data?.qura;

      window.localStorage.setItem("qura", isQura);
      return { qura: isQura };
    } catch (e) {
      console.error(e);
      return { qura: false };
    }
  },
  setPromptOutputLogging: async function (data) {
    return await fetch(`${API_BASE}/system/prompt-output-logging`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok)
          throw new Error("Could not update prompt output logging setting.");
        return res.json();
      })
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },

  isPromptOutputLoggingEnabled: async function () {
    return await fetch(`${API_BASE}/system/prompt-output-logging`, {
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok)
          throw new Error("Could not fetch prompt output logging status.");
        return res.json();
      })
      .then((res) => res.enabled)
      .catch(() => false);
  },
  getCopyOption: async () => {
    return await fetch(`${API_BASE}/system/copyOption`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => res?.qura)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getPerformLegalTask: async () => {
    try {
      const response = await fetch(`${API_BASE}/system/perform-legal-task`, {
        method: "GET",
        headers: baseHeaders(),
      });

      if (!response.ok) {
        throw new Error(
          "There was an issue with the server. Please try again later."
        );
      }

      const data = await response.json();
      const isPerformLegalTaskEnabled = data?.enabled;
      const allowUserAccess = data?.allowUserAccess;
      return { enabled: isPerformLegalTaskEnabled, allowUserAccess };
    } catch (e) {
      console.error("Error fetching perform legal task setting:", e.message);
      return { enabled: false, allowUserAccess: false };
    }
  },
  setPerformLegalTask: async (data) => {
    return await fetch(`${API_BASE}/system/perform-legal-task`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  getCanvasChat: async () => {
    try {
      const response = await fetch(`${API_BASE}/system/canvas-chat`, {
        method: "GET",
        headers: baseHeaders(),
      });

      if (!response.ok) {
        throw new Error(
          "There was an issue with the server. Please try again later."
        );
      }

      const data = await response.json();
      const isCanvasChatEnabled = data?.enabled;
      return { enabled: isCanvasChatEnabled };
    } catch (e) {
      console.error("Error fetching canvas chat setting:", e.message);
      return { enabled: false };
    }
  },
  setCanvasChat: async (enabled) => {
    return await fetch(`${API_BASE}/system/canvas-chat`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(enabled),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  setCitationButton: async (data) => {
    return await fetch(`${API_BASE}/system/enable-citation`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  isCitationButton: async () => {
    return await fetch(`${API_BASE}/system/citation`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then(function (res) {
        window.localStorage.setItem("citationEnabled", res?.citation);
        return res?.citation;
      })
      .catch((e) => {
        console.error(e);
        window.localStorage.setItem("citationEnabled", true);
        return true;
      });
  },
  fetchCustomWebsiteLink: async () => {
    try {
      const res = await fetch(`${API_BASE}/system/get-website-link`, {
        method: "GET",
        headers: baseHeaders(),
      });

      if (!res.ok) {
        const errorText = await res.text();
        console.error("Fetch error response:", errorText);
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();

      return {
        websiteLink: data?.websiteLink || "",
        displayText: data?.displayText || "Copyright IST 2024",
      };
    } catch (e) {
      console.error("Error fetching website link:", e);
      return { websiteLink: "", displayText: "Copyright IST 2024" }; // Ensure default displayText
    }
  },
  updateCustomWebsite: async (data) => {
    if (!data.websiteLink || !data.displayText) {
      console.error("Missing required fields: websiteLink or displayText");
      return {
        success: false,
        error: "Missing required fields: websiteLink or displayText",
      };
    }

    try {
      // Send the API request
      const response = await fetch(`${API_BASE}/system/custom-website-link`, {
        method: "POST",
        headers: baseHeaders(),
        body: JSON.stringify({
          websiteLink: data.websiteLink,
          displayText: data.displayText,
        }),
      });

      // Handle non-OK responses from the API
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Update error response:", errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return { success: result.success, error: result.error || null };
    } catch (error) {
      console.error("Failed to update website link:", error.message);
      return {
        success: false,
        error: error.message || "Failed to update website link",
      };
    }
  },
  isMultiUserMode: async () => {
    return await fetch(`${API_BASE}/system/multi-user-mode`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => res?.multiUserMode)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  isPublicUserMode: async () => {
    return await fetch(`${API_BASE}/system/public-user-mode`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => res?.publicUserMode)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getDocumentDrafting: async () => {
    return await fetch(`${API_BASE}/system/document-drafting`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => {
        const isDocumentDrafting = res?.isDocumentDrafting;
        const isDocumentDraftingLinking = res?.isDocumentDraftingLinking;
        window.localStorage.setItem("document-drafting", isDocumentDrafting);
        window.localStorage.setItem(
          "document-drafting-linking",
          isDocumentDraftingLinking
        );
        return res || {};
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  isDocumentDrafting: async () => {
    return await fetch(`${API_BASE}/system/document-drafting`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => {
        const isDocumentDrafting = res?.documentDrafting;
        window.localStorage.setItem("document-drafting", isDocumentDrafting);
        return isDocumentDrafting;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getDocumentDraftingPrompt: async () => {
    return await fetch(`${API_BASE}/system/get-document-drafting-prompt`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => {
        return {
          documentDraftingPrompt: res?.documentDraftingPrompt || "",
          legalIssuesPrompt: res?.legalIssuesPrompt || "",
          memoPrompt: res?.memoPrompt || "",
          combinePrompt: res?.combinePrompt || "",
          defaultCombinePrompt: res?.defaultCombinePrompt || "",
          defaultDocumentDraftingPrompt:
            res?.defaultDocumentDraftingPrompt || "",
          defaultLegalIssuesPrompt: res?.defaultLegalIssuesPrompt || "",
          defaultMemoPrompt: res?.defaultMemoPrompt || "",
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          documentDraftingPrompt: "",
          legalIssuesPrompt: "",
          memoPrompt: "",
          combinePrompt: "",
          defaultCombinePrompt: "",
          defaultDocumentDraftingPrompt: "",
          defaultLegalIssuesPrompt: "",
          defaultMemoPrompt: "",
        };
      });
  },
  getDefaultSettings: async () => {
    return await fetch(`${API_BASE}/system/get-default-settings`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => ({
        defaultPrompt: res?.defaultPrompt || "",
        vectorSearchTopN: res?.vectorSearchTopN
          ? Number(res.vectorSearchTopN)
          : null,
        validationPrompt: res?.validationPrompt || "",
      }))
      .catch((e) => {
        console.error(e);
        return {
          defaultPrompt: "",
          vectorSearchTopN: null,
          validationPrompt: "",
        };
      });
  },
  getDocumentBuilder: async () => {
    return await fetch(`${API_BASE}/system/get-document-builder`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => ({
        actionPlanPrompt: res?.actionPlanPrompt || "",
        legalTaskPrompt: res?.legalTaskPrompt || "",
        actionStepsSystemPrompt: res?.actionStepsSystemPrompt || "",
        actionStepsUserPrompt: res?.actionStepsUserPrompt || "",
        finalStepsSystemPrompt: res?.finalStepsSystemPrompt || "",
        finalStepsUserPrompt: res?.finalStepsUserPrompt || "",
        actionPlanCombinePrompt: res?.actionPlanCombinePrompt || "",
        actionStepsCombinePrompt: res?.actionStepsCombinePrompt || "",
        finalStepsCombinePrompt: res?.finalStepsCombinePrompt || "",
      }))
      .catch((e) => {
        console.error(e);
        return {
          actionPlanPrompt: "",
          legalTaskPrompt: "",
          actionStepsSystemPrompt: "",
          actionStepsUserPrompt: "",
          finalStepsSystemPrompt: "",
          finalStepsUserPrompt: "",
          actionPlanCombinePrompt: "",
          actionStepsCombinePrompt: "",
          finalStepsCombinePrompt: "",
        };
      });
  },
  deleteDocument: async (name) => {
    return await fetch(`${API_BASE}/system/remove-document`, {
      method: "DELETE",
      headers: baseHeaders(),
      body: JSON.stringify({ name }),
    })
      .then((res) => res.ok)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  deleteDocuments: async (names = []) => {
    return await fetch(`${API_BASE}/system/remove-documents`, {
      method: "DELETE",
      headers: baseHeaders(),
      body: JSON.stringify({ names }),
    })
      .then((res) => res.ok)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  deleteFolder: async (name, slug) => {
    return await fetch(`${API_BASE}/system/remove-folder`, {
      method: "DELETE",
      headers: baseHeaders(),
      body: JSON.stringify({ name, slug }),
    })
      .then((res) => res.ok)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  uploadPfp: async function (formData) {
    return await fetch(`${API_BASE}/system/upload-pfp`, {
      method: "POST",
      body: formData,
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Error uploading pfp.");
        return { success: true, error: null };
      })
      .catch((e) => {
        console.log(e);
        return { success: false, error: e.message };
      });
  },
  uploadLogo: async function (formData) {
    return await fetch(`${API_BASE}/system/upload-logo`, {
      method: "POST",
      body: formData,
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Error uploading logo.");
        return { success: true, error: null };
      })
      .catch((e) => {
        console.log(e);
        return { success: false, error: e.message };
      });
  },
  uploadLogoDark: async function (formData) {
    return await fetch(`${API_BASE}/system/upload-logo-dark`, {
      method: "POST",
      body: formData,
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Error uploading logo.");
        return { success: true, error: null };
      })
      .catch((e) => {
        console.log(e);
        return { success: false, error: e.message };
      });
  },
  fetchCustomFooterIcons: async function () {
    const cache = window.localStorage.getItem(this.cacheKeys.footerIcons);
    const { data, lastFetched } = cache
      ? safeJsonParse(cache, { data: [], lastFetched: 0 })
      : { data: [], lastFetched: 0 };

    if (!!data && Date.now() - lastFetched < 3_600_000)
      return { footerData: data, error: null };

    const { footerData, error } = await fetch(
      `${API_BASE}/system/footer-data`,
      {
        method: "GET",
        cache: "no-cache",
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
        return { footerData: [], error: e.message };
      });

    if (!footerData || !!error) return { footerData: [], error: null };

    const newData = safeJsonParse(footerData, []);
    window.localStorage.setItem(
      this.cacheKeys.footerIcons,
      JSON.stringify({ data: newData, lastFetched: Date.now() })
    );
    return { footerData: newData, error: null };
  },
  fetchSupportEmail: async function () {
    const cache = window.localStorage.getItem(this.cacheKeys.supportEmail);
    const { email, lastFetched } = cache
      ? safeJsonParse(cache, { email: "", lastFetched: 0 })
      : { email: "", lastFetched: 0 };

    if (!!email && Date.now() - lastFetched < 3_600_000)
      return { email: email, error: null };

    const { supportEmail, error } = await fetch(
      `${API_BASE}/system/support-email`,
      {
        method: "GET",
        cache: "no-cache",
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
        return { email: "", error: e.message };
      });

    if (!supportEmail || !!error) return { email: "", error: null };
    window.localStorage.setItem(
      this.cacheKeys.supportEmail,
      JSON.stringify({ email: supportEmail, lastFetched: Date.now() })
    );
    return { email: supportEmail, error: null };
  },

  fetchCustomAppName: async function () {
    const cache = window.localStorage.getItem(this.cacheKeys.customAppName);
    const { appName, lastFetched } = cache
      ? safeJsonParse(cache, { appName: "", lastFetched: 0 })
      : { appName: "", lastFetched: 0 };

    if (!!appName && Date.now() - lastFetched < 3_600_000)
      return { appName: appName, error: null };

    const { customAppName, error } = await fetch(
      `${API_BASE}/system/custom-app-name`,
      {
        method: "GET",
        cache: "no-cache",
        headers: baseHeaders(),
      }
    )
      .then((res) => res.json())
      .catch((e) => {
        console.log(e);
        return { customAppName: "", error: e.message };
      });

    if (!customAppName || !!error) {
      window.localStorage.removeItem(this.cacheKeys.customAppName);
      return { appName: "", error: null };
    }

    window.localStorage.setItem(
      this.cacheKeys.customAppName,
      JSON.stringify({ appName: customAppName, lastFetched: Date.now() })
    );
    return { appName: customAppName, error: null };
  },

  fetchCustomParagraph: async function () {
    const cache = window.localStorage.getItem(this.cacheKeys.customParagraph);
    const { paragraphText, lastFetched } = cache
      ? JSON.parse(cache)
      : { paragraphText: null, lastFetched: 0 };

    if (paragraphText && Date.now() - lastFetched < 3_600_000)
      return { paragraphText, error: null };

    return await fetch(`${API_BASE}/system/custom-paragraph`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch custom paragraph.");
        return res.json();
      })
      .then((res) => {
        const newParagraphText = res.customParagraphText || null;
        window.localStorage.setItem(
          this.cacheKeys.customParagraph,
          JSON.stringify({
            paragraphText: newParagraphText,
            lastFetched: Date.now(),
          })
        );
        return { paragraphText: newParagraphText, error: null };
      })
      .catch((e) => {
        console.error(e);
        return { paragraphText: null, error: e.message };
      });
  },

  fetchPfp: async function (id) {
    return await fetch(`${API_BASE}/system/pfp/${id}`, {
      method: "GET",
      cache: "no-cache",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok && res.status !== 204) return res.blob();
        throw new Error("Failed to fetch pfp.");
      })
      .then((blob) => (blob ? URL.createObjectURL(blob) : null))
      .catch((e) => {
        console.log(e);
        return null;
      });
  },
  removePfp: async function (id) {
    return await fetch(`${API_BASE}/system/remove-pfp`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok) return { success: true, error: null };
        throw new Error("Failed to remove pfp.");
      })
      .catch((e) => {
        console.log(e);
        return { success: false, error: e.message };
      });
  },
  fetchLogo: async function () {
    return await fetch(`${API_BASE}/system/logo`, {
      method: "GET",
      cache: "no-cache",
    })
      .then(async (res) => {
        if (res.ok && res.status !== 204) {
          const isCustomLogo = res.headers.get("X-Is-Custom-Logo") === "true";
          const blob = await res.blob();
          const logoURL = URL.createObjectURL(blob);
          return { isCustomLogo, logoURL };
        }
        throw new Error("Failed to fetch logo!");
      })
      .catch((e) => {
        console.log(e);
        return { isCustomLogo: false, logoURL: null };
      });
  },
  fetchLogoDark: async function () {
    return await fetch(`${API_BASE}/system/logo-dark`, {
      method: "GET",
      cache: "no-cache",
    })
      .then(async (res) => {
        if (res.ok && res.status !== 204) {
          const isCustomDarkModeLogo =
            res.headers.get("X-Is-Custom-Logo") === "true";
          const blob = await res.blob();
          const darkModeLogoURL = URL.createObjectURL(blob);
          return { isCustomDarkModeLogo, darkModeLogoURL };
        }
        throw new Error("Failed to fetch dark mode logo!");
      })
      .catch((e) => {
        console.log(e);
        return { isCustomDarkModeLogo: false, darkModeLogoURL: null };
      });
  },
  isDefaultLogo: async function () {
    return await fetch(`${API_BASE}/system/is-default-logo`, {
      method: "GET",
      cache: "no-cache",
    })
      .then((res) => {
        if (!res.ok) throw new Error("Failed to get is default logo!");
        return res.json();
      })
      .then((res) => res?.isDefaultLogo)
      .catch((e) => {
        console.log(e);
        return null;
      });
  },
  isDefaultDarkModeLogo: async function () {
    return await fetch(`${API_BASE}/system/is-default-logo-dark`, {
      method: "GET",
      cache: "no-cache",
    })
      .then((res) => {
        if (!res.ok) throw new Error("Failed to get is default logo!");
        return res.json();
      })
      .then((res) => res?.isDefaultLogo)
      .catch((e) => {
        console.log(e);
        return null;
      });
  },
  removeCustomLogo: async function () {
    return await fetch(`${API_BASE}/system/remove-logo`, {
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok) return { success: true, error: null };
        throw new Error("Error removing logo!");
      })
      .catch((e) => {
        console.log(e);
        return { success: false, error: e.message };
      });
  },
  removeCustomLogoDark: async function () {
    return await fetch(`${API_BASE}/system/remove-logo-dark`, {
      headers: baseHeaders(),
    })
      .then((res) => {
        if (res.ok) return { success: true, error: null };
        throw new Error("Error removing logo!");
      })
      .catch((e) => {
        console.log(e);
        return { success: false, error: e.message };
      });
  },
  getWelcomeMessages: async function () {
    return await fetch(`${API_BASE}/system/welcome-messages`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch welcome messages.");
        return res.json();
      })
      .catch((e) => {
        return { heading: "", text: "" };
      });
  },
  getPromptExamples: async function () {
    return await fetch(`${API_BASE}/system/prompt-examples`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch prompt examples.");
        return res.json();
      })
      .catch((e) => {
        return [];
      });
  },
  setPromptExamples: async function (examples) {
    return fetch(`${API_BASE}/system/set-prompt-examples`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(examples),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not save prompt examples.");
        return res.json();
      })
      .catch((e) => {
        return { success: false, error: e.message };
      });
  },
  setWelcomeMessages: async function (messages) {
    return fetch(`${API_BASE}/system/set-welcome-messages`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(messages),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not save welcome messages.");
        return res.json();
      })
      .catch((e) => {
        return { success: false, error: e.message };
      });
  },
  getApiKeys: async function () {
    return fetch(`${API_BASE}/system/api-keys`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText || "Error fetching api key.");
        }
        return res.json();
      })
      .catch((e) => {
        console.error(e);
        return { apiKey: null, error: e.message };
      });
  },
  generateApiKey: async function () {
    return fetch(`${API_BASE}/system/generate-api-key`, {
      method: "POST",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText || "Error generating api key.");
        }
        return res.json();
      })
      .catch((e) => {
        console.error(e);
        return { apiKey: null, error: e.message };
      });
  },
  deleteApiKey: async function () {
    return fetch(`${API_BASE}/system/api-key`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => res.ok)
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  customModels: async function (
    provider,
    apiKey = null,
    basePath = null,
    timeout = null
  ) {
    const controller = new AbortController();
    if (timeout) {
      setTimeout(() => {
        controller.abort("Request timed out.");
      }, timeout);
    }

    return fetch(`${API_BASE}/system/custom-models`, {
      method: "POST",
      headers: baseHeaders(),
      signal: controller.signal,
      body: JSON.stringify({
        provider,
        apiKey,
        basePath,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText || "Error finding custom models.");
        }
        return res.json();
      })
      .catch((e) => {
        console.error(e);
        return { models: [], error: e.message };
      });
  },
  chats: async (offset = 0, filters = {}) => {
    return await fetch(`${API_BASE}/system/workspace-chats`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ offset, filters }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
  },
  totalChatCount: async () => {
    return await fetch(`${API_BASE}/system/workspace-chats-count`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((data) => data.totalChats || 0)
      .catch((e) => {
        console.error(e);
        return 0;
      });
  },
  eventLogs: async (offset = 0) => {
    return await fetch(`${API_BASE}/system/event-logs`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({ offset }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return [];
      });
  },
  clearEventLogs: async () => {
    return await fetch(`${API_BASE}/system/event-logs`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  deleteChat: async (chatId) => {
    return await fetch(`${API_BASE}/system/workspace-chats/${chatId}`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.deletedCount && data.totalDeleted) {
          fetch(`${API_BASE}/setup-complete`, {
            method: "GET",
            headers: baseHeaders(),
          }).catch((e) =>
            console.error("Failed to refresh settings after chat deletion:", e)
          );
        }
        return data;
      })
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  deleteOldChats: async (timeframe, value) => {
    return await fetch(`${API_BASE}/system/delete-old-chats`, {
      method: "DELETE",
      headers: baseHeaders(),
      body: JSON.stringify({ timeframe, value }),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return {
          success: false,
          error: e.message,
          deletedCount: 0,
          totalDeleted: 0,
        };
      });
  },
  getChatDeletionCount: async () => {
    return await fetch(`${API_BASE}/setup-complete`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((data) => {
        const settings = data.results || {};
        return parseInt(settings.chat_deletion_count || "0", 10);
      })
      .catch((e) => {
        console.error(e);
        return 0;
      });
  },
  exportChats: async (type = "csv", chatType = "workspace", filters = {}) => {
    const queryParams = new URLSearchParams({
      type,
      chatType,
      filters: JSON.stringify({
        username: filters.user,
        referenceNumber: filters.referenceNumber,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }),
    });

    try {
      const response = await fetch(
        `${API_BASE}/v1/system/export-chats?${queryParams}`,
        {
          headers: baseHeaders(),
        }
      );

      if (!response.ok) {
        // For 502 and other error responses that might return HTML
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("text/html")) {
          throw new Error("Server error. Please try again later.");
        }

        const error = await response.json().catch(() => ({
          message: "Failed to export chats",
        }));
        throw new Error(error.message);
      }

      return await response.text();
    } catch (error) {
      console.error("Error exporting chats:", error);
      return null;
    }
  },
  updateUser: async (data) => {
    return await fetch(`${API_BASE}/system/user`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
  dataConnectors: DataConnector,

  getSlashCommandPresets: async function () {
    return await fetch(`${API_BASE}/system/slash-command-presets`, {
      method: "GET",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch slash command presets.");
        return res.json();
      })
      .then((res) => res.presets)
      .catch((e) => {
        console.error(e);
        return [];
      });
  },

  createSlashCommandPreset: async function (presetData) {
    return await fetch(`${API_BASE}/system/slash-command-presets`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(presetData),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not create slash command preset.");
        return res.json();
      })
      .then((res) => {
        return { preset: res.preset, error: null };
      })
      .catch((e) => {
        console.error(e);
        return { preset: null, error: e.message };
      });
  },

  updateSlashCommandPreset: async function (presetId, presetData) {
    return await fetch(`${API_BASE}/system/slash-command-presets/${presetId}`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(presetData),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not update slash command preset.");
        return res.json();
      })
      .then((res) => {
        return { preset: res.preset, error: null };
      })
      .catch((e) => {
        return { preset: null, error: "Failed to update this command." };
      });
  },

  deleteSlashCommandPreset: async function (presetId) {
    return await fetch(`${API_BASE}/system/slash-command-presets/${presetId}`, {
      method: "DELETE",
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not delete slash command preset.");
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },

  validateResponse: async function ({ answer, chatId }) {
    return await fetch(`${API_BASE}/system/validate-answer`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify({
        answer,
        chatId,
      }),
    })
      .then(async (res) => {
        if (!res.ok) {
          const error = await res.json();
          throw new Error(error.message || "Failed to validate response");
        }
        return res.json();
      })
      .catch((e) => {
        return {
          success: false,
          error: e.message,
          validationResult: null,
        };
      });
  },

  experimentalFeatures: {
    liveSync: LiveDocumentSync,
    agentPlugins: AgentPlugins,
  },

  async processAttachment(file) {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(`${API_BASE}/system/process-attachment`, {
        method: "POST",
        headers: baseHeaders(),
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to process attachment");
      }
      return await response.json();
    } catch (error) {
      console.error("Error processing attachment:", error);
      return { success: false, error: error.message };
    }
  },

  async cleanupTemporaryAttachment(location) {
    try {
      const response = await fetch(`${API_BASE}/document/attachment-cleanup`, {
        method: "POST",
        headers: {
          ...baseHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ location }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to cleanup attachment");
      }

      return { success: true, error: null };
    } catch (error) {
      console.error("Error in cleanupTemporaryAttachment:", error);
      return { success: false, error: error.message };
    }
  },
  updatePromptTemplate: async (data) => {
    return await fetch(`${API_BASE}/system/prompt-template`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },

  getPromptUpgradeTemplate: async () => {
    return await fetch(`${API_BASE}/system/prompt-template`, {
      headers: baseHeaders(),
    })
      .then((res) => res.json())
      .then((res) => res.template)
      .catch((e) => {
        console.error(e);
        return "";
      });
  },

  updateLegalTask: async function ({
    id,
    subCategory,
    description,
    legalPrompt,
  }) {
    try {
      const response = await fetch(`${API_BASE}/categories/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sub_category: subCategory,
          description,
          legal_task_prompt: legalPrompt,
        }),
      });

      const data = await response.json();
      return {
        success: response.ok,
        data: data.success ? data.data : null,
        error: !response.ok ? data.error : null,
      };
    } catch (error) {
      console.error("Error updating legal task:", error);
      return {
        success: false,
        error: error.message,
      };
    }
  },

  customAiSettings: async function () {
    return await fetch(`${API_BASE}/system/custom-ai-settings`, {
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not fetch custom AI settings.");
        return res.json();
      })
      .then((res) => res.settings || {})
      .catch((error) => {
        console.error("Error fetching custom AI settings:", error);
        return {};
      });
  },

  userCustomAiSettings: async function () {
    return await fetch(`${API_BASE}/user/custom-ai-settings`, {
      headers: baseHeaders(),
    })
      .then((res) => {
        if (!res.ok)
          throw new Error("Could not fetch user custom AI settings.");
        return res.json();
      })
      .then((res) => res.settings || {})
      .catch((error) => {
        console.error("Error fetching user custom AI settings:", error);
        return {};
      });
  },

  updateUserCustomAiSettings: async function (data) {
    return await fetch(`${API_BASE}/user/custom-ai-settings`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error("Error updating user custom AI settings:", error);
        return { success: false, error: error.message };
      });
  },

  updatePreferences: async function (updates = {}) {
    return await fetch(`${API_BASE}/system/preferences`, {
      method: "POST",
      headers: baseHeaders(),
      body: JSON.stringify(updates),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Could not update system preferences.");
        return res.json();
      })
      .catch((e) => {
        console.error(e);
        return { success: false, error: e.message };
      });
  },
};

export default System;
