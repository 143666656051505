import { useTranslation } from "react-i18next";

export default function GenericOpenAiOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-4 flex-wrap">
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("generic.base-url")}
          </label>
          <input
            type="url"
            name={`GenericOpenAiBasePath${moduleSuffix}`}
            placeholder="eg: https://proxy.openai.com"
            defaultValue={settings?.[`GenericOpenAiBasePath${moduleSuffix}`]}
            required={true}
            autoComplete="off"
            spellCheck={false}
            className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("generic.api-key")}
          </label>
          <input
            type="password"
            name={`GenericOpenAiKey${moduleSuffix}`}
            placeholder={t("generic.api-key-placeholder")}
            defaultValue={
              settings?.[`GenericOpenAiKey${moduleSuffix}`]
                ? "*".repeat(20)
                : ""
            }
            required={false}
            autoComplete="off"
            spellCheck={false}
            className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("generic.chat-model")}
          </label>
          <input
            type="text"
            name={`GenericOpenAiModelPref${moduleSuffix}`}
            placeholder={t("generic.chat-model-placeholder")}
            defaultValue={settings?.[`GenericOpenAiModelPref${moduleSuffix}`]}
            required={true}
            autoComplete="off"
            className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex gap-x-4 flex-wrap">
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("generic.token-window")}
          </label>
          <input
            type="number"
            name={`GenericOpenAiTokenLimit${moduleSuffix}`}
            placeholder={t("generic.token-window-placeholder")}
            min={1}
            onScroll={(e) => e.target.blur()}
            defaultValue={settings?.[`GenericOpenAiTokenLimit${moduleSuffix}`]}
            required={true}
            autoComplete="off"
            className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("generic.max-tokens")}
          </label>
          <input
            type="number"
            name={`GenericOpenAiMaxTokens${moduleSuffix}`}
            placeholder={t("generic.max-tokens-placeholder")}
            min={1}
            defaultValue={
              settings?.[`GenericOpenAiMaxTokens${moduleSuffix}`] || 1024
            }
            required={true}
            autoComplete="off"
            className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2 focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
}
