import { useTranslation } from "react-i18next";
import { useState } from "react";
import System from "@/models/system";

export default function AnthropicAiOptions({
  settings,
  moduleSuffix = "",
  onError,
  onChange = () => {},
}) {
  const { t } = useTranslation();
  const [apiKeyError, setApiKeyError] = useState("");
  const [inputValue, setInputValue] = useState(
    settings?.[`AnthropicApiKey${moduleSuffix}`]
  );
  const [selectedModel, setSelectedModel] = useState(
    settings?.[`AnthropicModelPref${moduleSuffix}`] ||
      "claude-3-5-sonnet-20241022"
  );

  const validateApiKey = (value) => {
    if (!value.startsWith("sk-ant-")) {
      const error = t("errors.env.anthropic-key-format");
      setApiKeyError(error);
      if (onError) onError(error);
      return false;
    }
    setApiKeyError("");
    if (onError) onError("");
    return true;
  };

  const handleApiKeyChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Notify parent of changes to trigger save button
    onChange();

    if (value && !validateApiKey(value)) {
      e.preventDefault();
      return;
    }
    setApiKeyError("");
    if (onError) onError("");
  };

  const handleApiKeyBlur = () => {
    // Only update if the value changed and is valid
    if (
      inputValue !== settings?.[`AnthropicApiKey${moduleSuffix}`] &&
      (inputValue === "" || validateApiKey(inputValue))
    ) {
      // Update system settings for the API key
      System.updateSystem({
        [`AnthropicApiKey${moduleSuffix}`]: inputValue,
      });
    }
  };

  const handleModelChange = (e) => {
    const newModel = e.target.value;
    setSelectedModel(newModel);

    // Update the system settings with the specific moduleSuffix
    System.updateSystem({
      [`AnthropicModelPref${moduleSuffix}`]: newModel,
    });
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("anthropic.api-key")}
          </label>
          <input
            type="password"
            name={`AnthropicApiKey${moduleSuffix}`}
            placeholder={t("anthropic.api-key-placeholder")}
            defaultValue={
              settings?.[`AnthropicApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
            onChange={handleApiKeyChange}
            onBlur={handleApiKeyBlur}
            className={`dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5 ${
              apiKeyError ? "border-red-500" : ""
            }`}
          />
          {apiKeyError && (
            <div className="text-red-500 text-sm mt-1">{apiKeyError}</div>
          )}
        </div>

        {!settings?.[`credentialsOnly${moduleSuffix}`] && (
          <div className="flex flex-col w-60">
            <label className="text-foreground text-sm font-semibold block mb-4">
              {t("anthropic.model-selection")}
            </label>
            <select
              name={`AnthropicModelPref${moduleSuffix}`}
              value={selectedModel}
              onChange={handleModelChange}
              required={true}
              className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-1.5"
            >
              {[
                "claude-2.0",
                "claude-2.1",
                "claude-3-haiku-20240307",
                "claude-3-opus-20240229",
                "claude-3-sonnet-20240229",
                "claude-3-5-sonnet-20240620",
                "claude-3-5-sonnet-20241022",
                "claude-3-5-haiku",
                "claude-3-7-sonnet",
                "claude-3-opus",
              ].map((model) => {
                return (
                  <option key={model} value={model} className="text-foreground">
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}
