import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { FullScreenLoader } from "../Preloader";
import validateSessionTokenForUser from "@/utils/session";
import paths from "@/utils/paths";
import { AUTH_TIMESTAMP, AUTH_TOKEN, AUTH_USER } from "@/utils/constants";
import { userFromStorage } from "@/utils/request";
import System from "@/models/system";

// Used only for Multi-user mode only as we permission specific pages based on auth role.
// When in single user mode we just bypass any authchecks.
function useIsAuthenticated() {
  const [isAuthd, setIsAuthed] = useState(null);
  const [shouldRedirectToOnboarding, setShouldRedirectToOnboarding] =
    useState(false);
  const [multiUserMode, setMultiUserMode] = useState(false);

  useEffect(() => {
    const validateSession = async () => {
      try {
        const {
          MultiUserMode,
          RequiresAuth,
          LLMProvider = null,
          VectorDB = null,
        } = await System.keys().catch(() => ({
          // Fallback to current state if system check fails
          MultiUserMode: multiUserMode,
          RequiresAuth: true,
          LLMProvider: null,
          VectorDB: null,
        }));

        setMultiUserMode(MultiUserMode);

        // Check for the onboarding redirect condition
        if (
          !MultiUserMode &&
          !RequiresAuth && // Not in Multi-user AND no password set.
          !LLMProvider &&
          !VectorDB
        ) {
          setShouldRedirectToOnboarding(true);
          setIsAuthed(true);
          return;
        }

        if (!MultiUserMode && !RequiresAuth) {
          setIsAuthed(true);
          return;
        }

        // Single User password mode check
        if (!MultiUserMode && RequiresAuth) {
          const localAuthToken = localStorage.getItem(AUTH_TOKEN);
          if (!localAuthToken) {
            setIsAuthed(false);
            return;
          }

          const isValid = await validateSessionTokenForUser().catch(() => true); // Keep session valid on network errors
          setIsAuthed(isValid);
          return;
        }

        const localUser = localStorage.getItem(AUTH_USER);
        const localAuthToken = localStorage.getItem(AUTH_TOKEN);
        if (!localUser || !localAuthToken) {
          setIsAuthed(false);
          return;
        }

        const isValid = await validateSessionTokenForUser().catch(() => true); // Keep session valid on network errors
        if (!isValid) {
          // Only clear auth if we got an explicit invalid response
          localStorage.removeItem(AUTH_USER);
          localStorage.removeItem(AUTH_TOKEN);
          localStorage.removeItem(AUTH_TIMESTAMP);
          setIsAuthed(false);
          return;
        }

        setIsAuthed(true);
      } catch (error) {
        console.error("Session validation error:", error);
        // Keep the current auth state on unexpected errors
        setIsAuthed(true);
      }
    };
    validateSession();
  }, [multiUserMode]);

  return { isAuthd, shouldRedirectToOnboarding, multiUserMode };
}

// Allows only admin to access the route and if in single user mode,
// allows all users to access the route
export function AdminRoute({ Component }) {
  const { isAuthd, shouldRedirectToOnboarding, multiUserMode } =
    useIsAuthenticated();
  if (isAuthd === null) return <FullScreenLoader />;

  if (shouldRedirectToOnboarding) {
    return <Navigate to={paths.onboarding.home()} />;
  }

  const user = userFromStorage();
  return isAuthd && (user?.role === "admin" || !multiUserMode) ? (
    <Component />
  ) : (
    <Navigate to={paths.home()} />
  );
}

// Allows manager and admin to access the route and if in single user mode,
// allows all users to access the route
export function ManagerRoute({ Component }) {
  const { isAuthd, shouldRedirectToOnboarding, multiUserMode } =
    useIsAuthenticated();
  if (isAuthd === null) return <FullScreenLoader />;

  if (shouldRedirectToOnboarding) {
    return <Navigate to={paths.onboarding.home()} />;
  }

  const user = userFromStorage();
  return isAuthd && (user?.role !== "default" || !multiUserMode) ? (
    <Component />
  ) : (
    <Navigate to={paths.home()} />
  );
}

export default function PrivateRoute({ Component }) {
  const { isAuthd, shouldRedirectToOnboarding } = useIsAuthenticated();
  if (isAuthd === null) return <FullScreenLoader />;

  if (shouldRedirectToOnboarding) {
    return <Navigate to="/onboarding" />;
  }

  return isAuthd ? <Component /> : <Navigate to={paths.login(true)} />;
}
