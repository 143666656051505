export const API_BASE = import.meta.env.VITE_API_BASE || "/api";

export const AUTH_USER = "anythingllm_user";
export const AUTH_TOKEN = "anythingllm_authToken";
export const AUTH_TIMESTAMP = "anythingllm_authTimestamp";
export const COMPLETE_QUESTIONNAIRE = "anythingllm_completed_questionnaire";
export const SEEN_DOC_PIN_ALERT = "anythingllm_pinned_document_alert";
export const SEEN_WATCH_ALERT = "anythingllm_watched_document_alert";

export const USER_BACKGROUND_COLOR = "bg-historical-msg-user";
export const AI_BACKGROUND_COLOR = "bg-historical-msg-system";
export const APPEARANCE_SETTINGS = "anythingllm_appearance_settings";

export const MODULE_LEGAL_QA = "legal-qa";
export const MODULE_DOCUMENT_DRAFTING = "document-drafting";

export const OLLAMA_COMMON_URLS = [
  "http://127.0.0.1:11434",
  "http://host.docker.internal:11434",
  "http://172.17.0.1:11434",
];

export const LMSTUDIO_COMMON_URLS = [
  "http://localhost:1234/v1",
  "http://127.0.0.1:1234/v1",
  "http://host.docker.internal:1234/v1",
  "http://172.17.0.1:1234/v1",
];

export const KOBOLDCPP_COMMON_URLS = [
  "http://127.0.0.1:5000/v1",
  "http://localhost:5000/v1",
  "http://host.docker.internal:5000/v1",
  "http://172.17.0.1:5000/v1",
];

export const LOCALAI_COMMON_URLS = [
  "http://127.0.0.1:8080/v1",
  "http://localhost:8080/v1",
  "http://host.docker.internal:8080/v1",
  "http://172.17.0.1:8080/v1",
];

export function fullApiUrl() {
  if (API_BASE !== "/api") return API_BASE;
  return `${window.location.origin}/api`;
}

export const POPUP_BROWSER_EXTENSION_EVENT = "NEW_BROWSER_EXTENSION_CONNECTION";

export function getCurrentModule() {
  const storedModule = window.localStorage.getItem("module");
  // Always default to legal-qa if no module is stored or if it's the initial login
  return storedModule === "document-drafting" ? storedModule : "legal-qa";
}

export function isModuleLegalQA() {
  return getCurrentModule() === "legal-qa";
}

export function isModuleDocumentDrafting() {
  return getCurrentModule() === "document-drafting";
}

export async function isDocumentDraftingSelected() {
  return isDocumentDrafting() && isModuleDocumentDrafting();
}

// Last module slug used for workspace creation to prevent race conditions
let _lastModuleSlug = null;
let _moduleChangeInProgress = false;

export function currentModuleSlug() {
  // Always get the latest module from localStorage to avoid stale data
  const moduleFromStorage = window.localStorage.getItem("module");
  const currentModule =
    moduleFromStorage === "document-drafting" ? moduleFromStorage : "legal-qa";
  const isDDEnabled = isDocumentDrafting();

  // If a module change is in progress, return the last known good slug
  if (_moduleChangeInProgress && _lastModuleSlug) {
    console.log(
      "Module change in progress, using cached slug:",
      _lastModuleSlug
    );
    return _lastModuleSlug;
  }

  // Determine the appropriate module slug
  let slug;
  if (currentModule === "document-drafting") {
    // Verify document drafting is enabled
    if (!isDDEnabled) {
      console.warn(
        "Document drafting module selected but not enabled, defaulting to legal-qa"
      );
      slug = "legal-qa";
    } else {
      slug = "document-drafting";
    }
  } else {
    slug = "legal-qa";
  }

  // Cache the slug for future use
  _lastModuleSlug = slug;
  return slug;
}

// Register a listener for the module-changed event to track transitions
if (typeof window !== "undefined") {
  window.addEventListener("module-changed", () => {
    _moduleChangeInProgress = true;
    // Reset the flag after a short delay
    setTimeout(() => {
      _moduleChangeInProgress = false;
    }, 1000);
  });
}

export function isDocumentDrafting() {
  return window.localStorage.getItem("document-drafting") === "true";
}

export function isDocumentDraftingLinking() {
  return window.localStorage.getItem("document-drafting-linking") === "true";
}

export function isQura() {
  return window.localStorage.getItem("qura") === "true";
}

export function isCitationEnabled() {
  return window.localStorage.getItem("citationEnabled") === "true";
}
export const getActiveReference = () => {
  return localStorage.getItem("activeReference");
};

export const validateReferenceNumber = (isInvoiceLoggingEnabled) => {
  if (!isInvoiceLoggingEnabled) return true;

  const reference = getActiveReference();
  return !!reference;
};
