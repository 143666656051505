import { useTranslation } from "react-i18next";

export default function CohereAiOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("cohere.api-key")}
          </label>
          <input
            type="password"
            name={`CohereApiKey${moduleSuffix}`}
            placeholder={t("cohere.api-key-placeholder")}
            defaultValue={
              settings?.[`CohereApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
            className="dark-input-mdl text-foreground  text-sm rounded-lg block w-full p-2.5"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("cohere.model-selection")}
          </label>
          <select
            name={`CohereModelPref${moduleSuffix}`}
            defaultValue={
              settings?.[`CohereModelPref${moduleSuffix}`] || "command-r"
            }
            required={true}
            className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
          >
            {[
              "command-r",
              "command-r-plus",
              "command",
              "command-light",
              "command-nightly",
              "command-light-nightly",
            ].map((model) => {
              return (
                <option key={model} value={model} className="text-foreground">
                  {model}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
