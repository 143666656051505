import System from "@/models/system";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function PerplexityOptions({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("perplexity.api-key")}
        </label>
        <input
          type="password"
          name={`PerplexityApiKey${moduleSuffix}`}
          placeholder={t("perplexity.api-key-placeholder")}
          defaultValue={
            settings?.[`PerplexityApiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          className="modal-search-block text-foreground  text-sm rounded-lg block w-full p-2.5"
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <PerplexityModelSelection
          settings={settings}
          moduleSuffix={moduleSuffix}
        />
      )}
    </div>
  );
}

function PerplexityModelSelection({ settings, moduleSuffix = "" }) {
  const { t } = useTranslation();
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("perplexity");
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("perplexity.model-selection")}
        </label>
        <select
          name={`PerplexityModelPref${moduleSuffix}`}
          disabled={true}
          value=""
          className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} value="" className="text-foreground">
            {t("perplexity.loading-models")}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-4">
        {t("perplexity.model-selection")}
      </label>
      <select
        name={`PerplexityModelPref${moduleSuffix}`}
        required={true}
        value={settings?.[`PerplexityModelPref${moduleSuffix}`] || ""}
        className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {customModels.length > 0 && (
          <optgroup label={t("perplexity.available-models")}>
            {customModels.map((model) => {
              return (
                <option
                  className="text-foreground"
                  key={model.id}
                  value={model.id}
                >
                  {model.id}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
    </div>
  );
}
