import React, { useEffect, useState, useRef } from "react";
import System from "@/models/system";
import PreLoader from "@/components/Preloader";
import { OLLAMA_COMMON_URLS } from "@/utils/constants";
import { CaretDown, CaretUp, Info } from "@phosphor-icons/react";
import useProviderEndpointAutoDiscovery from "@/hooks/useProviderEndpointAutoDiscovery";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

export default function OllamaLLMOptions({
  settings,
  moduleSuffix = "",
  onError,
  onChange = () => {},
}) {
  const {
    autoDetecting: loading,
    basePath,
    basePathValue,
    handleAutoDetectClick,
  } = useProviderEndpointAutoDiscovery({
    provider: "ollama",
    initialBasePath: settings?.[`OllamaLLMBasePath${moduleSuffix}`],
    ENDPOINTS: OLLAMA_COMMON_URLS,
    onChange, // Pass onChange callback to trigger save button on basePath updates
  });

  const [performanceMode, setPerformanceMode] = useState(
    settings?.[`OllamaLLMPerformanceMode${moduleSuffix}`] || "base"
  );
  const [maxTokens, setMaxTokens] = useState(
    settings?.[`OllamaLLMTokenLimit${moduleSuffix}`] || 4096
  );
  const [keepAliveSeconds, setKeepAliveSeconds] = useState(
    settings?.[`OllamaLLMKeepAliveSeconds${moduleSuffix}`] || "300"
  );
  const { t } = useTranslation();

  // Handle auto-detect with error catching
  const handleAutoDetectWithErrorCatching = (e) => {
    e.preventDefault();

    try {
      handleAutoDetectClick(e);
    } catch (error) {
      console.error("Auto-detection failed:", error);
    }

    // Always trigger onChange to show save button
    onChange();
  };

  // Create handlers for input changes to ensure they trigger the onChange callback
  const handleMaxTokensChange = (e) => {
    // Ensure we have a valid positive number
    const rawValue = e.target.value;
    const parsedValue = parseInt(rawValue);

    // Only accept valid positive numbers
    if (!isNaN(parsedValue) && parsedValue > 0) {
      setMaxTokens(parsedValue);
      onChange(); // Notify parent of changes to trigger save button

      // Don't update system settings directly - let form submission handle it
      // The value will be collected from the input field's name/value
    }
  };

  const handlePerformanceModeChange = (e) => {
    const value = e.target.value;
    setPerformanceMode(value);
    onChange(); // Notify parent of changes to trigger save button
  };

  const handleKeepAliveChange = (e) => {
    const value = e.target.value;
    setKeepAliveSeconds(value);
    onChange(); // Notify parent of changes to trigger save button
  };

  // Update basePath state when it changes in settings
  const handleBasePathChange = (e) => {
    basePath.onChange(e);
    onChange(); // Notify parent component of changes

    // Directly update the base path setting, similar to token limit
    const value = e.target.value;
    if (value) {
      System.updateSystem({
        [`OllamaLLMBasePath${moduleSuffix}`]: value,
      }).catch((error) => {
        console.error("Error updating base path:", error);
      });
    }
  };

  const handleBasePathBlur = (e) => {
    basePath.onBlur(e);
  };

  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="w-full flex items-start gap-4">
        <OllamaLLMModelSelection
          settings={settings}
          basePath={basePath.value}
          moduleSuffix={moduleSuffix}
          onChange={onChange}
        />
        <div className="flex flex-col w-full">
          <label className="text-foreground text-sm font-semibold block mb-2">
            {t("ollamallmselection.max-tokens")}
          </label>
          <input
            type="number"
            name={`OllamaLLMTokenLimit${moduleSuffix}`}
            className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2"
            placeholder="4096"
            min={1}
            defaultValue={
              settings?.[`OllamaLLMTokenLimit${moduleSuffix}`] || 4096
            }
            onScroll={(e) => e.target.blur()}
            onChange={(e) => {
              onChange(); // Notify parent of changes to trigger save button

              // Directly update the token limit setting
              const value = e.target.value;
              if (value && !isNaN(parseInt(value)) && parseInt(value) > 0) {
                System.updateSystem({
                  [`OllamaLLMTokenLimit${moduleSuffix}`]: value,
                }).catch((error) => {
                  console.error("Error updating token limit:", error);
                });
              }
            }}
            required={true}
            autoComplete="off"
          />
          <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
            {t(
              "ollamallmselection.max-tokens-desc",
              "Maximum number of tokens for context and response"
            )}
          </p>
        </div>
      </div>

      <div className="w-full flex items-start gap-4 mt-4">
        <div className="flex flex-col w-60">
          <div className="flex justify-between items-center mb-2">
            <label className="text-foreground text-sm font-semibold">
              {t("ollamallmselection.base-url", "Ollama Server URL")}
            </label>
            {loading ? (
              <PreLoader size="6" />
            ) : (
              <>
                {!basePathValue.value && (
                  <button
                    onClick={handleAutoDetectWithErrorCatching}
                    className="primary-bg text-xs font-medium px-2 py-1 rounded-md text-white"
                  >
                    {t("ollamallmselection.auto-detect", "Auto-detect")}
                  </button>
                )}
              </>
            )}
          </div>
          <input
            className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2"
            type="url"
            name={`OllamaLLMBasePath${moduleSuffix}`}
            placeholder={t(
              "ollamallmselection.base-url-placeholder",
              "http://localhost:11434"
            )}
            value={basePathValue.value}
            required={true}
            autoComplete="off"
            spellCheck={false}
            onChange={handleBasePathChange}
            onBlur={handleBasePathBlur}
          />
          <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
            {t(
              "ollamallmselection.base-url-desc",
              "The URL to your Ollama server"
            )}
          </p>
        </div>

        <div className="flex flex-col w-60">
          <label className="text-foreground text-sm font-semibold block mb-2">
            {t("ollamallmselection.keep-alive", "Cache Duration")}
          </label>
          <select
            name={`OllamaLLMKeepAliveSeconds${moduleSuffix}`}
            required={true}
            className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2"
            value={keepAliveSeconds}
            onChange={handleKeepAliveChange}
          >
            <option value="0">
              {t("ollamallmselection.no-cache", "No caching")}
            </option>
            <option value="300">
              {t("ollamallmselection.five-minutes", "5 minutes")}
            </option>
            <option value="3600">
              {t("ollamallmselection.one-hour", "1 hour")}
            </option>
            <option value="-1">
              {t("ollamallmselection.forever", "Keep loaded")}
            </option>
          </select>
          <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
            {t(
              "ollamallmselection.keep-alive-desc",
              "How long to keep models in memory "
            )}
            <a
              className="underline text-blue-300"
              href="https://github.com/ollama/ollama/blob/main/docs/faq.md#how-do-i-keep-a-model-loaded-in-memory-or-make-it-unload-immediately"
              target="_blank"
              rel="noreferrer"
            >
              {t("ollamallmselection.learn-more", "Learn more")}
            </a>
          </p>
        </div>

        <div className="flex flex-col w-60">
          <label className="text-foreground text-sm font-semibold mb-2 flex items-center">
            {t("ollamallmselection.performance-mode", "Performance Mode")}
            <Info
              size={16}
              className="ml-2 text-foreground"
              data-tooltip-id="performance-mode-tooltip"
            />
          </label>
          <select
            name={`OllamaLLMPerformanceMode${moduleSuffix}`}
            required={true}
            className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2"
            value={performanceMode}
            onChange={handlePerformanceModeChange}
          >
            <option value="base">
              {t("ollamallmselection.base-default", "Normal (default)")}
            </option>
            <option value="maximum">
              {t("ollamallmselection.maximum", "Maximum")}
            </option>
          </select>
          <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
            {t(
              "ollamallmselection.performance-mode-desc",
              "Select hardware resource utilization"
            )}
          </p>
          <Tooltip
            id="performance-mode-tooltip"
            place="bottom"
            className="tooltip !text-xs max-w-xs"
          >
            <p className="text-red-500">
              <strong>{t("ollamallmselection.note", "Note:")}</strong>{" "}
              {t(
                "ollamallmselection.maximum-warning",
                "Maximum mode uses significantly more GPU memory"
              )}
            </p>
            <br />
            <p>
              <strong>{t("ollamallmselection.base", "Normal:")}</strong>{" "}
              {t(
                "ollamallmselection.base-desc",
                "Balanced performance and resource usage"
              )}
            </p>
            <br />
            <p>
              <strong>{t("ollamallmselection.maximum", "Maximum:")}</strong>{" "}
              {t(
                "ollamallmselection.maximum-desc",
                "Fastest performance but requires more GPU memory"
              )}
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

function OllamaLLMModelSelection({
  settings,
  basePath = null,
  moduleSuffix = "",
  onChange = () => {},
}) {
  const { t } = useTranslation();
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [selectedModel, setSelectedModel] = useState(
    settings?.[`OllamaLLMModelPref${moduleSuffix}`] || ""
  );
  const modelInitializedRef = useRef(false);

  // Define a fallback model if API call fails
  const FALLBACK_MODEL = "llama3.2:latest";

  const handleModelChange = (e) => {
    const newModel = e.target.value;
    setSelectedModel(newModel);
    onChange(); // Notify parent of changes

    // Directly update the system settings with the specific moduleSuffix
    System.updateSystem({
      [`OllamaLLMModelPref${moduleSuffix}`]: newModel,
    }).catch((error) => {
      console.error("Failed to update model preference:", error);
    });
  };

  // Function to set default model
  const setDefaultModel = (modelId) => {
    console.log(`Setting default Ollama model to: ${modelId}`);
    setSelectedModel(modelId);

    // Always update the system even if the environment variable doesn't exist yet
    return System.updateSystem({
      [`OllamaLLMModelPref${moduleSuffix}`]: modelId,
    })
      .then(() => {
        console.log(`Successfully set default Ollama model to: ${modelId}`);
        onChange(); // Notify parent of changes
        return true;
      })
      .catch((error) => {
        console.error("Failed to set default Ollama model:", error);
        return false;
      });
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    async function findCustomModels() {
      if (!basePath) {
        if (isMounted) {
          setCustomModels([]);
          setLoading(false);
          setLoadError(false);
        }
        return;
      }

      if (isMounted) {
        setLoading(true);
        setLoadError(false);
      }

      // Set a timeout to prevent indefinite loading
      const TIMEOUT_MS = 15000; // 15 seconds timeout
      timeoutId = setTimeout(() => {
        if (isMounted) {
          console.error("Ollama model fetch timeout after 15 seconds");

          // Use fallback model if fetch times out
          if (!selectedModel) {
            console.log(
              `Using fallback model ${FALLBACK_MODEL} due to timeout`
            );
            setDefaultModel(FALLBACK_MODEL);
          }

          setLoadError(true);
          setLoading(false);
        }
      }, TIMEOUT_MS);

      try {
        console.log(`Fetching Ollama models from: ${basePath}`);
        const response = await System.customModels(
          "ollama",
          null,
          basePath,
          10000
        );

        // Clear the timeout since we got a response
        clearTimeout(timeoutId);

        if (!isMounted) return;

        console.log(`Received Ollama models response:`, response);
        const { models, error } = response;

        if (error) {
          console.error(`Error from customModels API: ${error}`);

          // Use fallback model on error if no model is selected
          if (!selectedModel) {
            console.log(
              `Using fallback model ${FALLBACK_MODEL} due to API error`
            );
            setDefaultModel(FALLBACK_MODEL);
          }

          setLoadError(true);
          setLoading(false);
          return;
        }

        setCustomModels(models || []);

        // If we have models but no model is selected or saved, set a default
        if (models?.length > 0) {
          const savedModel = settings?.[`OllamaLLMModelPref${moduleSuffix}`];

          // Check if the saved model exists in the available models
          const modelExists =
            savedModel && models.some((m) => m.id === savedModel);

          // If no saved model or saved model doesn't exist in available models, set default
          if (!savedModel || !modelExists) {
            const firstModelId = models[0].id;
            setDefaultModel(firstModelId);
          }
        } else {
          console.log("No Ollama models found or empty response");

          // Use fallback model if no models are found
          if (!selectedModel) {
            console.log(
              `Using fallback model ${FALLBACK_MODEL} due to empty model list`
            );
            setDefaultModel(FALLBACK_MODEL);
          }

          setLoadError(true);
        }

        if (isMounted) {
          setLoading(false);
        }
      } catch (error) {
        // Clear the timeout since we got an error response
        clearTimeout(timeoutId);

        console.error("Error fetching Ollama models:", error);

        // Use fallback model on error if no model is selected
        if (!selectedModel && isMounted) {
          console.log(
            `Using fallback model ${FALLBACK_MODEL} due to fetch error`
          );
          setDefaultModel(FALLBACK_MODEL);
        }

        if (isMounted) {
          setLoadError(true);
          setLoading(false);
        }
      }
    }

    findCustomModels();

    return () => {
      isMounted = false;
      // Clear any pending timeouts when component unmounts
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [basePath, settings, moduleSuffix]);

  if (loading) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-2">
          {t("ollamallmselection.model")}
        </label>
        <select
          name={`OllamaLLMModelPref${moduleSuffix}`}
          disabled={true}
          value="loading"
          className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2"
        >
          <option value="loading" disabled={true}>
            {t("ollamallmselection.loading-models", "Loading models...")}
          </option>
        </select>
        <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
          {t(
            "ollamallmselection.model-loading",
            "Please wait while we load available models"
          )}
        </p>
      </div>
    );
  }

  if (loadError || customModels.length === 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-2">
          {t("ollamallmselection.model")}
        </label>
        <select
          name={`OllamaLLMModelPref${moduleSuffix}`}
          disabled={true}
          value="no-models"
          className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2"
        >
          <option value="no-models" disabled={true}>
            {loadError
              ? t("ollamallmselection.connection-error", "Connection error")
              : !basePath
                ? t("ollamallmselection.enter-url", "Enter URL first")
                : t("ollamallmselection.no-models-found", "No models found")}
          </option>
        </select>
        <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
          {loadError
            ? t(
                "ollamallmselection.connect-error-desc",
                "Check your connection or Ollama server"
              )
            : t(
                "ollamallmselection.model-desc",
                "Enter the URL to your Ollama server"
              )}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-2">
        {t("ollamallmselection.model")}
      </label>
      <select
        name={`OllamaLLMModelPref${moduleSuffix}`}
        required={true}
        value={selectedModel}
        onChange={handleModelChange}
        className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2"
      >
        {customModels.map((model) => (
          <option key={model.id} value={model.id} className="normal-text">
            {model.id}
          </option>
        ))}
      </select>
      <p className="text-xs leading-[18px] font-base text-foreground text-opacity-60 mt-2">
        {t("ollamallmselection.model-choose")}
      </p>
    </div>
  );
}
