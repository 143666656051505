import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ContextWrapper } from "@/AuthContext";
import PrivateRoute, {
  AdminRoute,
  ManagerRoute,
} from "@/components/PrivateRoute";
import Login from "@/pages/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import OnboardingFlow from "@/pages/OnboardingFlow";
import { initializeTextSize } from "@/utils/textSize";

import { LogoProvider } from "./LogoContext";
import { FullScreenLoader } from "./components/Preloader";
import "regenerator-runtime/runtime";

const Main = lazy(() => import("@/pages/Main"));
const InvitePage = lazy(() => import("@/pages/Invite"));
const AdminUsers = lazy(() => import("@/pages/Admin/Users"));
const AdminLogs = lazy(() => import("@/pages/Admin/Logging"));
const AdminSystem = lazy(() => import("@/pages/Admin/System"));
// const AdminQura = lazy(() => import("@/pages/Admin/Qura"));
const AdminAgents = lazy(() => import("@/pages/Admin/Agents"));
const WorkspaceChat = lazy(() => import("@/pages/WorkspaceChat"));
const AdminInvites = lazy(() => import("@/pages/Admin/Invitations"));
const AdminWorkspaces = lazy(() => import("@/pages/Admin/Workspaces"));
const GeneralChats = lazy(() => import("@/pages/GeneralSettings/Chats"));
const GeneralApiKeys = lazy(() => import("@/pages/GeneralSettings/ApiKeys"));
const GeneralAppearance = lazy(
  () => import("@/pages/GeneralSettings/Appearance")
);

const GeneralTranscriptionPreference = lazy(
  () => import("@/pages/GeneralSettings/TranscriptionPreference")
);
const GeneralAudioPreference = lazy(
  () => import("@/pages/GeneralSettings/AudioPreference")
);
const GeneralEmbeddingPreference = lazy(
  () => import("@/pages/GeneralSettings/EmbeddingPreference")
);
const EmbeddingTextSplitterPreference = lazy(
  () => import("@/pages/GeneralSettings/EmbeddingTextSplitterPreference")
);
const GeneralVectorDatabase = lazy(
  () => import("@/pages/GeneralSettings/VectorDatabase")
);
const GeneralSecurity = lazy(() => import("@/pages/GeneralSettings/Security"));
const GeneralBrowserExtension = lazy(
  () => import("@/pages/GeneralSettings/BrowserExtensionApiKey")
);
const WorkspaceSettings = lazy(() => import("@/pages/WorkspaceSettings"));
const EmbedConfigSetup = lazy(
  () => import("@/pages/GeneralSettings/EmbedConfigs")
);
const EmbedChats = lazy(() => import("@/pages/GeneralSettings/EmbedChats"));
const PrivacyAndData = lazy(
  () => import("@/pages/GeneralSettings/PrivacyAndData")
);
const ExperimentalFeatures = lazy(
  () => import("@/pages/Admin/ExperimentalFeatures")
);
const LiveDocumentSyncManage = lazy(
  () => import("@/pages/Admin/ExperimentalFeatures/Features/LiveSync/manage")
);
const DocumentDrafting = lazy(
  () => import("@/pages/GeneralSettings/DocumentDrafting")
);
const DraftingModel = lazy(
  () => import("@/pages/GeneralSettings/DraftingModel")
);
const ChatSettings = lazy(() => import("@/pages/GeneralSettings/ChatSettings"));
const DefaultSettings = lazy(
  () => import("@/pages/GeneralSettings/DefaultSettings")
);
const PdrSettings = lazy(() => import("@/pages/GeneralSettings/PdrSettings"));

const DocumentBuilder = lazy(
  () => import("@/pages/GeneralSettings/DocumentBuilder")
);
const CustomUserAIPreference = lazy(
  () => import("@/pages/GeneralSettings/CustomUserAI")
);
const ReRankSettings = lazy(
  () => import("@/pages/GeneralSettings/ReRankSettings")
);
const GenericLLMPreference = lazy(
  () => import("@/pages/GeneralSettings/GenericLLMPreference")
);

export default function App() {
  initializeTextSize();

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <ContextWrapper>
        <LogoProvider>
          <Routes>
            <Route path="/" element={<PrivateRoute Component={Main} />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/workspace/:slug/settings/:tab"
              element={<PrivateRoute Component={WorkspaceSettings} />}
            />
            <Route
              path="/workspace/:slug"
              element={<PrivateRoute Component={WorkspaceChat} />}
            />
            <Route
              path="/workspace/:slug/t/:threadSlug"
              element={<PrivateRoute Component={WorkspaceChat} />}
            />
            <Route path="/accept-invite/:code" element={<InvitePage />} />

            {/* Admin */}
            <Route
              path="/settings/llm-preference/:type?"
              element={<AdminRoute Component={GenericLLMPreference} />}
            />
            <Route
              path="/settings/custom-user-ai-preference"
              element={<ManagerRoute Component={CustomUserAIPreference} />}
            />
            <Route
              path="/settings/document-builder"
              element={<AdminRoute Component={DocumentBuilder} />}
            />
            <Route
              path="/settings/transcription-preference"
              element={
                <AdminRoute Component={GeneralTranscriptionPreference} />
              }
            />
            <Route
              path="/settings/audio-preference"
              element={<AdminRoute Component={GeneralAudioPreference} />}
            />
            <Route
              path="/settings/embedding-preference"
              element={<AdminRoute Component={GeneralEmbeddingPreference} />}
            />
            <Route
              path="/settings/text-splitter-preference"
              element={
                <AdminRoute Component={EmbeddingTextSplitterPreference} />
              }
            />
            <Route
              path="/settings/vector-database"
              element={<AdminRoute Component={GeneralVectorDatabase} />}
            />
            <Route
              path="/settings/agents"
              element={<AdminRoute Component={AdminAgents} />}
            />
            <Route
              path="/settings/event-logs"
              element={<AdminRoute Component={AdminLogs} />}
            />
            <Route
              path="/settings/embed-config"
              element={<AdminRoute Component={EmbedConfigSetup} />}
            />
            <Route
              path="/settings/embed-chats"
              element={<AdminRoute Component={EmbedChats} />}
            />
            <Route
              path="/settings/re-rank-settings"
              element={<AdminRoute Component={ReRankSettings} />}
            />
            {/* Manager */}
            <Route
              path="/settings/security"
              element={<ManagerRoute Component={GeneralSecurity} />}
            />
            <Route
              path="/settings/privacy"
              element={<AdminRoute Component={PrivacyAndData} />}
            />
            <Route
              path="/settings/appearance"
              element={<ManagerRoute Component={GeneralAppearance} />}
            />
            <Route
              path="/settings/api-keys"
              element={<AdminRoute Component={GeneralApiKeys} />}
            />
            <Route
              path="/settings/browser-extension"
              element={<ManagerRoute Component={GeneralBrowserExtension} />}
            />
            <Route
              path="/settings/workspace-chats"
              element={<ManagerRoute Component={GeneralChats} />}
            />
            <Route
              path="/settings/system-preferences"
              element={<ManagerRoute Component={AdminSystem} />}
            />
            <Route
              path="/settings/invites"
              element={<ManagerRoute Component={AdminInvites} />}
            />
            <Route
              path="/settings/users"
              element={<ManagerRoute Component={AdminUsers} />}
            />
            <Route
              path="/settings/workspaces"
              element={<ManagerRoute Component={AdminWorkspaces} />}
            />
            <Route
              path="/settings/beta-features"
              element={<AdminRoute Component={ExperimentalFeatures} />}
            />
            {/* Onboarding Flow */}
            <Route path="/onboarding" element={<OnboardingFlow />} />
            <Route path="/onboarding/:step" element={<OnboardingFlow />} />

            {/* Experimental feature pages  */}
            {/* Live Document Sync feature */}
            <Route
              path="/settings/beta-features/live-document-sync/manage"
              element={<AdminRoute Component={LiveDocumentSyncManage} />}
            />
            <Route
              path="/settings/document-drafting"
              element={<ManagerRoute Component={DocumentDrafting} />}
            />
            <Route
              path="/settings/drafting-model"
              element={<ManagerRoute Component={DraftingModel} />}
            />
            <Route
              path="/settings/chat-settings"
              element={<ManagerRoute Component={ChatSettings} />}
            />
            <Route
              path="/settings/default-settings"
              element={<ManagerRoute Component={DefaultSettings} />}
            />
            <Route
              path="/settings/pdr-settings"
              element={<ManagerRoute Component={PdrSettings} />}
            />
            <Route
              path="/settings/experimental"
              element={<ManagerRoute Component={ExperimentalFeatures} />}
            />
          </Routes>
          <ToastContainer />
        </LogoProvider>
      </ContextWrapper>
    </Suspense>
  );
}
