// Looking for a language to translate IST Legal to?
// Create a `common.js` file in the language's ISO code https://www.w3.org/International/O-charset-lang.html
// eg: Spanish => es/common.js
// eg: French => fr/common.js
// You should copy the en/common.js file as your template and just translate every string in there.
// By default, we try to see what the browsers native language is set to and use that. If a string
// is not defined or is null in the translation file, it will fallback to the value in the en/common.js file
// RULES:
// The EN translation file is the ground-truth for what keys and options are available. DO NOT add a special key
// to a specific language file as this will break the other languages. Any new keys should be added to english
// and the language file you are working on.

// Contributor Notice: If you are adding a translation you MUST locally run `yarn verify:translations` from the root prior to PR.
// please do not submit PR's without first verifying this test passes as it will tell you about missing keys or values
// from the primary dictionary.

// Only import English statically - other languages will be loaded dynamically
import English from "./en/common.js";
export const defaultNS = "common";
export const resources = {
  en: {
    common: English,
  },
};

export const supportedLanguageCodes = [
  "en",
  "fr",
  "sv",
  "rw",
  "de",
  "no",
  "pl",
];

export const loadLanguageAsync = async (language) => {
  if (language === "en") return null;

  try {
    let module;
    switch (language) {
      case "fr":
        module = await import("./fr/common.js");
        break;
      case "sv":
        module = await import("./sv/common.js");
        break;
      case "rw":
        module = await import("./rw/common.js");
        break;
      case "de":
        module = await import("./de/common.js");
        break;
      case "no":
        module = await import("./no/common.js");
        break;
      case "pl":
        module = await import("./pl/common.js");
        break;
      default:
        return null;
    }

    return module.default;
  } catch (error) {
    console.error(`Failed to load language: ${language}`, error);
    return null;
  }
};

export const initializeLanguageAsync = async () => {
  const userLanguage = localStorage.getItem("language");

  if (userLanguage && userLanguage !== "en") {
    const resourceBundle = await loadLanguageAsync(userLanguage);
    if (resourceBundle) return userLanguage;
  } else if (userLanguage === "en") {
    return "en";
  }

  try {
    const response = await fetch(
      `${window.location.origin}/api/setup-complete`
    );

    if (response.ok) {
      const data = await response.json();
      const adminDefaultLang = data?.results?.language;

      if (adminDefaultLang && adminDefaultLang !== "en") {
        const resourceBundle = await loadLanguageAsync(adminDefaultLang);
        if (resourceBundle) {
          localStorage.setItem("language", adminDefaultLang);
          return adminDefaultLang;
        }
      } else if (adminDefaultLang === "en") {
        localStorage.setItem("language", "en");
        return "en";
      }
    }
  } catch (error) {
    console.error("Failed to fetch admin language setting", error);
  }

  localStorage.setItem("language", "en");
  return "en";
};
