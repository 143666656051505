import Workspace from "@/models/workspace";
import paths from "@/utils/paths";
import showToast from "@/utils/toast";
import { PencilSimple, Trash } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import { Button } from "@/components/Button";
import { LuX, LuRotateCcw } from "react-icons/lu";
import { THREAD_RENAME_EVENT } from "..";

export default function ThreadItem({
  isActive,
  workspace,
  thread,
  onRemove,
  ctrlPressed = false,
  toggleMarkForDeletion,
}) {
  const { t } = useTranslation();
  const { slug } = useParams();
  const optionsContainer = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const linkTo = !thread.slug
    ? paths.workspace.chat(slug)
    : paths.workspace.thread(slug, thread.slug);

  const displayName =
    thread.name === "Thread"
      ? t("sidebar.thread.empty-thread")
      : thread.name === "Standard"
        ? t("sidebar.thread.default")
        : thread.name;

  return (
    <Button
      asChild
      variant={isActive ? "secondary" : "ghost"}
      size="sm"
      className="relative group h-fit"
    >
      <div className="flex w-full items-center justify-between pr-2">
        {window.location.pathname === linkTo || ctrlPressed ? (
          <span className="flex items-center w-full max-w-48 shrink-0">
            <div
              className="overflow-hidden w-[150px] pr-2"
              title={displayName.length > 35 ? displayName : undefined}
            >
              <div
                className={
                  displayName.length > 25 ? "marquee-text" : "truncate"
                }
              >
                {displayName}
              </div>
            </div>
          </span>
        ) : (
          <Link
            to={linkTo}
            className="flex items-center flex-grow max-w-[calc(100%-42px)] shrink-0"
          >
            <div
              className="overflow-hidden w-[150px] pr-2"
              title={displayName.length > 35 ? displayName : undefined}
            >
              <div
                className={
                  displayName.length > 25 ? "marquee-text" : "truncate"
                }
              >
                {displayName}
              </div>
            </div>
          </Link>
        )}

        <div ref={optionsContainer}>
          {ctrlPressed ? (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => toggleMarkForDeletion(thread.id)}
              className={!thread.deleted && "!text-red-500"}
            >
              {thread.deleted ? <LuRotateCcw /> : <LuX />}
            </Button>
          ) : (
            <div className="flex items-center w-fit group-hover:visible md:invisible gap-x-1">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowOptions(!showOptions)}
                aria-label="Thread options"
              >
                <BsThreeDots />
              </Button>
            </div>
          )}
          {showOptions && (
            <OptionsMenu
              containerRef={optionsContainer}
              workspace={workspace}
              thread={thread}
              onRemove={onRemove}
              close={() => setShowOptions(false)}
            />
          )}
        </div>
      </div>
    </Button>
  );
}

function OptionsMenu({ containerRef, workspace, thread, onRemove, close }) {
  const { t } = useTranslation();
  const menuRef = useRef(null);

  const outsideClick = (e) => {
    if (!menuRef.current) return false;
    if (
      !menuRef.current?.contains(e.target) &&
      !containerRef.current?.contains(e.target)
    )
      close();
    return false;
  };

  const isEsc = (e) => {
    if (e.key === "Escape" || e.key === "Esc") close();
  };

  function cleanupListeners() {
    window.removeEventListener("click", outsideClick);
    window.removeEventListener("keyup", isEsc);
  }

  useEffect(() => {
    function setListeners() {
      if (!menuRef?.current || !containerRef.current) return false;
      window.document.addEventListener("click", outsideClick);
      window.document.addEventListener("keyup", isEsc);
    }

    setListeners();
    return cleanupListeners;
  }, [menuRef.current, containerRef.current]);

  const renameThread = async () => {
    const input = window.prompt(t("sidebar.thread.rename-message"))?.trim();
    if (!input || input.length === 0) {
      close();
      return;
    }

    const sanitizedName = input.replace(/[^\p{L}0-9\s-]/gu, "");

    if (!sanitizedName || sanitizedName.length < 3) {
      showToast(t("thread_name_error"), "error", {
        clear: true,
      });
      close();
      return;
    }

    const { message } = await Workspace.threads.update(
      workspace.slug,
      thread.slug,
      { name: sanitizedName }
    );
    if (message) {
      showToast(
        t("toast.workspace.thread-update-failed", { message }),
        "error",
        {
          clear: true,
        }
      );
      close();
      return;
    }

    thread.name = sanitizedName;

    window.dispatchEvent(
      new CustomEvent(THREAD_RENAME_EVENT, {
        detail: { threadSlug: thread.slug, newName: sanitizedName },
      })
    );

    close();
  };

  const handleDelete = async () => {
    if (!window.confirm(t("sidebar.thread.delete-message"))) return;
    const success = await Workspace.threads.delete(workspace.slug, thread.slug);
    if (!success) {
      showToast(t("show-toast.delete-option"), "error", { clear: true });
      return;
    }
    showToast(t("show-toast.thread-deleted"), "success", { clear: true });
    onRemove(thread.id);
  };

  return (
    <div
      ref={menuRef}
      className="absolute flex flex-col gap-y-2 w-fit top-[20px] right-[0px] p-2 bg-background border shadow-lg rounded-lg z-10"
    >
      <Button
        variant="ghost"
        size="sm"
        onClick={renameThread}
        className="!justify-start"
      >
        <PencilSimple />
        {t("sidebar.thread.rename")}
      </Button>
      <Button
        variant="ghost"
        size="sm"
        onClick={handleDelete}
        className="!justify-start"
      >
        <Trash />
        {t("sidebar.thread.delete-thread")}
      </Button>
    </div>
  );
}
