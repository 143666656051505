import System from "@/models/system";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function GeminiLLMOptions({
  settings,
  moduleSuffix = "",
  onError,
  onChange = () => {},
}) {
  const { t } = useTranslation();
  const apiKeyFieldName = `GeminiLLMApiKey${moduleSuffix}`;
  const modelPrefFieldName = `GeminiLLMModelPref${moduleSuffix}`;
  const safetySettingFieldName = `GeminiSafetySetting${moduleSuffix}`;
  const [inputValue, setInputValue] = useState(
    settings ? settings[apiKeyFieldName] : undefined
  );
  const [geminiApiKey, setGeminiApiKey] = useState(
    settings ? settings[apiKeyFieldName] : undefined
  );
  const [apiKeyError, setApiKeyError] = useState("");
  const [safetySetting, setSafetySetting] = useState(
    settings ? settings[safetySettingFieldName] || "BLOCK_NONE" : "BLOCK_NONE"
  );

  const handleApiKeyChange = (e) => {
    const value = e.target.value.replace(/[\uF000-\uF8FF]/g, "");
    setInputValue(value);

    // Notify parent of changes to trigger save button
    onChange();

    setApiKeyError("");
    if (onError) onError("");
  };

  const handleApiKeyBlur = () => {
    setGeminiApiKey(inputValue);

    // Update system settings for the API key if it has changed
    if (inputValue !== settings?.[apiKeyFieldName]) {
      System.updateSystem({
        [apiKeyFieldName]: inputValue,
      });
    }
  };

  const handleSafetySettingChange = (e) => {
    const newValue = e.target.value;
    setSafetySetting(newValue);

    // Update the system settings with the specific moduleSuffix
    System.updateSystem({
      [safetySettingFieldName]: newValue,
    });
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center gap-4">
        <div className="flex flex-col w-80">
          <label className="text-foreground text-sm font-semibold block mb-4">
            {t("gemini.api-key")}
          </label>
          <input
            type="password"
            name={apiKeyFieldName}
            placeholder={t("gemini.api-key-placeholder")}
            defaultValue={
              settings && settings[apiKeyFieldName]
                ? "*".repeat(Number(t("generic.password-mask-length")))
                : ""
            }
            required={true}
            autoComplete="off"
            spellCheck={false}
            onChange={handleApiKeyChange}
            onBlur={handleApiKeyBlur}
            className={`dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5 ${
              apiKeyError ? "border-red-500" : ""
            }`}
          />
          {apiKeyError && (
            <div className="text-red-500 text-sm mt-1">{apiKeyError}</div>
          )}
        </div>

        {!settings?.credentialsOnly && (
          <>
            <GeminiModelSelection
              apiKey={geminiApiKey}
              settings={settings}
              moduleSuffix={moduleSuffix}
            />
            <div className="flex flex-col w-60">
              <label className="text-foreground text-sm font-semibold block mb-4">
                {t("gemini.safety-setting")}
              </label>
              <select
                name={safetySettingFieldName}
                value={safetySetting}
                onChange={handleSafetySettingChange}
                required={true}
                className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
              >
                <option value="BLOCK_NONE">
                  {t("gemini.safety-options.none")}
                </option>
                <option value="BLOCK_ONLY_HIGH">
                  {t("gemini.safety-options.block-few")}
                </option>
                <option value="BLOCK_MEDIUM_AND_ABOVE">
                  {t("gemini.safety-options.block-some")}
                </option>
                <option value="BLOCK_LOW_AND_ABOVE">
                  {t("gemini.safety-options.block-most")}
                </option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function GeminiModelSelection({ apiKey, settings, moduleSuffix }) {
  const { t } = useTranslation();
  const modelPrefFieldName = `GeminiLLMModelPref${moduleSuffix || ""}`;
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState(
    settings ? settings[modelPrefFieldName] || "" : ""
  );
  const modelInitializedRef = useRef(false);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      try {
        const { models } = await System.customModels("gemini", apiKey);

        if (models?.length > 0) {
          const uniqueModels = [];
          const seenIds = new Set();
          const seenNames = new Set();

          models.forEach((model) => {
            const modelId = model.id;
            const displayName = model.name;

            if (!seenIds.has(modelId) && !seenNames.has(displayName)) {
              seenIds.add(modelId);
              seenNames.add(displayName);
              uniqueModels.push(model);
            }
          });

          const modelsByOrganization = uniqueModels.reduce((acc, model) => {
            const key = model.experimental
              ? t("gemini.experimental")
              : t("gemini.stable");
            acc[key] = acc[key] || [];
            acc[key].push(model);
            return acc;
          }, {});
          setGroupedModels(modelsByOrganization);

          // Check if the default model exists in the fetched models
          const defaultModelId = "gemini-pro";
          const defaultModelExists = uniqueModels.some(
            (m) => m.id === defaultModelId
          );

          // If there's a saved model from settings, prioritize it
          if (settings?.[modelPrefFieldName]) {
            const savedModel = settings[modelPrefFieldName];
            // Check if the saved model exists in the fetched models
            const modelExists = uniqueModels.some((m) => m.id === savedModel);

            if (!modelExists) {
              // If saved model doesn't exist, update to either default or first model
              const modelToUse = defaultModelExists
                ? defaultModelId
                : uniqueModels[0].id;
              setSelectedModel(modelToUse);

              // Auto-save the model
              System.updateSystem({
                [modelPrefFieldName]: modelToUse,
              });
            } else {
              // Make sure the UI selection matches the saved model
              setSelectedModel(savedModel);
            }
          } else if (!selectedModel && !modelInitializedRef.current) {
            // No saved model, use default if available, otherwise first model
            modelInitializedRef.current = true;
            const modelToUse = defaultModelExists
              ? defaultModelId
              : uniqueModels[0].id;
            setSelectedModel(modelToUse);

            // Auto-save the model
            System.updateSystem({
              [modelPrefFieldName]: modelToUse,
            });
          }
        }
      } catch (error) {
        console.error("Failed to fetch models:", error);
      }
      setLoading(false);
    }

    if (apiKey) {
      findCustomModels();
    } else {
      setGroupedModels({});
      setLoading(false);
    }
  }, [apiKey, t, settings, modelPrefFieldName]);

  // Update local state when settings change
  useEffect(() => {
    setSelectedModel(settings ? settings[modelPrefFieldName] || "" : "");
  }, [settings, modelPrefFieldName]);

  if (loading) {
    return (
      <div className="flex flex-col w-40">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("gemini.model-selection")}
        </label>
        <select
          name={modelPrefFieldName}
          disabled={true}
          value="loading"
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
        >
          <option value="loading" className="normal-text">
            {t("gemini.loading-models")}
          </option>
        </select>
      </div>
    );
  }

  const handleModelChange = (e) => {
    const newValue = e.target.value;
    setSelectedModel(newValue);

    // Update the system settings with the specific moduleSuffix
    System.updateSystem({
      [modelPrefFieldName]: newValue,
    }).catch((error) => {
      console.error(`Failed to update model preference:`, error);
    });
  };

  return (
    <div className="flex flex-col w-70">
      <label className="text-normal text-sm font-semibold block mb-3">
        {t("gemini.model-selection")}
      </label>
      <select
        name={modelPrefFieldName}
        required={true}
        value={selectedModel}
        onChange={handleModelChange}
        className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-2.5"
      >
        {/* Manual options for latest models */}
        <optgroup label={t("gemini.manual-options")}>
          <option value="gemini-2.0-flash">gemini-2.0-flash</option>
          <option value="gemini-2.0-pro-exp">gemini-2.0-pro-exp</option>
          <option value="gemini-exp-1206">gemini-exp-1206</option>
        </optgroup>

        {Object.keys(groupedModels)
          .sort((a, b) => {
            if (a === t("gemini.stable")) return -1;
            if (b === t("gemini.stable")) return 1;
            return a.localeCompare(b);
          })
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option key={model.id} value={model.id}>
                  {model.id}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
}
