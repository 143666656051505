import { useState, useEffect, useRef } from "react";
import System from "@/models/system";
import { useTranslation } from "react-i18next";

export default function OpenAiOptions({
  settings,
  moduleSuffix = "",
  onError,
  onChange = () => {},
}) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(
    settings?.[`OpenAiKey${moduleSuffix}`]
  );
  const [openAIKey, setOpenAIKey] = useState(
    settings?.[`OpenAiKey${moduleSuffix}`]
  );
  const [selectedModel, setSelectedModel] = useState(
    settings?.[`OpenAiModelPref${moduleSuffix}`] || ""
  );
  const [apiKeyError, setApiKeyError] = useState("");
  const initializedRef = useRef(false);

  // Immediate context window initialization on mount - run this first, outside of any state dependencies
  useEffect(() => {
    // Get initial model value directly from settings
    const initialModel = settings?.[`OpenAiModelPref${moduleSuffix}`];

    if (initialModel && !initializedRef.current) {
      initializedRef.current = true;

      // Force immediate context window update
      setTimeout(() => {
        onChange({
          autoSaved: true,
          modelChanged: true,
          provider: "openai",
          modelKey: `OpenAiModelPref${moduleSuffix}`,
          modelValue: initialModel,
        });
      }, 0);
    }
  }, [moduleSuffix, settings, onChange]); // Only depend on these props, not state

  const validateApiKey = (value) => {
    if (!value.startsWith("sk-")) {
      const error = t("errors.env.openai-key-format");
      setApiKeyError(error);
      if (onError) onError(error);
      return false;
    }
    setApiKeyError("");
    if (onError) onError("");
    return true;
  };

  const handleApiKeyChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Notify parent of changes to trigger save button
    onChange();

    if (value && !validateApiKey(value)) {
      e.preventDefault();
      return;
    }
    setApiKeyError("");
    if (onError) onError("");
  };

  const handleApiKeyBlur = () => {
    setOpenAIKey(inputValue);

    // Only update if the value changed and is valid
    if (
      inputValue !== settings?.[`OpenAiKey${moduleSuffix}`] &&
      (inputValue === "" || validateApiKey(inputValue))
    ) {
      // Update system settings for the API key
      System.updateSystem({
        [`OpenAiKey${moduleSuffix}`]: inputValue,
      });
    }
  };

  const handleModelChange = (e) => {
    const newModel = e.target.value;
    const isAutoSelection = e.isAutoSelection === true;
    setSelectedModel(newModel);

    // Update the system settings with the specific moduleSuffix
    System.updateSystem({
      [`OpenAiModelPref${moduleSuffix}`]: newModel,
    }).catch((error) => {
      console.error(`Failed to update model preference:`, error);
    });

    // Immediately notify parent about model change to update context window
    if (typeof onChange === "function") {
      onChange({
        autoSaved: true, // Always mark as auto-saved since we're directly updating the system
        modelChanged: true,
        provider: "openai",
        modelKey: `OpenAiModelPref${moduleSuffix}`,
        modelValue: newModel,
      });
    }
  };

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-full">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("openai.api-key")}
        </label>
        <input
          type="password"
          name={`OpenAiKey${moduleSuffix}`}
          placeholder={t("openai.api-key-placeholder")}
          defaultValue={
            settings?.[`OpenAiKey${moduleSuffix}`] ? "*".repeat(20) : ""
          }
          required={true}
          autoComplete="off"
          spellCheck={false}
          onChange={handleApiKeyChange}
          onBlur={handleApiKeyBlur}
          className="dark-input-mdl text-foreground text-sm rounded-lg block w-full p-1.5"
        />
        {apiKeyError && (
          <div className="text-red-500 text-sm mt-1">{apiKeyError}</div>
        )}
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <OpenAIModelSelection
          settings={settings}
          apiKey={openAIKey}
          moduleSuffix={moduleSuffix}
          selectedModel={selectedModel}
          onModelChange={handleModelChange}
        />
      )}
    </div>
  );
}

function OpenAIModelSelection({
  apiKey,
  moduleSuffix = "",
  selectedModel,
  onModelChange,
  settings,
}) {
  const { t } = useTranslation();
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentModel, setCurrentModel] = useState(selectedModel || "");
  const modelInitializedRef = useRef(false);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      try {
        const { models } = await System.customModels(
          "openai",
          typeof apiKey === "boolean" ? null : apiKey
        );

        if (models?.length > 0) {
          const modelsByOrganization = models.reduce((acc, model) => {
            acc[model.organization] = acc[model.organization] || [];
            acc[model.organization].push(model);
            return acc;
          }, {});
          setGroupedModels(modelsByOrganization);

          // If there's no current model selected but we have models available,
          // initialize with the current model from settings with the specific suffix
          if (!currentModel && settings?.[`OpenAiModelPref${moduleSuffix}`]) {
            setCurrentModel(settings[`OpenAiModelPref${moduleSuffix}`]);
          } else if (
            !currentModel &&
            models.length > 0 &&
            !modelInitializedRef.current
          ) {
            // Select first model if none is selected and we haven't initialized yet
            const firstModelId = models[0].id;
            setCurrentModel(firstModelId);
            modelInitializedRef.current = true;

            // Add a delay to ensure this doesn't interfere with other initialization
            setTimeout(() => {
              if (onModelChange) {
                // Create a synthetic event with the value property set
                const syntheticEvent = {
                  target: { value: firstModelId },
                  // Tag this event as coming from auto-selection
                  isAutoSelection: true,
                };
                onModelChange(syntheticEvent);
              }
            }, 500); // Delay to ensure the component is fully mounted
          }
        } else {
          setGroupedModels({});
        }
      } catch (error) {
        console.error("Failed to fetch models:", error);
        setGroupedModels({});
      }
      setLoading(false);
    }
    if (apiKey) {
      findCustomModels();
    } else {
      setGroupedModels({});
      setLoading(false);
    }
  }, [apiKey, moduleSuffix, settings]);

  // Update currentModel when selectedModel prop changes
  useEffect(() => {
    setCurrentModel(selectedModel || "");
  }, [selectedModel]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setCurrentModel(newValue);

    // Call parent's onModelChange, which will update settings and context window
    if (onModelChange) {
      onModelChange(e);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col w-60">
        <label className="text-foreground text-sm font-semibold block mb-4">
          {t("openai.model-selection")}
        </label>
        <select
          name={`OpenAiModelPref${moduleSuffix}`}
          disabled={true}
          value="loading"
          className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
        >
          <option value="loading" className="normal-text">
            {t("loading.models")}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="text-foreground text-sm font-semibold block mb-4">
        {t("openai.model-selection")}
      </label>
      <select
        name={`OpenAiModelPref${moduleSuffix}`}
        required={true}
        value={currentModel}
        onChange={handleChange}
        className="dark-input-mdl text-foreground focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {/* Manually selectable models */}
        <optgroup label="Manual Options">
          <option value="chatgpt-4o-latest" className="text-foreground">
            chatgpt-4o-latest
          </option>
          <option value="gpt-4o-2024-11-20" className="text-foreground">
            gpt-4o-2024-11-20
          </option>
          <option value="o1" className="text-foreground">
            o1
          </option>
          <option value="o1-mini" className="text-foreground">
            o1-mini
          </option>
          <option value="o3-mini" className="text-foreground">
            o3-mini
          </option>
          <option value="o3-mini-2025-01-31" className="text-foreground">
            o3-mini-2025-01-31
          </option>
        </optgroup>

        {/* Existing custom models separator and optgroups */}
        {Object.keys(groupedModels).length > 0 && (
          <option disabled className="text-gray-500">
            ──────────
          </option>
        )}
        {Object.keys(groupedModels)
          .sort()
          .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option
                  key={model.id}
                  value={model.id}
                  className="text-foreground"
                >
                  {model.name}
                </option>
              ))}
              {/* Hardcoded API Option */}
              <option value="chatgpt-4o-latest" className="text-foreground">
                chatgpt-4o-latest
              </option>
            </optgroup>
          ))}
      </select>
    </div>
  );
}
